export const SelectionGroupItem = superclass => class extends superclass {

  static get properties() {
    return {
      selected: { type: Boolean, reflect: true, attribute: 'selected' },
      value: { type: String },
    };
  }

  updated(changedProperties) {
    super.updated(changedProperties);

    if (changedProperties.has('selected')) {
      this.selected = this.value === this.parentNode.selectedValue;
    }
  }

  handleSelection() {
    this.dispatchEvent(new CustomEvent('nova-selection-group-item-selected', {
      bubbles: true,
      composed: true,
      detail: {
        value: this.value,
      },
    }));
  }
};
