import '../../../../../shared/components/inputs/nova-attribute-picker/nova-attribute-picker.js';

import { FormElementMixin } from '@brightspace-ui/core/components/form/form-element-mixin.js';
import { inputLabelStyles } from '@brightspace-ui/core/components/inputs/input-label-styles.js';
import { labelStyles } from '@brightspace-ui/core/components/typography/styles.js';

import { css, html, LitElement, nothing } from 'lit';
import { ifDefined } from 'lit/directives/if-defined.js';
import { isEmail } from '../../../../../../shared/helpers/util.js';
import { LocalizeNova } from '../../../../../shared/mixins/localize-nova/localize-nova.js';

class EmailListInput extends LocalizeNova(FormElementMixin(LitElement)) {

  static get properties() {
    return {
      label: { type: String },
      required: { type: Boolean },
      // Array of email strings
      emails: { type: Array },
      // New property: array of emails that have been flagged as invalid or error by the parent.
      invalidEmails: { type: Array },
      errorEmails: { type: Array },
      // Still using the label value for aria-label on the input.
      hideLabel: { type: Boolean, attribute: 'hide-label', reflect: true },

      rows: { type: Number, attribute: 'rows', reflect: true },
      limit: { type: Number },
    };
  }

  static get styles() {
    return [
      labelStyles,
      inputLabelStyles,
      css`
        :host {
          display: flex;
          flex-direction: column;
        }

        .email-counter {
          justify-self: flex-end;
        }
      `,
    ];
  }

  constructor() {
    super();
    this.emails = [];
    this.invalidEmails = [];
    this.errorEmails = [];
    this._blurReplaced = false;
    this.setValidity({ badInput: true });
  }

  updated(_changedProperties) {
    if (_changedProperties.has('emails')) {
      this.setValidity({ badInput: this.emails.length <= 0 });
    }
    // The attribute picker component does not fire a blur event when the user tabs out of the input field. This overrides
    // the blur event handler directly on the component.
    if (this.attributePickerElement && !this._blurReplaced) {
      this.attributePickerElement._onInputBlur = () => {
        this.attributePickerElement._inputFocused = false;
        this._keyupHandler({ key: 'Tab' });
      };
      this._blurReplaced = true;
    }
  }

  get attributePickerElement() {
    return this.shadowRoot.getElementById('attribute-picker');
  }

  get validationMessage() {
    if (this.validity.patternMismatch) {
      return this.localize('suggest-panel.form.error.invalid-email');
    } else if (this.validity.badInput) {
      return this.localize('suggest-panel.form.error.required');
    }
    return super.validationMessage;
  }

  _suggestListChanged(e) {
    const { attributeList } = e.detail;
    if (attributeList.length > this.emails.length) {
      const { value } = attributeList[attributeList.length - 1];
      if (isEmail(value)) {
        this.emails.push(value);
        this.setValidity({ patternMismatch: false });
      } else {
        this.attributePickerElement._text = value;
        this.attributePickerElement.attributeList.splice(this.attributePickerElement.attributeList.length - 1, 1);
        this.setValidity({ patternMismatch: true });
      }
    }
    this.emails = this.attributePickerElement.attributeList.map(attribute => attribute.value);

    this.setValidity({ badInput: this.emails.length <= 0 });
    this._dispatchEmailsChangedEvent();
  }

  _keyupHandler(e) {
    const text = this.attributePickerElement._text;
    const textContainsSpace = text.includes(' ');
    const textContainsComma = text.includes(',');
    const textContainsSemicolon = text.includes(';');
    if (e.key === 'Tab' && isEmail(text.trim())) {
      this.attributePickerElement._text = '';
      this.emails = [...this.emails, text.trim()];
      this._dispatchEmailsChangedEvent();
      this.setValidity({ badInput: this.emails.length <= 0 });
      return;
    }
    let emailListUpdated = false;
    const invalidEmails = [];
    if (textContainsSpace || textContainsComma || textContainsSemicolon) {
      const emails = text.split(/[,;\s]+/);
      for (const email of emails) {
        if (isEmail(email)) {
          this.attributePickerElement._text = '';
          this.emails = [...this.emails, email];
          emailListUpdated = true;
        } else if (email) {
          invalidEmails.push(email);
        }
      }
      this.attributePickerElement._text = invalidEmails.join(textContainsComma ? ',' : ';');

      this.setValidity({ badInput: this.emails.length <= 0 });
    }
    if (emailListUpdated) this._dispatchEmailsChangedEvent();
  }

  /**
   * Dispatches a custom event to notify the parent that the emails have changed.
   * @private
   */
  _dispatchEmailsChangedEvent() {
    this.dispatchEvent(new CustomEvent('emails-changed', {
      bubbles: true,
      composed: true,
      detail: {
        emails: this.emails,
      },
    }));
  }

  get emailsWithErrors() {
    return this.emails.map(email => ({
      name: email,
      value: email,
      warning: this.invalidEmails && this.invalidEmails.includes(email),
      error: this.errorEmails && this.errorEmails.includes(email),
    }));
  }

  render() {
    const label = this.hideLabel ? ''
      : html`<label class="suggest-form-label d2l-input-label" for="attribute-picker">${this.label}</label>`;
    return html`
        ${label}
      <nova-attribute-picker
        @keydown="${this._keyDownHandler}"
        @keyup="${this._keyupHandler}"
        rows="${ifDefined(this.rows)}"
        limit="${ifDefined(this.limit)}"
        id="attribute-picker"
        @nova-attribute-picker-attributes-changed="${this._suggestListChanged}"
        class="suggest-form-input"
        label="${this.label}"
        .attributeList=${this.emailsWithErrors} }
        allow-freeform>
      </nova-attribute-picker>
      ${this.limit !== undefined ? html`
        <div class="email-counter d2l-label-text">${this.emails.length}/${this.limit}</div>
      ` : nothing}
    `;
  }
}

window.customElements.define('email-list-input', EmailListInput);
