import '@brightspace-ui/core/components/button/button.js';
import '@brightspace-ui/core/components/button/button-icon.js';
import '@brightspace-ui/core/components/button/button-subtle.js';
import '@brightspace-ui/core/components/collapsible-panel/collapsible-panel.js';
import '@brightspace-ui/core/components/collapsible-panel/collapsible-panel-summary-item.js';
import '@brightspace-ui/core/components/colors/colors.js';
import '@brightspace-ui/core/components/icons/icon.js';
import '@brightspace-ui/core/components/dropdown/dropdown-more.js';
import '@brightspace-ui/core/components/dropdown/dropdown-content.js';
import { inputLabelStyles } from '@brightspace-ui/core/components/inputs/input-label-styles.js';

import { css, html, LitElement, nothing } from 'lit';
import { repeat } from 'lit/directives/repeat.js';

import '../nova-json-input.js';
import { LocalizeNova } from '../../../../mixins/localize-nova/localize-nova.js';

const UNIQUE_INTERNAL_KEY = 'nij_unique_key';

class NovaInputJsonArray extends LocalizeNova(LitElement) {

  static get properties() {
    return {
      // Array of objects to display in the editor
      // REQUIRED
      objectArray: { type: Array },

      // REQUIRED
      label: { type: String },

      // If true, will disable all inputs
      disabled: { type: Boolean },

      // You can provide a default value for new objects added with the 'add object' button
      // If not provided, will make an empty object when 'add object' is used
      defaultNewObject: { type: Object },

      // An instance of a nova-model-schema to validate each object against (must extend nova-model-schema class)
      novaModelSchema: { type: Object },

      // Provide the key to use for the title on each collapsible panel. Value must be string.
      // If not provided or not found, will use "Object <#index>"
      titleKey: { type: String, attribute: 'title-key' },

      // Provide the key to use for the description on the collapsible panel
      // If not provided or not found, will not be shown
      summaryKey: { type: String, attribute: 'summary-key' },

      // Will hide previews for each JSON input, including both tabs on top
      jsonViewsHidden: { type: Boolean, attribute: 'json-views-hidden' },

      // Don't display any properties with these names
      hiddenKeys: { type: Array, attribute: 'hidden-keys' },

      // internal value, can retrieve with get value() method
      _arrayValue: { type: Array },
    };
  }

  static get styles() {
    return [
      inputLabelStyles,
      css`
        :host {
          display: block;
        }

        d2l-collapsible-panel:not(:first-of-type) {
          margin-top: 12px;
        }

        .add-object-button {
          width: 100%;
        }

        .collapse-button-container {
          align-items: center;
          display: flex;
          margin-bottom: 6px;
        }

        .empty-list-container {
          background-color: var(--d2l-color-regolith);
          border: 1px solid var(--d2l-color-mica);
          border-radius: 6px;
          padding: 1rem;
        }

        .last-button-container {
          margin-top: 12px;
        }

        .top-container {
          align-items: center;
          display: flex;
          justify-content: space-between;
        }
`,
    ];
  }

  constructor() {
    super();
    this._uniqueKeyCounter = 0;
  }

  updated(changedProperties) {
    if (this._arrayValue) return;
    if (changedProperties.has('objectArray')) {
      if (!Array.isArray(this.objectArray)) {
        console.error('nova-json-input-array: no array provided for objectArray value');
      }
    }
    this.initialize();
  }

  initialize() {
    this._arrayValue = this._addInternalKeys(this.objectArray);
  }

  get value() {
    return this._stripInternalKeys(this._arrayValue);
  }

  get allJsonInputs() {
    return Array.from(this.shadowRoot.querySelectorAll('nova-json-input'));
  }

  get allInputsHaveValidJson() {
    return this.allJsonInputs.every(input => {
      input.validate();
      return !input.hasInvalidJsonInJsonView;
    });
  }

  get allInputsValidPerNovaSchema() {
    if (!this.novaModelSchema?.isValid) return undefined;
    return this.allJsonInputs.every(input => {
      input.validate();
      return input.isValidPerNovaModelSchema;
    });
  }

  get valueHasChanged() {
    return this._valueChanged;
  }

  get _firstButtonContainer() {
    return html`
      <div class="top-container">
        <label class="d2l-input-label">${this.label}</label>
        <div class="collapse-button-container">
          ${this._collapseAllButton}
          ${this._expandAllButton}
        </div>
      </div>
    `;
  }

  get _lastButtonContainer() {
    return html`
      <div class="last-button-container">
        ${this._addObjectButton}
      </div>
    `;
  }

  get _addObjectButton() {
    return html`
      <d2l-button
        ?disabled=${this.disabled}
        class="add-object-button"
        @click=${this._handleAddObject}>
        ${this.localize('nova-json-input-array.addObject')}
      </d2l-button>
    `;
  }

  get _collapseAllButton() {
    return html`
      <d2l-button-subtle
        ?disabled=${!this._arrayValue?.length}
        @click=${this._onCollapseAll}
        text=${this.localize('general.button-text.collapseAll')}>
      </d2l-button-subtle>`;
  }

  get _expandAllButton() {
    return html`
      <d2l-button-subtle
        ?disabled=${!this._arrayValue?.length}
        @click=${this._onExpandAll}
        text=${this.localize('general.button-text.expandAll')}>
      </d2l-button-subtle>`;
  }

  _onCollapseAll() {
    this._allCollapsibles?.forEach(collapsible => collapsible.expanded = false);
  }

  _onExpandAll() {
    this._allCollapsibles?.forEach(collapsible => collapsible.expanded = true);
  }

  get _allCollapsibles() {
    return this.shadowRoot.querySelectorAll('d2l-collapsible-panel');
  }

  _handleJsonChange(e) {
    const jsonInput = e.currentTarget;
    const arrayIndex = jsonInput.getAttribute('data-index');
    this._arrayValue[arrayIndex] = jsonInput.value;
  }

  _collapsibleJsonInput(object, index) {
    const titleText = object[this.titleKey] || this.localize('nova-json-input-array.objectTitle', { index });
    const summaryText = object[this.summaryKey] ?? '';
    const summaryItem = html`
      <d2l-collapsible-panel-summary-item
        slot="summary"
        text=${summaryText}>
      </d2l-collapsible-panel-summary-item>
    `;

    const jsonInputField = html`
      <nova-json-input
        ?disabled=${this.disabled}
        data-index=${index}
        .object=${object}
        .novaModelSchema=${this.novaModelSchema}
        .hiddenKeys=${this.hiddenKeys}
        ?json-view-hidden=${this.jsonViewsHidden}
        @nova-json-input-changed=${this._handleJsonChange}>
      </nova-json-input>
    `;

    return html`
      <d2l-collapsible-panel
        panel-title=${titleText}>
        ${this.summaryText !== '' ? summaryItem : nothing}
        ${this._contextMenu(index)}
        ${jsonInputField}
      </d2l-collapsible-panel>
    `;
  }

  _handleAddObject() {
    let newObject = { [UNIQUE_INTERNAL_KEY]: ++this._uniqueKeyCounter };
    if (typeof this.defaultNewObject === 'object' && !Array.isArray(this.defaultNewObject)) {
      newObject = { ...newObject, ...this.defaultNewObject };
    } else {
      // can preload obj with title/summary keys if provided
      if (this.titleKey) newObject[this.titleKey] = '';
      if (this.summaryKey) newObject[this.summaryKey] = '';
    }
    this._arrayValue.push(newObject);
    this.update();
  }

  _onDuplicate(e) {
    const index = parseInt(e.currentTarget.parentElement.getAttribute('data-index'));
    const copyObject = { ...this._arrayValue[index] };
    this._arrayValue.splice(index, 0, copyObject);
    this.update();
  }

  _onDeleteItem(e) {
    const index = parseInt(e.currentTarget.parentElement.getAttribute('data-index'));
    this._arrayValue.splice(index, 1);
    this.update();
  }

  // Add unique keys to each item so that the repeat directive works properly
  _addInternalKeys(array) {
    return array.map(object => {
      return {
        [UNIQUE_INTERNAL_KEY]: ++this._uniqueKeyCounter,
        ...object,
      };
    });
  }

  _stripInternalKeys(array) {
    return array.map(object => this._stripInternalKey(object));
  }

  _stripInternalKey(object) {
    const strippedObject = { ...object };
    delete strippedObject[UNIQUE_INTERNAL_KEY];
    return strippedObject;
  }

  get _arrayContentContainer() {
    if (!this._arrayValue?.length) {
      return html`
        <div class="empty-list-container">
          <p>${this.localize('nova-json-input-array.empty')}</p>
        </div>
      `;
    }

    return repeat(this._arrayValue, object => object[UNIQUE_INTERNAL_KEY], (object, index) => {
      return this._collapsibleJsonInput(this._stripInternalKey(object), index);
    });
  }

  _contextMenu(index) {
    if (this.disabled) return nothing;
    return html`
      <d2l-dropdown-more text="" slot="actions">
        <d2l-dropdown-menu>
          <d2l-menu label=${this.localize('nova-json-input-array.contextMenu.duplicateObject')} data-index=${index} >
            <d2l-menu-item
              @click=${this._onDuplicate}
              text=${this.localize('nova-json-input-array.contextMenu.duplicateObject')}>
            </d2l-menu-item>
            <d2l-menu-item
              @click=${this._onDeleteItem}
              data-index=${index}
              text=${this.localize('nova-json-input-array.contextMenu.deleteObject')}>
            </d2l-menu-item>
          </d2l-menu>
        </d2l-dropdown-menu>
      </d2l-dropdown-more>
    `;
  }

  render() {
    if (!this.objectArray) return nothing;
    return [
      this._firstButtonContainer,
      this._arrayContentContainer,
      this._lastButtonContainer,
    ];
  }
}

window.customElements.define('nova-json-input-array', NovaInputJsonArray);
