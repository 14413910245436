export const NovaFlowProgressBar = superclass => class extends superclass {

  static get properties() {
    return {
      steps: { type: Array },
      current: { type: Number },
    };
  }

  constructor() {
    super();
    this.steps = [];
    this.current = 0;
  }

  connectedCallback() {
    super.connectedCallback();
    this.addEventListener('nova-flow-step-changed', this._handleStepChanged.bind(this));
    this.addEventListener('nova-flow-steps-changed', this._handleStepsChanged.bind(this));
  }

  disconnectedCallback() {
    this.removeEventListener('nova-flow-step-changed', this._handleStepChanged.bind(this));
    this.removeEventListener('nova-flow-steps-changed', this._handleStepsChanged.bind(this));
    super.disconnectedCallback();
  }

  _handleStepsChanged(e) {
    this.steps = e.detail.steps;
  }

  _handleStepChanged(e) {
    this.current = e.detail.step;
  }
};
