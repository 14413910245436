import '@brightspace-ui/core/components/icons/icon.js';
import { SkeletonMixin } from '@brightspace-ui/core/components/skeleton/skeleton-mixin.js';

import { css, html, LitElement } from 'lit';

import { LocalizeNova } from '../../../mixins/localize-nova/localize-nova.js';

class NovaSelectionGroup extends LocalizeNova(SkeletonMixin(LitElement)) {

  static get properties() {
    return {
      selectedValue: { type: String, reflect: true, attribute: 'selected-value' },
      for: { type: String },
      layout: { type: String },
      gap: { type: String },
    };
  }

  static get styles() {
    return css`
      :host {
        display: inline-block;
      }

      .nova-selection-group {
        display: flex;
        flex-direction: var(--nova-selection-group-layout, row);
        gap: var(--nova-selection-group-gap, 18px);
      }
    `;
  }

  constructor() {
    super();
    this.selectedValue = '';
    this.for = '';
    this.layout = 'row';
    this.gap = '18px';
  }

  updated(changedProperties) {
    if (changedProperties.has('selectedValue')) {
      this._updateChildSelections();
    }

    if (changedProperties.has('layout')) {
      this.style.setProperty('--nova-selection-group-layout', this.layout);
    }

    if (changedProperties.has('gap')) {
      this.style.setProperty('--nova-selection-group-gap', this.gap);
    }
  }

  _handleSelection(e) {
    this.selectedValue = e.detail.value;
    this.dispatchEvent(new CustomEvent('nova-selection-group-selection-changed', {
      bubbles: true,
      composed: true,
      detail: {
        for: this.for,
        selectedValue: this.selectedValue,
      },
    }));
  }

  _updateChildSelections() {
    Array.from(this.children).forEach(child => {
      child.selected = child.value === this.selectedValue;
    });
  }

  render() {
    return html`
      <div @nova-selection-group-item-selected=${this._handleSelection} class="nova-selection-group">
        <slot></slot>
      </div>
    `;
  }
}

window.customElements.define('nova-selection-group', NovaSelectionGroup);

