import '@brightspace-ui/core/components/dropdown/dropdown-button-subtle.js';
import '@brightspace-ui/core/components/dropdown/dropdown-menu.js';
import '@brightspace-ui/core/components/menu/menu.js';
import '@brightspace-ui/core/components/menu/menu-item.js';
import { SkeletonMixin } from '@brightspace-ui/core/components/skeleton/skeleton-mixin.js';

import { css, html, LitElement } from 'lit';
import { repeat } from 'lit/directives/repeat.js';

import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';

import { ALL_LANG_KEYS, ALL_LANGUAGES, SUPPORTED_LANG_KEYS } from '../../../../../shared/l10n/localize.js';
import { LocalizeNova } from '../../../mixins/localize-nova/localize-nova.js';

class NovaLangSelectButton extends RequesterMixin(LocalizeNova(SkeletonMixin(LitElement))) {
  static get properties() {
    return {
      language: { type: String },
    };
  }

  static get styles() {
    return css`
      :host {
        display: inline-block;
      }
    `;
  }

  constructor() {
    super();
    this._languageKeys = SUPPORTED_LANG_KEYS;
    this.language = 'en';
  }

  connectedCallback() {
    super.connectedCallback();
    this.session = this.requestInstance('d2l-nova-session');

    const browserLanguage = navigator.languages ? navigator.languages[0] : navigator.language;
    let language = this._determineSupportedLanguages(browserLanguage);
    if (this.session.user.hasSetting('language')) {
      language = this.session.user.getSetting('language');
    }
    this.language = language;
  }

  _determineSupportedLanguages(languageCode) {
    let languageKeys;
    if (this.session.hasFeature('showUnApprovedLanguages')) {
      languageKeys = ALL_LANG_KEYS;
    } else {
      languageKeys = SUPPORTED_LANG_KEYS;
    }
    this._languageKeys = languageKeys;
    const lowerCaseLangCode = languageCode.toLocaleLowerCase();
    if (languageKeys.includes(lowerCaseLangCode)) {
      return lowerCaseLangCode;
    }
    // If specific lang with subtag not found (e.g., 'fr-ca'), then check primary tag (e.g., just 'fr')
    const primaryLang = lowerCaseLangCode.split('-')[0];
    if (languageKeys.includes(primaryLang)) {
      return primaryLang;
    }
    return 'en';
  }

  setLang(e) {
    const targetLanguage = e.target.getAttribute('data-language');
    this.dispatchEvent(new CustomEvent('nova-change-language', {
      detail: {
        language: targetLanguage,
      },
      bubbles: true,
      composed: true,
    }));
    this.language = targetLanguage;
  }

  render() {
    return html`
      <d2l-dropdown-button-subtle text="${this.localize(`general.language.${this.language}`)}">
        <d2l-dropdown-menu>
          <d2l-menu label="${this.localize('general.language')}">
            ${repeat(this._languageKeys, key => key, key => html`
              <d2l-menu-item text=${ALL_LANGUAGES[key]} data-language="${key}" @click=${this.setLang}></d2l-menu-item>
            `)}
          </d2l-menu>
        </d2l-dropdown-menu>
      </d2l-dropdown-button-subtle>
    `;
  }
}

window.customElements.define('nova-lang-select-button', NovaLangSelectButton);

