import '@brightspace-ui/core/components/button/button.js';

import { SkeletonMixin } from '@brightspace-ui/core/components/skeleton/skeleton-mixin.js';

import { css, html, LitElement } from 'lit';

import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';

import { LocalizeNova } from '../../../shared/mixins/localize-nova/localize-nova.js';
import { NovaNavMixin } from '../../../../app/shared/mixins/nova-nav/nova-nav.js';

import '../../../admin/components/users-table/users-table.js';
import '../settings-edit-user/settings-edit-user.js';
import '../settings-invite-user/settings-invite-user.js';

class SettingsUserManagement extends LocalizeNova(SkeletonMixin(NovaNavMixin(RequesterMixin(LitElement)))) {
  static get properties() {
    return {
      query: { type: Object },
      allRoles: { type: Array },
      view: { type: String },
      guid: { type: String },
    };
  }

  static get styles() {
    return css`
      :host {
        display: flex;
        flex: 1;
        flex-flow: column;
        gap: 32px;
      }
    `;
  }

  constructor() {
    super();
    this.allRoles = [];
  }

  connectedCallback() {
    super.connectedCallback();
    this.client = this.requestInstance('d2l-nova-client');
    this.session = this.requestInstance('d2l-nova-session');
  }

  async firstUpdated() {
    this.skeleton = true;
    this.tenantId = this.session._tenant.id;
    this.allRoles = await this.client.getRolesByTenantId(this.tenantId);
    this.skeleton = false;
  }

  async updated(changedProperties) {
    if (changedProperties.has('query') && this.query.guid) {
      this.guid = this.query.guid;
    }
  }

  editUser(e) {
    this.navigate(`/settings/user-management/edit?guid=${e.detail.guid}`);
  }

  _doneCreatingUser() {
    this.navigate('/settings/user-management');
  }

  render() {
    if (this.view === 'create') {
      return html`<settings-invite-user @done=${this._doneCreatingUser}></settings-invite-user>`;
    } else if (this.view === 'edit') {
      return html`<settings-edit-user .allRoles="${this.allRoles}" guid="${this.guid}"></settings-edit-user>`;
    }
    return html`
      <div>
        ${this.localize('settings-user-management.subtitle')}
      </div>
      <users-table class="d2l-skeletize" .allRoles="${this.allRoles}" @edit-user="${this.editUser}"></users-table>`;
  }
}

window.customElements.define('settings-user-management', SettingsUserManagement);

