import '@brightspace-ui/core/components/button/button.js';
import '@brightspace-ui/core/components/dialog/dialog.js';
import '@brightspace-ui/core/components/inputs/input-text.js';
// eslint-disable-next-line import/no-unresolved
import '@brightspace-ui/labs/components/pager-numeric.js';

import './activity-table-row/activity-table-row.js';
import '../activity-filter/activity-filter.js';
import '../../general/nova-tooltip/nova-tooltip.js';
import '../../general/nova-permission-container/nova-permission-container.js';
import '../../../../main/components/general/nova-button-subtle/nova-button-subtle.js';

import { css, html, LitElement } from 'lit';
import { navigator as nav } from 'lit-element-router';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';
import { selectStyles } from '@brightspace-ui/core/components/inputs/input-select-styles.js';
import { SkeletonMixin } from '@brightspace-ui/core/components/skeleton/skeleton-mixin.js';

import { offscreenStyles } from '@brightspace-ui/core/components/offscreen/offscreen.js';

import Activity from '../../../../../shared/models/activity/activity.js';
import ActivityFilter from '../../../models/activity-filter/activity-filter.js';
import { CSV_EXPORTABLE_ATTRIBUTES } from '../../../../../shared/models/activity/generic-activity.js';
import { LocalizeNova } from '../../../mixins/localize-nova/localize-nova.js';
import { mapify } from '../../../../../shared/methods.js';
import { novaLottieMixin } from '../../../../shared/mixins/nova-lottie-mixin/nova-lottie-mixin.js';
import { NovaPermissionMixin } from '../../../mixins/nova-permission-mixin/nova-permission-mixin.js';
import { novaTableStyles } from '../../../../main/components/applications/application-table/styles.js';

/**
 * Shows a list of the activities
 */
export default class ActivitiesTable extends NovaPermissionMixin(LocalizeNova(SkeletonMixin(RequesterMixin(novaLottieMixin(nav(LitElement)))))) {

  static get properties() {
    return {
      providers: { type: Array },
      _activities: { type: Array, attribute: false },
      _exportAttributes: { type: Array, attribute: false },
      _search: { type: String, attribute: false },
      _skills: { type: Array, attribute: false },
      _pageNumber: { type: Number, attribute: false },
      _activitiesPerPage: { type: Number, attribute: false },
      _confirmationDialogOpened: { type: Boolean, attribute: false, reflect: false },
      _filter: { type: Object, attribute: false },
      exportButtonText: { type: String },
      exportAnimationText: { type: String },
      exportIcon: { type: String },
      exportButtonDisabled: { type: Boolean },
      exportAnimationBackground: { type: String },
    };
  }

  static get styles() {
    return [
      super.styles,
      novaTableStyles,
      selectStyles,
      offscreenStyles,
      css`
        .submit-buttons {
          padding-top: 10px;
        }

        d2l-input-text {
          padding-bottom: 1.5rem;
        }

        .action-filter {
          margin-bottom: 10px;
        }

        .icon-animation {
          height: 23px;
          left: 10px;
          opacity: 0;
          position: absolute;
          top: 13px;
          width: 23px;
        }

        .animation-opacity {
          opacity: 1;
        }

        .d2l-label-text {
          font-size: 0.7rem;
          font-weight: 700;
          letter-spacing: 0.2px;
          line-height: 1rem;
          margin-right: 10px;
          white-space: nowrap;
        }

        .message {
          height: 200px;
        }


        .provider-logo {
          height: auto;
          max-height: 30px;
          max-width: 100%;
          width: auto;
        }

        .table-row, .table-header {
          grid-template-columns: 100px 200px 100px auto 100px 100px 50px;
          padding: 5px 20px;
        }

        .table-item {
          align-items: center;
          display: flex;
        }

        .requestable {
          margin: auto;
        }

        .initiator {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 60px;
        }
        .export-attributes {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
        }
`,
    ];
  }

  constructor() {
    super();
    this._activitiesPerPage = 20;
    this._pageNumber = 1;
    this._search = '';
    this._activities = [];
    this._applications = [];
    this._selectedActivity = new Activity();
    this._filter = new ActivityFilter();
    this._confirmationDialogOpened = false;
    this._exportAttributes = CSV_EXPORTABLE_ATTRIBUTES;
    this.exportIcon = 'tier1:download';
    this.exportButtonDisabled = false;
    this.exportButtonText = this.localize('activity-table.header.export');
    this.exportAnimationText = '';
    this.exportAnimationBackground = '';
    this._pollCounter = 0;
  }

  get filter() {
    return this._filter;
  }

  set filter(filter) {
    this._filter = new ActivityFilter(filter);
  }

  connectedCallback() {
    super.connectedCallback();
    this.client = this.requestInstance('d2l-nova-client');
    this.session = this.requestInstance('d2l-nova-session');
  }

  async firstUpdated() {
    this.container = this.shadowRoot.getElementById('animation-container');
    this.animationUrl = '/assets/animation/csv-export-animation.json';
    this.loop = true;
    this.autoPlay = true;
    this.providers = await this.client.listTenants('provider');
    const skillData = await this.client.getSkills({});
    this._skills = skillData.skillCounts;
    await this._updateProviderMapAndFilter();
    super.firstUpdated();
  }

  async updated(changedProperties) {
    if (changedProperties.has('providers')) {
      await this._updateProviderMapAndFilter();
    }
    super.updated(changedProperties);
  }

  async _updateProviderMapAndFilter() {
    this._providerMap = mapify(this.providers, 'id');
    this.filter = { ...this.session.user.getSetting('filters'), validProviders: this.providers };
    await this._updateTable();
  }

  async _openExportDialog() {
    this.shadowRoot.querySelector('#export-dialog').opened = true;
  }

  _importClicked() {
    this.navigate('/activities/import');
  }

  render() {
    const activities = this._activities?.hits?.map(a => new Activity(a)) || [];
    return html`
      <nova-permission-container .updatePermissions="${['activity:view']}">
        <d2l-input-text id="search" label="Search" @change="${this._handleSearchChange}" .value=${this.filter.searchCriteria}></d2l-input-text>
      </nova-permission-container>

      <nova-button-subtle .updatePermissions="${['activity:export']}"
        id='export-button'
        ?disabled=${this.exportButtonDisabled || !this.canUpdate}
        icon="${this.exportIcon}"
        text="${this.exportButtonText}"
        @click=${this._openExportDialog}>
        <slot name="default">
          <div id="animation-container" class="icon-animation ${this.exportAnimationBackground}"></div>
          <span>${this.exportAnimationText}</span>
        </slot>
      </nova-button-subtle>

      <nova-button-subtle .updatePermissions="${['activity:streams:import']}"
        id="import-button"
        icon="tier1:upload"
        text="${this.localize('activity-table.header.import')}"
        ?disabled=${!this.canUpdate}
        @click="${this._importClicked}">
      </nova-button-subtle>

      <activity-filter
        .filter=${this.filter}
        ?skeleton=${this.skeleton}
        .skills=${this._skills}
        @filter-changed=${this._filterChange}
        .showActiveFilter=${this.session.tenant.type === 'admin'}
      >
      </activity-filter>
      <div class="nova-table" role="table">
        <div class="table-header d2l-skeletize" role="row">
          <div class="table-item active" role="columnheader">
            <nova-tooltip class="tooltip" text="${this.localize('activity-table.header.active')}" tooltip-text="${this.localize('activity-table.header.active.tooltip')}"></nova-tooltip>
          </div>
          <div class="table-item provider" role="columnheader">${this.localize('activity-table.header.provider')}</div>
          <div class="table-item" role="columnheader">${this.localize('activity-table.header.type')}</div>
          <div class="table-item action" role="columnheader">${this.localize('activity-table.header.title')}</div>
          <div class="table-item requestable" role="columnheader">
            <nova-tooltip class="tooltip" text="${this.localize('activity-table.header.req')}" tooltip-text="${this.localize('activity-table.header.req.tooltip')}"></nova-tooltip>
          </div>
          <div class="table-item taxable" role="columnheader">
            <nova-tooltip class="tooltip" text="${this.localize('activity-table.header.tax')}" tooltip-text="${this.localize('activity-table.header.tax.tooltip')}"></nova-tooltip>
          </div>
          <div class="table-item actions" role="columnheader"><span class="d2l-offscreen offscreen-display-name">${this.localize('activity-table.header.actions')}</span></div>
        </div>
        ${activities.map(a => html`
          <activity-table-row
            .activity=${a}
            .provider=${this._providerMap[a.provider]}
            ?skeleton=${this.skeleton}
            @nova-activity-state-change="${this._activeStateChanged}"
          >
          </activity-table-row>
        `)}
      </div>
      <d2l-labs-pager-numeric
        id="pagination"
        @d2l-labs-pager-numeric-page-change=${this._pageChanged}
        page-number=${this._pageNumber}
        max-page-number=${this._maxPageNumber}></d2l-labs-pager-numeric>
      ${this.confirmationDialogTemplate()}
      ${this.exportDialogTemplate()}
    `;
  }

  confirmationDialogTemplate() {
    const cancelText = this._applications.length > 0 ? this.localize('activity-table.confirmation.cancelText', { applicationsLength: this._applications.length }) : '';
    const disableText = this.localize('activity-table.confirmation.disabledText', { cancelText: cancelText });
    const enableText = this.localize('activity-table.confirmation.enableText');
    const dialogText = !this._selectedActivity.hasTag('active') ? enableText : disableText;
    return html`
      <d2l-dialog-confirm
          id="dialog-confirm"
          text="${dialogText}"
          title-text="${this.localize('activity-table.confirmation.header')}"
          @d2l-dialog-close=${this._dialogClose()}>
          <d2l-button slot="footer" primary data-dialog-action="yes">${this.localize('general.button-text.yes')}</d2l-button>
          <d2l-button slot="footer" data-dialog-action>${this.localize('general.button-text.no')}</d2l-button>
        </d2l-dialog-confirm>`;
  }

  _handleExportAttributeChange(e) {
    const { value, checked } = e.target;
    if (checked) {
      this._exportAttributes.push(value);
    } else {
      this._exportAttributes = this._exportAttributes.filter(a => a !== value);
    }
  }

  exportDialogTemplate() {
    return html`
      <d2l-dialog
        id="export-dialog"
        title-text="${this.localize('activity-table.exportDialog.title')}">
        <p>${this.localize('activity-table.exportDialog.text')}</p>
        <div class="export-attributes">
          ${CSV_EXPORTABLE_ATTRIBUTES.map(a => html`
            <d2l-input-checkbox ?disabled="${a === 'id'}" ?checked=${this._exportAttributes.includes(a)} @change=${this._handleExportAttributeChange} value="${a}">${a}</d2l-input-checkbox>
          `)}
        </div>
        <d2l-button slot="footer"
                    data-dialog-action="cancel">
          ${this.localize('general.button-text.cancel')}
        </d2l-button>
        <d2l-button slot="footer"
                    @click="${this.export}"
                    data-dialog-action="done"
                    primary>
          ${this.localize('general.button-text.export')}
        </d2l-button>
      </d2l-dialog>
    `;
  }

  async export() {
    // Update the button appearance to indicate its animation state
    this.exportButtonText = '';
    this.exportAnimationText = this.localize('activity-table.exportDialog.exporting');
    this.exportButtonDisabled = true;
    this.exportIcon = '';
    this.exportAnimationBackground = 'animation-opacity';

    await this._exportToCSV();
  }

  async delay(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  resetExportButton() {
    this.exportButtonText = this.localize('activity-table.header.export');
    this.exportAnimationText = '';
    this.exportButtonDisabled = false;
    this.exportIcon = 'tier1:download';
    this.exportAnimationBackground = '';
  }

  async _exportToCSV() {
    try {
      await this.client.exportActivities({ filters: this._filter, attributes: this._exportAttributes });
      this.session.toast({ type: 'default', message: this.localize('application-table.export.processing'), noAutoClose: false });
      await this.delay(1500);
      await this._pollS3();
    } catch (error) {
      this._pollCounter = 0;
      this.resetExportButton();
      this.session.toast({ type: 'critical', message: this.localize('application-table.export.error'), noAutoClose: false });
    }
  }

  async _pollS3() {
    const exportStatus = await this.client.checkActivitiesSearchStatus(this.session.tenant.id, this.session.user.guid);
    if (exportStatus.ready) {
      this.session.toast({ type: 'success', message: this.localize('application-table.export.complete'), noAutoClose: false });
      this._pollCounter = 0;
      // Reset the button to initial state
      this.resetExportButton();
      this.client.getActivityReport(this.session.tenant.id, this.session.user.guid);
    } else {
      this._pollCounter = this._pollCounter + 1;
      if (this._pollCounter > 40) {
        this.session.toast({ type: 'critical', message: this.localize('application-table.export.error'), noAutoClose: false });
        // Reset the button to initial state
        this.resetExportButton();
        throw Error('Exceeded 40 tries to pull exported file');
      }
      setTimeout(this._pollS3.bind(this), 1500);
    }
  }

  get _maxPageNumber() {
    return this._totalActivityRecords > 0 ? Math.ceil(this._totalActivityRecords / this._activitiesPerPage) : 1;
  }

  get _pageStartIndex() {
    return (this._pageNumber - 1) * this._activitiesPerPage;
  }

  get _totalActivityRecords() {
    return this._activities?.total?.value || 0;
  }

  async _activeStateChanged(e) {
    this.shadowRoot.querySelector('#dialog-confirm').opened = true;
    const { activity } = e.detail;
    this._selectedActivity = activity;
    this._applications = (await this.client.getApplicationsForActivity(activity.id, true)).filter(a => (a.isApprovedOnly || a.isPending) && !a.isCancelled);
    this._confirmationDialogOpened = true;
  }

  _dialogClose() {
    return async e => {
      this._confirmationDialogOpened = false;
      const { action } = e.detail;
      const newState = !this._selectedActivity.hasTag('active');
      const activityId = this._selectedActivity.id;
      switch (action) {
        case 'yes':
          await this.client.setActivityActive(activityId, newState);
          for (const act of this._activities.hits) {
            if (act.id === activityId) {
              // act.tags = newState ? act.tags.filter(t => t !== 'inactive') : [...act.tags, 'inactive'];
              act.tags = !newState ? act.tags.filter(t => t !== 'active') : [...act.tags, 'active'];
              this._selectedActivity.setTag('active', newState);
              break;
            }
          }
          break;
        case 'no':
          break;
      }
      await this._updateState();
    };
  }

  async _filterChange(e) {
    if (e && e.detail.filter) {
      this.filter = e.detail.filter;
    }
    this.filter = new ActivityFilter(this.filter);

    this.client.setSessionSetting('filters', this.filter);
    this._pageNumber = 1;
    await this._updateTable();
  }

  async _handleSearchChange(e) {
    this._search = e.target?.value;
    this.filter.searchCriteria = this._search.trim();
    await this._filterChange();
  }

  async _pageChanged(e) {
    this._pageNumber = e.detail.page;
    await this._updateTable();
  }

  async _updateState() {
    this.skeleton = true;
    await this.update();
    this.skeleton = false;
  }

  async _updateTable() {
    this.skeleton = true;
    this._activities = await this.client.searchActivities({
      from: this._pageStartIndex,
      size:this._activitiesPerPage,
      filters: this.filter,
    });

    this.skeleton = false;
  }

}

window.customElements.define('activity-table', ActivitiesTable);
