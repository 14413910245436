import '@brightspace-ui/core/components/inputs/input-textarea.js';
import '@brightspace-ui/core/components/icons/icon.js';
import '@app/shared/components/skills/skill-chip-list/skill-chip-list.js';
import '@brightspace-ui/core/components/button/button.js';

import { css, html, LitElement, nothing } from 'lit';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';

import { skillswaveGradientTextStyles, skillswaveHeading1Styles, skillswaveHeading2Styles, skillswaveParagraphSubtleStyles } from '@/app/shared/styles/skillswave-text-styles.js';
import { LocalizeNova } from '@/app/shared/mixins/localize-nova/localize-nova.js';
import { NovaFlowStepMixin } from '@/app/shared/mixins/nova-flow-step-mixin/nova-flow-step-mixin.js';
import { OBJECTIVES } from '../client-onboarding-objective/client-onboarding-objective.js';

class ClientOnboardingSkills extends RequesterMixin(NovaFlowStepMixin(LocalizeNova(LitElement))) {

  static get properties() {
    return {
      lightcastCompanyId: { type: String, attribute: 'lightcast-company-id' },
      _curatedSkills: { type: Array },
      _describedSkills: { type: Array },
      _generateButtonDisabled: { type: Boolean },
    };
  }

  static get styles() {
    return [
      skillswaveHeading1Styles,
      skillswaveHeading2Styles,
      skillswaveGradientTextStyles,
      skillswaveParagraphSubtleStyles,
      css`
        :host {
          display: block;
        }
      `,
    ];
  }

  constructor() {
    super();

    this._curatedSkills = [];
    this._describedSkills = [];
    this._generateButtonDisabled = true;
  }

  async connectedCallback() {
    super.connectedCallback();

    this.client = this.requestInstance('d2l-nova-client');
    this.session = this.requestInstance('d2l-nova-session');
  }

  async updated(changedProperties) {
    if (
      (changedProperties.has('lightcastCompanyId') || changedProperties.has('novaFlowData'))
      && this.lightcastCompanyId && this.novaFlowData
    ) {
      if (this.novaFlowData.industry?.industryList || this.novaFlowData.department?.department) {
        const lightcastResponse = await this.client.lightcastRecommendedSkills(
          this.lightcastCompanyId,
          {
            skill_categories_name: this.novaFlowData.industry?.industryList,
            lot_career_area_name: this.novaFlowData.department?.department ? [this.novaFlowData.department?.department] : undefined,
          }
        );

        this._curatedSkills = lightcastResponse.skillSubCategories.map(skillName => ({
          id: skillName,
          name: skillName,
        }));
      }
    }
  }

  async _getskills() {
    const skillsDescription = this.shadowRoot.getElementById('skills-description-input').value;

    try {
      let generatedSkills;
      try {
        generatedSkills = await this.client.lightcastGetSkills(skillsDescription);
      } catch {
        generatedSkills = [];
      }

      if (generatedSkills.length === 0) {
        throw new Error('No skills generated');
      }

      const existingSkillIds = new Set(this._describedSkills.map(skill => skill.id));

      this._describedSkills = [
        ...generatedSkills.filter(skill => !existingSkillIds.has(skill.id)),
        ...this._describedSkills,
      ];

      this._generateButtonDisabled = true;
    } catch (e) {
      this.session.toast({
        message: this.localize('view-client-onboarding.client-onboarding-skills.no-skills-generated'),
        type: 'error',
      });
    }
  }

  _removedDescribedSkill(e) {
    const removedSkill = e.detail.skill;
    const removedSkillIndex = this._describedSkills.findIndex(skill => skill.id === removedSkill.id);

    if (removedSkillIndex !== -1) {
      this._describedSkills = this._describedSkills.toSpliced(removedSkillIndex, 1);
    }
  }

  _removedCuratedSkill(e) {
    const removedSkill = e.detail.skill;
    const removedSkillIndex = this._curatedSkills.findIndex(skill => skill.id === removedSkill.id);

    if (removedSkillIndex !== -1) {
      this._curatedSkills = this._curatedSkills.toSpliced(removedSkillIndex, 1);
    }
  }

  _promptInput(e) {
    this._generateButtonDisabled = e.target.value.length === 0;
  }

  render() {
    return html`
      ${this._renderHeader()}
      ${this._renderPrompt()}
      ${this._renderDescribedSkills()}
      ${this._renderCuratedSkills()}
      ${!this.blockBack ? html`
        <d2l-button class="nova-flow-previous" @click=${this.goBack}>${this.localize('general.button-text.previous')}</d2l-button>
      ` : nothing }
      <d2l-button class="nova-flow-next" primary ?disabled=${!this.nextEnabled} @click=${this.goNext}>${this.localize('general.button-text.next')}</d2l-button>
    `;
  }

  _renderHeader() {
    return html`
      <h1 class="skillswave-heading-1">
        ${this.localize('view-client-onboarding.client-onboarding-skills.title', { ...this._isCompany })}
      </h1>
      <p class="skillswave-paragraph-subtle">
        ${this.localize('view-client-onboarding.client-onboarding-skills.description')}
      </p>
    `;
  }

  _renderPrompt() {
    return html`
      <d2l-input-textarea
        label="${this.localize('view-client-onboarding.client-onboarding-skills.textarea-label', { ...this._isCompany })}"
        label-hidden
        placeholder=${this.localize('view-client-onboarding.client-onboarding-skills.textarea-placeholder', { ...this._isCompany })}
        id="skills-description-input"
        @input=${this._promptInput}>
      </d2l-input-textarea>
      <d2l-button
        primary
        ?disabled=${this._generateButtonDisabled}
        @click=${this._getskills}>
        ${this.localize('view-client-onboarding.client-onboarding-skills.generate-skills-button')}
      </d2l-button>
    `;
  }

  _renderDescribedSkills() {
    if (!this._describedSkills || this._describedSkills.length === 0) return nothing;

    return html`
      <h2 class="skillswave-heading-2 skillswave-gradient-text">
        <d2l-icon icon="tier1:ai"></d2l-icon>
        ${this.localize('view-client-onboarding.client-onboarding-skills.skills-you-want', { ...this._isCompany })}
      </h2>
      <skill-chip-list
        .skills=${this._describedSkills}
        max-rows=2
        max-skills=10
        notSorted
        removable
        @nova-chip-remove=${this._removedDescribedSkill}>
      </skill-chip-list>
      <p class="skillswave-paragraph-subtle">
        ${this.localize('view-client-onboarding.client-onboarding-skills.want-more-skills')}
      </p>
    `;
  }

  _renderCuratedSkills() {
    if (!this._curatedSkills || this._curatedSkills.length === 0) return nothing;

    return html`
      <h2 class="skillswave-heading-2 skillswave-gradient-text">
        <d2l-icon icon="tier1:ai"></d2l-icon>
        ${this.localize('view-client-onboarding.client-onboarding-skills.skills-curated', { ...this._isCompany })}
      </h2>
      <skill-chip-list
        .skills=${this._curatedSkills}
        max-rows=2
        max-skills=4
        notSorted
        removable
        @nova-chip-remove=${this._removedCuratedSkill}>
      </skill-chip-list>
    `;
  }

  get _isCompany() {
    return {
      isCompany: this.novaFlowData?.objective?.objective === OBJECTIVES.MY_COMPANY,
    };
  }

}

window.customElements.define('client-onboarding-skills', ClientOnboardingSkills);
