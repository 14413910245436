import { css, html, LitElement } from 'lit';

import '@brightspace-ui/core/components/button/button-icon.js';
import { SkeletonMixin } from '@brightspace-ui/core/components/skeleton/skeleton-mixin.js';

import { LocalizeNova } from '../../../mixins/localize-nova/localize-nova.js';

class NovaPagination extends LocalizeNova(SkeletonMixin(LitElement)) {
  static get properties() {
    return {
      disableLast: { type: Boolean },
      isSearching: { type: Boolean },
      lastPage: { type: Number },
      pageNumber: { type: Number },
    };
  }

  constructor() {
    super();
    this.pageNumber = 1;
    this.isSearching = false;
  }

  static get styles() {
    return css`
      .pagination {
        align-items: center;
        display: flex;
        justify-content: center;
        margin: 1rem 0;
      }
    `;
  }

  _pageChanged(e) {
    const action = e.target.id;
    this.dispatchEvent(new CustomEvent('page-changed', { detail: { action } }));
  }

  render() {
    const isFirstPage = this.pageNumber === 1;
    const isLastPage = this.pageNumber === this.lastPage;

    return html`
      <div class="pagination">
        <d2l-button-icon ?disabled=${isFirstPage || this.isSearching} id="first" icon="tier2:chevron-left" text="First Page" @click=${this._pageChanged}></d2l-button-icon>
        <d2l-button-icon ?disabled=${isFirstPage || this.isSearching} id="previous" icon="tier1:chevron-left" text="Previous Page" @click=${this._pageChanged}></d2l-button-icon>

        <span>${this.pageNumber}</span>

        <d2l-button-icon ?disabled=${isLastPage || this.isSearching} id="next" icon="tier1:chevron-right" text="Next Page" @click=${this._pageChanged}></d2l-button-icon>
        <d2l-button-icon ?disabled=${this.disableLast || isLastPage || this.isSearching} id="last" icon="tier2:chevron-right" text="Last Page" @click=${this._pageChanged}></d2l-button-icon>
      </div>
    `;
  }
}

window.customElements.define('nova-pagination', NovaPagination);
