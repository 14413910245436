import { SkeletonMixin } from '@brightspace-ui/core/components/skeleton/skeleton-mixin.js';

import { css, html, LitElement } from 'lit';

import { LocalizeNova } from '../../../mixins/localize-nova/localize-nova.js';

class NovaDataAlert extends LocalizeNova(SkeletonMixin(LitElement)) {

  static get styles() {
    return css`
      :host {
        display: block;
      }

      .data-alert {
        background-color: var(--d2l-color-celestine-plus-2);
        border-radius: 6px;
      }

      .data-alert-container {
        padding: 18px 30px 18px 24px;
      }

      :host(:focus-visible) .data-alert {
        border-radius: 10px;
        outline: 2px solid var(--d2l-color-celestine);
        outline-offset: 4px;
      }
    `;
  }

  render() {
    return html`
      <div class="data-alert" tabIndex="0">
        <div class="data-alert-container">
          <slot></slot>
        </div>
      </div>
    `;
  }
}

window.customElements.define('nova-data-alert', NovaDataAlert);

