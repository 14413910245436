import { css, html, LitElement, nothing } from 'lit';
import { repeat } from 'lit/directives/repeat.js';

import '@brightspace-ui/core/components/button/button.js';
import '@brightspace-ui/core/components/list/list.js';
import '@brightspace-ui/core/components/list/list-item.js';
import { heading1Styles } from '@brightspace-ui/core/components/typography/styles.js';

import '../../../shared/components/general/app-link/app-link.js';
import '../../../shared/components/general/no-results/no-results.js';
import '../../../shared/components/general/nova-pagination/nova-pagination.js';
import '../../../shared/components/tenant-filter-search/tenant-filter-search.js';
import StepPaginationMixin from '../../../shared/mixins/step-pagination-mixin/step-pagination-mixin.js';

class NovaTenantList extends StepPaginationMixin(LitElement) {
  static get properties() {
    return {
      disableCreate: { type: Boolean, attribute: 'disable-create' },
    };
  }

  static get styles() {
    return [
      heading1Styles,
      css`
      .tenant-list-item app-link {
        z-index: 1;
      }

      .tenant-list-item {
        align-items: center;
        display: grid;
        grid-template-columns: 30% auto;
        width: 100%;
      }
      .create-button {
        margin-bottom: 12px;
      }
    `];
  }

  constructor() {
    super();
    this.disableCreate = false;
  }

  _handleCreateClick() {
    this.navigate(`tenants/add/${this.type}`);
  }

  render() {
    const createButton = this.disableCreate ? nothing : html`
        <d2l-button class="create-button" @click="${this._handleCreateClick}">${this.localize(`view-admin.addButton.${this.type}`)}</d2l-button>
      `;
    return html`
      <h1 class="d2l-heading-1">${this.localize(`view-admin.${this.type}.heading1`)}</h1>
      ${createButton}
      <tenant-filter-search @changed=${this.handleFilterSearch} ?disabled=${this._isSearching} type=${this.type}></tenant-filter-search>
      ${this._tenants.length === 0 && !this._isSearching ? html`<no-results></no-results>` : nothing}
      <d2l-list>
        ${repeat(this._tenants, tenant => tenant.id, tenant => html`
          <d2l-list-item>
            <div class="tenant-list-item">
              ${tenant?.imageUrl ? html`
                <img height="25px" alt="${tenant.name}" src="${tenant.imageUrl}">
              ` : html`
                <span>${tenant.name}</span>
              `}
              <app-link d2l-link href="/tenants/${tenant.id}/edit"><div>${tenant.name}</div></app-link>
            </div>
          </d2l-list-item>
        `)}
      </d2l-list>

      <nova-pagination
        ?disableLast=${!this._lastGoodToken || this._pagesFound === this._pageNumber}
        ?isSearching=${this._isSearching}
        lastPage=${this._lastPage}
        pageNumber=${this._pageNumber}
        @page-changed=${this._pageChanged}>
      </nova-pagination>
    `;
  }
}

window.customElements.define('nova-tenant-list', NovaTenantList);
