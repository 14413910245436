import { NovaSchema, SchemaAttribute } from '../nova-model-schema.js';

import { Cost } from '../../cost.js';
import { CustomAttribute } from '../../custom-attribute.js';
import { DEFAULT_CURRENCY } from '../../../constants.js';

export const APPROVAL_MODELS = {
  distributed: 'distributed',
  centralized: 'centralized',
  hybrid: 'hybrid',
};

export const BUDGET_CONFIGURATIONS = {
  CORPORATE_POLICY: 'corporatePolicy',
  CASE_BY_CASE: 'caseByCase',
  PERCENT_COVERAGE: 'percentCoverage',
};

export const ENTITLEMENTS = {
  employer: ['sponsor', 'watcher'],
  provider: ['admin'],
};

export const VALID_TAGS = [
  'adminRoles',
  'testData',
  'collectUserInfoOnCreate',
  'accountConnection',
  'careerExplorer',
  'd2l', // to differentiate between D2L corporate tenant and other employer tenants
  'ownRequestSubmit',
  'isCanadianBusiness',
  'requireUserEmails', // require all users to have an email to view wave, otherwise email collect view shows up
  'brightspaceInstance',
  'allowEmailEdit',
  'hideFastToCompleteStream',
  'paymentHandledByProvider',
  'automaticCatalogOptOut',
  'requireStudentID',
  'cacheAnalyticsReports',
  'plgTenant',
];

export const EMPTY_LIGHTCAST_SUGGESTION = {
  id: '-1',
  name: 'No lightcast mapping selected',
};

export const DEFAULT_CAREER_EXPLORER_DATA = {
  companies: [EMPTY_LIGHTCAST_SUGGESTION],
  industries: [EMPTY_LIGHTCAST_SUGGESTION],
  settings: {
    careerFilterSelectionMode: 'single',
    showLots: true,
    skillsAggregationStrategy: 'additiveUnion',
    skillsIncluded: 'specialized',
    skillsSignificanceThreshold: 1,
  },
};

export const DEFAULT_DAYS_UNTIL_INVOICE_DUE = 30;

class TenantModelSchema extends NovaSchema {

  constructor() {
    const attributes = {
      package: new SchemaAttribute('new-general'),
      description: new SchemaAttribute(),
      accentColour: new SchemaAttribute(),
      brightspaceName: new SchemaAttribute(),
      brightspaceURL: new SchemaAttribute(),
      attributeRequirements: new SchemaAttribute([]),
      accountCreationUrl: new SchemaAttribute(),
      allowableDomains: new SchemaAttribute([]),
      applicationCustomFields: new SchemaAttribute([]),
      approvalModel: new SchemaAttribute().setPossibleValues(Object.keys(APPROVAL_MODELS)),
      budget: new SchemaAttribute({}),
      taxNexus: new SchemaAttribute({ registrations: {} }),
      careerExplorer : new SchemaAttribute(),
      defaultDomain: new SchemaAttribute(),
      entitlements: new SchemaAttribute([]),
      faqs: new SchemaAttribute([]),
      features: new SchemaAttribute({}),
      financeEmail: new SchemaAttribute(),
      studentSupportEmail: new SchemaAttribute(),
      freshdeskId: new SchemaAttribute([]),
      homePageContent: new SchemaAttribute([]),
      id: new SchemaAttribute(),
      imageUrl: new SchemaAttribute(),
      imageUrlEmail: new SchemaAttribute(),
      name: new SchemaAttribute(),
      notes: new SchemaAttribute(),
      legalName: new SchemaAttribute(),
      operatingCurrency: new SchemaAttribute(DEFAULT_CURRENCY),
      programPolicy: new SchemaAttribute(),
      registrationTime: new SchemaAttribute(),
      revenueSharePct: new SchemaAttribute(),
      revShare: new SchemaAttribute({}),
      revShareExemption: new SchemaAttribute({ cost: 0 }).setType(Cost),
      studentIdFormat: new SchemaAttribute(),
      tags: new SchemaAttribute().setPossibleValues(VALID_TAGS),
      type: new SchemaAttribute(),
      learnerTerminology: new SchemaAttribute('employee').setPossibleValues(['employee', 'member', 'jobseeker']),
      userDataSystem: new SchemaAttribute('IDP').setPossibleValues(['IDP', 'HRIS']),
      customAttributes: new SchemaAttribute([]).setType(CustomAttribute, true),
      applicationAttributes: new SchemaAttribute([]).setType(CustomAttribute, true),
      daysUntilInvoiceDue: new SchemaAttribute(DEFAULT_DAYS_UNTIL_INVOICE_DUE),
      autoCatalogOptOut: new SchemaAttribute(),
    };
    super('tenant', attributes);
  }

}

export const TenantSchema = new TenantModelSchema();
