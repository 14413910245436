import '@brightspace-ui/core/components/icons/icon.js';
import { SkeletonMixin } from '@brightspace-ui/core/components/skeleton/skeleton-mixin.js';

import { css, html, LitElement, nothing } from 'lit';

import { skillswaveHeading3Styles, skillswaveParagraphSubtleStyles } from '@/app/shared/styles/skillswave-text-styles.js';
import { LocalizeNova } from '../../../mixins/localize-nova/localize-nova.js';
import { SelectionGroupItem } from '../../../mixins/selection-group-item-mixin/selection-group-item-mixin.js';

class NovaSelectionCard extends SelectionGroupItem(LocalizeNova(SkeletonMixin(LitElement))) {

  static get properties() {
    return {
      icon: { type: String },
      title: { type: String },
      description: { type: String },
    };
  }

  static get styles() {
    return [
      skillswaveHeading3Styles,
      skillswaveParagraphSubtleStyles,
      css`
        :host {
          display: flex;
          max-width: 224px;
        }

        .selection-card-outer {
          display: flex;
          height: 100%;
          width: 100%;
        }

        .selection-card {
          background-color: white;
          border-radius: 10px;
          height: 100%;
          outline: 1px solid var(--d2l-color-mica);
          width: 100%;
        }

        .card-icon-container {
          align-items: center;
          background-color: var(--d2l-color-sylvite);
          border-radius: 50%;
          display: flex;
          flex-direction: row;
          height: 48px;
          justify-content: center;
          width: 48px;
        }
        .card-icon-container d2l-icon {
          color: var(--d2l-color-ferrite);
        }

        :host([selected]) .selection-card {
          outline: 2px solid var(--d2l-color-celestine);
        }
        :host([selected]) .selection-card-outer {
          outline: none;
        }
        :host([selected]) .card-icon-container {
          background-color: var(--d2l-color-celestine-plus-2);
        }
        :host([selected]) .card-icon {
          color: var(--d2l-color-celestine);
        }

        :host(:hover) .selection-card {
          background-color: var(--d2l-color-celestine-plus-2);
        }
        :host(:hover) .card-icon-container {
          background-color: var(--d2l-color-celestine);
        }
        :host(:hover) .card-icon {
          color: white;
        }

        .selection-card-outer:focus-visible {
          border-radius: 10px;
          outline: 2px solid var(--d2l-color-celestine);
          outline-offset: 4px;
        }

        .icon-row {
          align-items: top;
          display: flex;
          justify-content: space-between;
          padding: 19px 22px 0 25px;
        }

        .card-selection-indicator-container {
          align-items: center;
          background-color: var(--d2l-color-celestine);
          border-radius: 50%;
          display: flex;
          height: 24px;
          justify-content: center;
          width: 24px;
        }
        .card-selection-indicator {
          color: white;
          height: 16px;
          width: 16px;
        }

        .content {
          padding: 0 22px;
        }
      `,
    ];
  }

  get cardIcon() {
    if (!this.icon) {
      return nothing;
    }

    return html`
      <div class="card-icon-container">
        <d2l-icon class="card-icon" icon="${this.icon}"></d2l-icon>
      </div>
    `;
  }

  get selectionIndicator() {
    if (!this.selected) {
      return nothing;
    }

    return html`
      <div class="card-selection-indicator-container">
        <d2l-icon class="card-selection-indicator" icon="tier1:check"></d2l-icon>
      </div>
    `;
  }

  _handleKeyDown(e) {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault();
      this.handleSelection();
    }
  }

  render() {
    return html`
      <div
        class="selection-card-outer"
        tabIndex="0" role="button"
        @click=${this.handleSelection}
        @keydown=${this._handleKeyDown}
        aria-label=${this.title}
      >
        <div class="selection-card" >
          <div class="icon-row">
            ${this.cardIcon}
            ${this.selectionIndicator}
          </div>
          <div class="content">
            <h3 class="skillswave-heading-3">${this.title}</h3>
            <p class="skillswave-paragraph-subtle">${this.description}</p>
          </div>
        </div>
      </div>
    `;
  }
}

window.customElements.define('nova-selection-card', NovaSelectionCard);

