import '@app/settings/components/settings-invite-user/settings-invite-user.js';

import { css, html, LitElement } from 'lit';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';

import { skillswaveGradientTextStyles, skillswaveHeading2Styles, skillswaveParagraphSubtleStyles } from '@/app/shared/styles/skillswave-text-styles.js';
import { LocalizeNova } from '@/app/shared/mixins/localize-nova/localize-nova.js';
import { NovaFlowStepMixin } from '@/app/shared/mixins/nova-flow-step-mixin/nova-flow-step-mixin.js';

class ClientOnboardingAddUsers extends RequesterMixin(NovaFlowStepMixin(LocalizeNova(LitElement))) {

  static get properties() {
    return {
      emailList: { type: Array },
    };
  }

  static get styles() {
    return [
      skillswaveHeading2Styles,
      skillswaveGradientTextStyles,
      skillswaveParagraphSubtleStyles,
      css`
        :host {
          display: block;
        }
      `,
    ];
  }

  constructor() {
    super();
    this.emailList = [];
  }

  firstUpdated() {
    if (this._existingData?.emailList) {
      this._updateData(this._existingData.emailList);
    }
  }

  goNext() {
    super.goNext();

    const inviteUserComponent = this.shadowRoot.querySelector('#settings-invite-user');
    inviteUserComponent.submitUsers();
  }

  _emailListChanged(e) {
    this._updateData(e.detail.emails);
  }

  _updateData(emailList) {
    this.emailList = emailList;

    this.data = {
      emailList: emailList,
    };
  }

  render() {
    return html`
      <h2 class="skillswave-heading-2 skillswave-gradient-text">${this.localize('view-client-onboarding.add-users.title')}</h2>
      <div class="skillswave-paragraph-subtle">
        ${this.localize('view-client-onboarding.add-users.description', { tooltip: this.l10nTags.tooltip(this.localize('view-client-onboarding.add-users.learner-tooltip')) })}
      </div>
      <settings-invite-user 
        id="settings-invite-user"
        hide-header
        hide-buttons
        .emailList="${this.emailList}"
        @emails-changed="${this._emailListChanged}">
      </settings-invite-user>
      <d2l-button @click=${this.goNext} primary>${this.localize('general.button-text.next')}</d2l-button>
    `;
  }
}

window.customElements.define('client-onboarding-add-users', ClientOnboardingAddUsers);
