import '@brightspace-ui/core/components/button/button.js';
import '@brightspace-ui/core/components/inputs/input-checkbox.js';
import { tableStyles } from '@brightspace-ui/core/components/table/table-wrapper.js';

import { css, html, LitElement } from 'lit';
import { heading1Styles, heading2Styles, heading3Styles } from '@brightspace-ui/core/components/typography/styles.js';
import { navigator as nav } from 'lit-element-router';
import { repeat } from 'lit/directives/repeat.js';
import { selectStyles } from '@brightspace-ui/core/components/inputs/input-select-styles.js';

import '../../../../shared/components/general/no-results/no-results.js';
import '../../../../shared/components/general/nova-pagination/nova-pagination.js';
import '../../../../shared/components/tenant-filter-search/tenant-filter-search.js';

import { FEATURES } from '../../../../../shared/constants.js';
import StepPaginationMixin from '../../../../shared/mixins/step-pagination-mixin/step-pagination-mixin.js';

export default class FeaturesGrid extends StepPaginationMixin(((nav(LitElement)))) {
  static get styles() {
    return [
      selectStyles,
      tableStyles,
      heading1Styles,
      heading2Styles,
      heading3Styles,
      css`
        .checkbox-col {
          text-align: center !important;
          vertical-align: middle !important;
        }
      `,
    ];
  }

  constructor() {
    super();
    this._updatedFeatures = {};
  }

  render() {
    return html`
      <h1 class="d2l-heading-1">${this.localize('view-admin.features')}</h1>
      <tenant-filter-search @changed=${this.handleFilterSearch} ?disabled=${this._isSearching} type=${this.type}></tenant-filter-search>
      ${this._gridTemplate(this._tenants)}
      <d2l-button ?disabled=${!this.canUpdate} primary @click=${this._saveFeatures}>Save</d2l-button>
    `;
  }

  _allFeaturesChanged(tenants, feature) {
    return e => {
      for (const tenant of tenants) {
        this._checkFeature(tenant, feature, e.target.checked);
      }
      this.update();
    };
  }

  _checkFeature(tenant, feature, value) {
    tenant.features[feature] = value;
    this._updatedFeatures[tenant.id] = tenant.features;
  }

  _featureCheckboxChanged(tenant, feature) {
    return e => {
      tenant.features[feature] = e.target.checked;
      this._updatedFeatures[tenant.id] = tenant.features;
    };
  }

  _gridTemplate(tenants) {
    if (!tenants || tenants.length === 0) return html`<no-results></no-results>`;
    const featureKeys = Object.keys(FEATURES);
    return html`
      <d2l-table-wrapper sticky-headers type="light">
        <table class="d2l-table">
          <thead>
          <tr>
            <th><d2l-table-col-sort-button>Tenant</d2l-table-col-sort-button></th>
            ${repeat(featureKeys, key => key, key => html`
              <th class="checkbox-col">${FEATURES[key]}</th>
            `)}
          </tr>
          <tr>
            <th></th>
            ${repeat(featureKeys, key => key, key => html`
              <th class="checkbox-col">
                <d2l-input-checkbox
                  id="${key}"
                  class="all-checkbox"
                  @change=${this._allFeaturesChanged(tenants, key)}
                  .checked=${this._isAllChecked(tenants, key)}>
                </d2l-input-checkbox>
              </th>
            `)}
          </tr>
          </thead>
          <tbody>
            ${repeat(tenants, tenant => tenant.id, tenant => html`
              <tr>
                <td>${tenant.name}</td>
                ${repeat(featureKeys, key => key, key => html`
                  <td class="checkbox-col">
                    <d2l-input-checkbox
                      id="${tenant.id}_${key}"
                      class="tenant-feature-checkbox"
                      @change=${this._featureCheckboxChanged(tenant, key)}
                      .checked=${tenant.features?.[key]}>
                    </d2l-input-checkbox>
                  </td>
                  `)}
              </tr>
            `)}
          </tbody>
        </table>
      </d2l-table-wrapper>

      <nova-pagination
        ?disableLast=${!this._lastGoodToken || this._pagesFound === this._pageNumber}
        ?isSearching=${this._isSearching}
        lastPage=${this._lastPage}
        pageNumber=${this._pageNumber}
        @page-changed=${this._pageChanged}>
      </nova-pagination>
    `;
  }

  _isAllChecked(tenants, key) {
    return tenants.every(t => t.features?.[key]);
  }

  async _saveFeatures() {
    try {
      await this.client.saveFeatures(this._updatedFeatures);
      this.session.toast({ type: 'default', message: 'Successfully saved the features' });
    } catch (err) {
      this.session.toast({ type: 'critical', message: 'There was an error saving the features' });
    }
  }
}

window.customElements.define('features-grid', FeaturesGrid);
