import { NovaSchema, SchemaAttribute } from './nova-model-schema.js';

export const costSchemaConfig = {
  id: new SchemaAttribute(),
  cost: new SchemaAttribute(),
  hundredOfCents: new SchemaAttribute(0),
  currency: new SchemaAttribute(),
  originalCurrency: new SchemaAttribute(),
  conversionRate: new SchemaAttribute(1), // cost in current currency * conversionRate = cost in original currency
};

class CostModelSchema extends NovaSchema {

  constructor() {
    super('cost', costSchemaConfig);
  }
}

export const CostSchema = new CostModelSchema();
