import { SkeletonMixin } from '@brightspace-ui/core/components/skeleton/skeleton-mixin.js';

import { css, html, LitElement } from 'lit';
import { classMap } from 'lit/directives/class-map.js';

import { skillswaveBodySmallStyles } from '../../../../../shared/styles/skillswave-text-styles.js';

import { LocalizeNova } from '../../../../../shared/mixins/localize-nova/localize-nova.js';
import { NovaFlowProgressBar } from '../../../../../shared/mixins/nova-flow-progress-bar-mixin/nova-flow-progress-bar-mixin.js';

class ClientOnboardingProgressBar extends NovaFlowProgressBar(LocalizeNova(SkeletonMixin(LitElement))) {

  static get styles() {
    return [
      skillswaveBodySmallStyles,
      css`
        :host {
          display: block;
        }

        .progress-bar-container {
          display: flex;
          flex-direction: row;
          gap: 6px;
          justify-content: space-between;
          margin-bottom: 60px;
        }

        .progress-bar-step {
          display: flex;
          flex: 1;
          flex-direction: column;
          gap: 4px;
        }

        .progress-bar-step-bar {
          background-color: var(--d2l-color-mica);
          border-bottom-left-radius: 10px;
          border-top-right-radius: 10px;
          height: 8px;
        }

        .progress-bar-step-bar.active {
          background-color: var(--d2l-color-celestine);
        }

        .progress-bar-step-title {
          display: flex;
          flex-direction: row;
          justify-content: center;
          text-align: center;
        }

        .skillswave-body-small {
          color: var(--d2l-color-galena);
        }
        .skillswave-body-small.active {
          color: var(--d2l-color-ferrite);
          font-weight: 700 !important;
        }
        .skillswave-body-small.complete {
          color: var(--d2l-color-ferrite);
        }
      `,
    ];
  }

  _renderProgressBarStep(title, index) {
    const barClasses = {
      'progress-bar-step-bar': true,
      'active': index <= this.current,
    };

    const textClasses = {
      'skillswave-body-small': true,
      'active': index === this.current,
      'complete': index < this.current,
    };

    return html`
      <div class="progress-bar-step">
        <div class=${classMap(barClasses)}></div>
        <div class="progress-bar-step-title">
          <span class=${classMap(textClasses)}>${title}</span>
        </div>
      </div>
    `;
  }

  render() {
    return html`
      <div class="progress-bar-container">
        ${this.steps.map((step, index) => this._renderProgressBarStep(step.displayName, index))}
      </div>
      <slot></slot>
    `;
  }
}

window.customElements.define('client-onboarding-progress-bar', ClientOnboardingProgressBar);

