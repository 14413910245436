import '../../../shared/components/general/nova-side-panel/nova-side-panel.js';
import '../../../shared/components/general/nova-side-panel-item/nova-side-panel-item.js';
import '../../components/settings-finance/settings-finance.js';
import '../../components/settings-user-management/settings-user-management.js';

import { css, html, LitElement } from 'lit';
import { heading1Styles } from '@brightspace-ui/core/components/typography/styles.js';

import { navigator as nav } from 'lit-element-router';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';
import { SkeletonMixin } from '@brightspace-ui/core/components/skeleton/skeleton-mixin.js';

import { LocalizeNova } from '../../../shared/mixins/localize-nova/localize-nova.js';
import { NovaNavMixin } from '../../../shared/mixins/nova-nav/nova-nav.js';

export default class ViewSettings extends LocalizeNova(SkeletonMixin(RequesterMixin(nav(NovaNavMixin(LitElement))))) {
  static get properties() {
    return {
      params: { type: Object },
      query: { type: Object },
      _selectedPanel: { type: String },
      _subview: { type: String },
      _breadcrumbs: { type: Array },
    };
  }

  static get styles() {
    return [
      heading1Styles,
      css`
        :host {
          display: flex;
          flex: 1;
          flex-flow: column nowrap;
        }
      `,
    ];
  }

  constructor() {
    super();
    this.skeleton = true;

    this._breadcrumbs = [];
  }

  connectedCallback() {
    super.connectedCallback();
    this.session = this.requestInstance('d2l-nova-session');
    this.client = this.requestInstance('d2l-nova-client');
    this._selectedPanel = this.params?.panel || 'details';
    this._subview = this.params?.subview;
  }

  async firstUpdated() {
    this.skeleton = false;
  }

  updated(changedProperties) {
    if (changedProperties.has('params')) {
      this._selectedPanel = this.params?.panel || this._selectedPanel;
      this._subview = this.params.subview;

      this._breadcrumbs = this._subview ? [
        { path: `settings/${this._selectedPanel}` },
      ] : [];
    }
  }

  render() {
    if (!this.session?.tenant?.hasTag('plgTenant') || !this.session.user.hasPermission(['settings:update'])) this.navigateWithoutHistory('/403');

    return html`
      <nova-side-panel
        .skeleton="${this.skeleton}"
        .selectedPanel="${this._selectedPanel}"
        .breadcrumbs="${this._breadcrumbs}"
      >
        <nova-side-panel-item
          panel="user-management"
          title=${this.localize('view-settings.user-management')}
          label=${this.localize('view-settings.user-management')}
          icon="tier1:lock-locked"
          >
            <settings-user-management
              .view="${this._subview}"
              .query="${this.query}"
            ></settings-user-management>
        </nova-side-panel-item>
        <nova-side-panel-item
          panel="finance"
          label=${this.localize('view-settings.finance')}
          title=${this.localize('view-settings.finance')}
          icon="tier1:lor"
          >
            <settings-finance></settings-finance>
        </nova-side-panel-item>
        <nova-side-panel-item
          panel="catalog-preferences"
          label=${this.localize('view-settings.catalog-preferences')}
          title=${this.localize('view-settings.catalog-preferences')}
          icon="tier1:checklist"
          >
        </nova-side-panel-item>
      </nova-side-panel>
    `;
  }
}

window.customElements.define('view-settings', ViewSettings);
