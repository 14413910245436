import { NovaSchema, SchemaAttribute } from './nova-model-schema.js';
import { costSchemaConfig } from './cost.js';
import { LocalizedValue } from '../localized-value.js';

class LocalizedCostModelSchema extends NovaSchema {

  constructor() {
    const config = {
      ...costSchemaConfig,
      displayName: new SchemaAttribute().setType(LocalizedValue),
    };
    super('localizedCost', config);
  }
}

export const LocalizedCostSchema = new LocalizedCostModelSchema();
