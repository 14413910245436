import '@brightspace-ui/core/components/button/button.js';

import { css, html, LitElement, nothing } from 'lit';
import { LocalizeNova } from '../../../../shared/mixins/localize-nova/localize-nova.js';

class NovaFlow extends LocalizeNova(LitElement) {

  static get properties() {
    return {
      name: { type: String },
      hideNavigationButtons: { type: Boolean, default: false },
      hideProgressBar: { type: Boolean, default: false },
      _steps: { type: Array },
      _currentStep: { type: Number },
    };
  }

  static get styles() {
    return [
      css`
        :host {
          display: block;
          height: 100%;
          width: 100%;
        }

        .slotted-content {
          height: 100%;
          width: 100%;
        }
      `,
    ];
  }

  constructor() {
    super();
    this.name = '';
    this.hideNavigationButtons = false;
    this.hideProgressBar = false;
    this._steps = [];
    this._currentStep = 0;
    this.template = undefined;
    this.styles = undefined;
  }

  get _stepElements() {
    return Array.from(this.children);
  }

  get _storageKey() {
    return `flow-${this.name}`;
  }

  connectedCallback() {
    super.connectedCallback();

    this.addEventListener('nova-flow-step-next-enabled', this._stepReady);
    this.addEventListener('nova-flow-step-next', this._goNext);
    this.addEventListener('nova-flow-step-back', this._goBack);
    this.addEventListener('nova-flow-step-finish', this._finish);
    this.addEventListener('nova-flow-step-updated', this._updateStep);
    window.addEventListener('popstate', this._handlePopState.bind(this));

    this._steps = this._stepElements.map(child => ({
      displayName: child.step.displayName,
      name: child.step.name,
      skippable: child.skippable,
      blockBack: child.blockBack,
      nextEnabled: child.nextEnabled,
    }));

    const urlParams = new URLSearchParams(window.location.search);
    const step = urlParams.get('step');
    if (step) {
      const stepIndex = this._steps.findIndex(s => s.name === step);
      if (stepIndex !== -1) {
        this._currentStep = stepIndex;
      }
    } else {
      this._currentStep = 0;
      urlParams.set('step', this._steps[this._currentStep].name);
      window.history.replaceState({}, '', `${window.location.pathname}?${urlParams}`);
    }

    const existingData = localStorage.getItem(this._storageKey);
    if (existingData) {
      this._setupFromExternalData(existingData);
    } else {
      localStorage.setItem(this._storageKey, JSON.stringify({}));
    }
  }

  disconnectedCallback() {
    this.removeEventListener('nova-flow-step-updated', this._updateStep);
    this.removeEventListener('nova-flow-step-next-enabled', this._stepReady);
    this.removeEventListener('nova-flow-step-next', this._goNext);
    this.removeEventListener('nova-flow-step-back', this._goBack);
    this.removeEventListener('nova-flow-step-finish', this._finish);
    window.removeEventListener('popstate', this._handlePopState.bind(this));
    super.disconnectedCallback();
  }

  updated(changedProperties) {
    if (changedProperties.has('_currentStep')) {
      const novaFlowData = this.data;

      this._stepElements.forEach(step => {
        step.novaFlowData = novaFlowData;
      });

      this.dispatchEvent(new CustomEvent('nova-flow-step-changed', {
        detail: {
          step: this._currentStep,
          config: this._steps[this._currentStep],
        },
        bubbles: true,
        composed: true,
      }));
    }

    if (changedProperties.has('_steps')) {
      this.dispatchEvent(new CustomEvent('nova-flow-steps-changed', {
        detail: {
          steps: this._steps,
        },
        bubbles: true,
        composed: true,
      }));
    }
  }

  get data() {
    return this._stepElements.reduce((acc, step) => {
      if (step.data && !step.shouldSkip(acc)) {
        acc[step.step.name] = step.data;
      }

      return acc;
    }, {});
  }

  _updateStep(e) {
    const { step } = e.detail;
    this._steps = this._steps.map(existingStep => {
      if (existingStep.name === step.name) {
        return {
          ...existingStep,
          ...step,
        };
      }
      return existingStep;
    });
  }

  _setupFromExternalData(existingData) {
    const data = JSON.parse(existingData);
    for (const key in data) {
      const step = this._stepElements.find(s => s.step === key);
      if (step) {
        step.data = data[key];
      }
    }
  }

  _stepReady(e) {
    this._steps = this._steps.map(step => {
      if (step.name === e.detail.step.name) {
        return {
          ...step,
          nextEnabled: e.detail.nextEnabled || step.skippable,
        };
      }
      return step;
    });
  }

  _goBack() {
    this._currentStep = Math.max(0, this._currentStep - 1);
    this._updateURL();
    if (this._stepElements[this._currentStep].shouldSkip(this.data)) {
      this._goBack();
    }
  }

  _goNext() {
    this._currentStep = Math.min(this._steps.length - 1, this._currentStep + 1);
    this._updateURL();
    if (this._stepElements[this._currentStep].shouldSkip(this.data)) {
      this._goNext();
    }
  }

  _finish() {
    this.dispatchEvent(new CustomEvent('completed'));
    localStorage.removeItem(this._storageKey);
  }

  _updateURL() {
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set('step', this._steps[this._currentStep].name);
    window.history.pushState({}, '', `${window.location.pathname}?${urlParams.toString()}`);
  }

  _handlePopState() {
    const step = new URLSearchParams(window.location.search).get('step');
    if (step) {
      const stepIndex = this._steps.findIndex(s => s.name === step);
      if (stepIndex !== -1) {
        this._currentStep = stepIndex;
      }
    }
  }

  get progressBar() {
    if (this.hideProgressBar) {
      return nothing;
    }

    return html`
      <div class="progress-bar">
        progress bar: ${this._currentStep + 1} / ${this._steps.length}
      </div>
    `;
  }

  get navigationButtons() {
    if (this.hideNavigationButtons) {
      return nothing;
    }

    return html`
      <div class="navigation-buttons">
        ${this._currentStep > 0 && !this._steps[this._currentStep].blockBack ? html`
          <d2l-button class="nova-flow-previous" @click=${this._goBack}>${this.localize('general.button-text.previous')}</d2l-button>
        ` : nothing}
        ${this._currentStep < this._steps.length - 1 ? html`
          <d2l-button class="nova-flow-next" primary ?disabled=${!this._steps[this._currentStep].nextEnabled} @click=${this._goNext}>${this.localize('general.button-text.next')}</d2l-button>
        ` : nothing}
        ${this._currentStep === this._steps.length - 1 ? html`
          <d2l-button class="nova-flow-finish" primary ?disabled=${!this._steps[this._currentStep].nextEnabled} @click=${this._finish}>${this.localize('general.button-text.finish')}</d2l-button>
        ` : nothing}
      </div>
    `;
  }

  get slot() {
    if (this._steps.length) {
      return html`<slot name="${this._steps[this._currentStep].name}"></slot>`;
    }
    return nothing;
  }

  render() {
    return html`
      ${this.progressBar}
      <div class="slotted-content">
        ${this.slot}
      </div>
      ${this.navigationButtons}
    `;
  }

}

window.customElements.define('nova-flow', NovaFlow);
