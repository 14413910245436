import '@brightspace-ui/core/components/tooltip/tooltip-help.js';

import { html } from 'lit';

import { Localizer } from '../../../../shared/l10n/localizers/localizer.js';
import { novaLocalize } from '../../../../shared/l10n/localize.js';

export const LocalizeNova = superclass => class extends superclass {

  static get properties() {
    return {
      __resourcesLoaded: { type: Object, attribute: false },
    };
  }

  constructor() {
    super();
    this.__resourcesLoaded = false;
    this.__resourcesLoadedPromise = new Promise(resolve => {
      this.__localizer = new Localizer();
      Localizer.__resourcesLoadedPromise.then(() => {
        this.__resourcesLoaded = true;
        resolve();
      });
    });
    this.__updatedProperties = new Map();
  }

  get l10nTags() {
    return {
      bold() {
        return (...chunks) => html`<b>${chunks}</b>`;
      },

      empty() {
        return (...chunks) => `${chunks}`;
      },

      link(href) {
        return (...chunks) => html`<a href="${href}" target="_blank" rel="noopener">${chunks}</a>`;
      },

      // Can return HTML templates or just a string
      d2lLink(href, returnHtml = true) {
        return (...chunks) => {
          const outputFunction = returnHtml ? html : String.raw;
          return outputFunction`<a class="d2l-link" href="${href}" rel="noopener">${chunks}</a>`;
        };
      },

      d2lOnClick(handler, returnHtml = true) {
        return (...chunks) => {
          const outputFunction = returnHtml ? html : String.raw;
          return outputFunction`<span class="d2l-link" @click="${handler}">${chunks}</span>`;
        };
      },

      d2lAppLink(href, returnHtml = true) {
        return (...chunks) => {
          const outputFunction = returnHtml ? html : String.raw;
          return outputFunction`<app-link class="d2l-link" href="${href}">${chunks}</app-link>`;
        };
      },

      noBreak() {
        return (...chunks) => html`<span style="white-space: nowrap">${chunks}</span>`;
      },

      strong() {
        return (...chunks) => html`<strong>${chunks}</strong>`;
      },

      tooltip(tooltipText) {
        const tooltipStyle = `
          color: white;
          font-family: 'Poppins', sans-serif;
          font-size: 0.7rem;
          font-weight: 400;
          line-height: 150%;
        `;
        return (...chunks) => html`
          <d2l-tooltip-help 
            inherit-font-style 
            text="${chunks.join(' ')}">
            <div style=${tooltipStyle}>
              ${tooltipText}
            </div>
          </d2l-tooltip-help>
        `;
      },

    };
  }

  shouldUpdate(changedProperties) {
    if (!this.__resourcesLoaded) {
      changedProperties.forEach((oldValue, propName) => {
        this.__updatedProperties.set(propName, oldValue);
      });
      return false;
    }

    this.__updatedProperties.forEach((oldValue, propName) => {
      if (!changedProperties.has(propName)) {
        changedProperties.set(propName, oldValue);
      }
    });
    this.__updatedProperties.clear();
    return super.shouldUpdate(changedProperties);
  }

  async getUpdateComplete() {
    await super.getUpdateComplete();
    const resourcesLoaded = Localizer.resources !== undefined;
    if (resourcesLoaded) {
      return;
    }
    await this.__resourcesLoadedPromise;
  }

  localize(key, params) {
    return novaLocalize(key, params);
  }

  localizeError(code) {
    return novaLocalize(`error.${code}`) || novaLocalize('app-nova.catchAllErrorMessage');
  }

};
