import '@brightspace-ui-labs/autocomplete/autocomplete.js';
import '@brightspace-ui/core/components/inputs/input-search.js';
import '@brightspace-ui/core/components/tag-list/tag-list.js';
import '@brightspace-ui/core/components/tag-list/tag-list-item.js';
import '@brightspace-ui/core/components/button/button.js';

import { css, html, LitElement, nothing } from 'lit';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';
import { selectStyles } from '@brightspace-ui/core/components/inputs/input-select-styles.js';

import { skillswaveGradientTextStyles, skillswaveHeading2Styles, skillswaveLabelStyles, skillswaveParagraphSubtleStyles } from '@/app/shared/styles/skillswave-text-styles.js';
import { LocalizeNova } from '@/app/shared/mixins/localize-nova/localize-nova.js';
import { NovaFlowStepMixin } from '@/app/shared/mixins/nova-flow-step-mixin/nova-flow-step-mixin.js';

class ClientOnboardingDepartment extends RequesterMixin(NovaFlowStepMixin(LocalizeNova(LitElement))) {

  static get properties() {
    return {
      lightcastCompanyId: { type: String, attribute: 'lightcast-company-id' },
      _selectedDepartments: { type: Array },
      _departmentList: { type: Array },
    };
  }

  static get styles() {
    return [
      selectStyles,
      skillswaveHeading2Styles,
      skillswaveGradientTextStyles,
      skillswaveLabelStyles,
      skillswaveParagraphSubtleStyles,
      css`
        :host {
          display: block;
        }

        h2 {
          margin-bottom: 16px;
        }

        p {
          margin-bottom: 24px;
        }

        d2l-labs-autocomplete {
          display: block;
          width: 100%;
        }

        d2l-tag-list-item {
          margin-bottom: 6px;
          margin-right: 6px;
        }

        .skillswave-heading-2 {
          margin-bottom: 16px;
          -webkit-text-fill-color: transparent;
        }

        .search-container {
          margin-bottom: 24px;
          max-width: 550px;
        }

        .autocomplete-search {
          display: block;
          width: 550px;
        }

        .selected-departments {
          margin-bottom: 60px;
        }
      `,
    ];
  }

  constructor() {
    super();

    this._departmentList = [];
    this._selectedDepartments = [];
  }

  async connectedCallback() {
    super.connectedCallback();

    this.client = this.requestInstance('d2l-nova-client');
  }

  firstUpdated() {
    super.firstUpdated();
    if (this._existingData?.departments) {
      this._updateData(this._existingData.departments);
    }
  }

  async updated(changedProperties) {
    super.updated(changedProperties);

    if (changedProperties.has('_selectedDepartments')) {
      this._updateData();
    }

    if (changedProperties.has('lightcastCompanyId')) {
      if (this.lightcastCompanyId) {
        const { lotOccupationGroups } = await this.client.lightcastLotOccupationGroupNames(this.lightcastCompanyId);
        if (lotOccupationGroups?.length) {
          this._departmentList = lotOccupationGroups.map(department => department.name);
          this.requestUpdate();
        }
      }

      this.nextEnabled = this.nextEnabled || this._departmentList.length === 0;
    }
  }

  _updateData(selectedDepartments) {
    if (selectedDepartments) {
      this._selectedDepartments = selectedDepartments;
    }

    this.nextEnabled = this._selectedDepartments.length > 0 || this._departmentList.length === 0;
    this.data = {
      departments: this._selectedDepartments,
    };
  }

  _onSelection(e) {
    if (e.target.value) {
      this._selectedDepartment = e.target.value;
    }
  }

  _filterFn(value, filter) {
    return value.toLowerCase().indexOf(filter.toLowerCase()) >= 0;
  }

  async _autoCompleteFilterChange(e) {
    const filterValue = e.detail.value?.trim();
    const autoComplete = this.shadowRoot.getElementById('autocomplete');

    if (this._departmentList.length > 0 && filterValue) {
      const selectedDepartments = new Set(this._selectedDepartments);
      const matching = this._departmentList.filter(department => !selectedDepartments.has(department) && this._filterFn(department, filterValue));
      autoComplete.setSuggestions(matching.map(department => ({ value: department })));
    } else {
      autoComplete.setSuggestions([]);
    }
  }

  async _autoCompleteSuggestionSelected(e) {
    this._updateData([...this._selectedDepartments, e.detail.value]);

    const autoComplete = this.shadowRoot.getElementById('autocomplete');
    autoComplete.setSuggestions([]);

    const search = this.shadowRoot.getElementById('search');
    search._handleClearClick();
  }

  _stopPropagation(e) {
    const [HOME, END] = [36, 35];
    const { keyCode } = e;
    if (keyCode === HOME || keyCode === END) {
      e.stopPropagation();
    }
  }

  _removeDepartment(e) {
    this._updateData(this._selectedDepartments.filter(department => department !== e.detail.key));
  }

  render() {
    return html`
      <div>
        <h2 class="skillswave-heading-2 skillswave-gradient-text">${this.localize('view-client-onboarding.client-onboarding-department.title')}</h2>
        <p class="skillswave-paragraph-subtle">${this.localize('view-client-onboarding.client-onboarding-department.description')}</p>
        <div class="search-container">
          <d2l-labs-autocomplete
            id="autocomplete"
            role="search"
            show-on-focus
            .filterFn=${this._filterFn}
            remote-source
            @d2l-labs-autocomplete-filter-change=${this._autoCompleteFilterChange}
            @d2l-labs-autocomplete-suggestion-selected=${this._autoCompleteSuggestionSelected}
            ?disabled=${this._selectedDepartments.length >= 3}
          >
            <d2l-input-search
              id="search"
              label="Search"
              class="autocomplete-search"
              @keydown=${this._stopPropagation}
              @d2l-input-search-searched=${this._autoCompleteFilterChange}
              placeholder="${this.localize('view-client-onboarding.client-onboarding-department.searchPlaceholder')}"
              slot="input"
              ?disabled=${this._selectedDepartments.length >= 3}
            ></d2l-input-search>
          </d2l-labs-autocomplete>
          <span class="skillswave-label">${this.localize('view-client-onboarding.client-onboarding-department.maximumDepartments', { maximum: 3 })}</span>
        </div>
        <div class="selected-departments">
          <d2l-tag-list
            clearable
            description="${this.localize('view-client-onboarding.client-onboarding-department.selectedDepartments')}"
            @d2l-tag-list-item-clear=${this._removeDepartment}
          >
            ${this._selectedDepartments.map(department => html`<d2l-tag-list-item key="${department}" text="${department}"></span>`)}
          </d2l-tag-list>
        </div>
        <div>
          ${!this.blockBack
    ? html`<d2l-button class="nova-flow-previous" @click=${this.goBack}>${this.localize('general.button-text.previous')}</d2l-button>`
    : nothing }
          <d2l-button class="nova-flow-next" primary ?disabled=${!this.nextEnabled} @click=${this.goNext}>${this.localize('general.button-text.next')}</d2l-button>
        </div>
      </div>
    `;
  }

}

window.customElements.define('client-onboarding-department', ClientOnboardingDepartment);
