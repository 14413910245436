import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';

import { LocalizeNova } from '../../../shared/mixins/localize-nova/localize-nova.js';
import { NovaPermissionMixin } from '../../../shared/mixins/nova-permission-mixin/nova-permission-mixin.js';

const PAGE_SIZE = 20;

export default superclass => class StepPaginationMixin extends NovaPermissionMixin(LocalizeNova(RequesterMixin(superclass))) {
  static get properties() {
    return {
      _isSearching: { type: Boolean },
      _search: { type: String },
      _tenants: { type: Array },
      type: { type: String },
    };
  }

  constructor() {
    super();
    this._filterTags = [];
    this._isSearching = false;
    this._pageNumber = 1;
    this._tenants = [];
  }

  connectedCallback() {
    super.connectedCallback();
    this.session = this.requestInstance('d2l-nova-session');
    this.client = this.requestInstance('d2l-nova-client');
  }

  async firstUpdated() {
    this._isSearching = true;
    await this.fetchTenants({ asc: true, pageSize: PAGE_SIZE });
    this._isSearching = false;
  }

  async fetchTenants(filters) {
    const { asc, nextToken } = filters;
    const oldTenantsList = this._tenants;
    const data = await this.client.listTenants(this.type, filters);
    this._tenants = data.items ?? data;

    if (!data.items?.length) {
      if (!data.nextToken) {
        // no results, set page to last valid page and ignore dud nextToken
        if (this._pageNumber > 1) {
          this._tenants = oldTenantsList;
          this._pageNumber--;
        }
        this._lastPage = asc ? this._pageNumber : this._lastPage;
      } else {
        // AWS DDB 1MB limit reached with no results yet, try again
        this.fetchTenants({ ...filters, nextToken: data.nextToken });
      }
    } else if (!data.nextToken) {
      this._lastPage = asc ? this._pageNumber : this._lastPage;
      this._lastGoodToken = asc ? nextToken : this._lastGoodToken;
    } else {
      this._lastGoodToken = (!this._lastPage && this._pageNumber > this._pagesFound) ? nextToken : this._lastGoodToken;
    }

    this._pagesFound = Math.max(this._pageNumber, this._pagesFound ?? 1);
    this._nextToken = data.nextToken;
  }

  async _searchTenants(asc = true) {
    this._isSearching = true;
    const filters = { asc, nextToken: this._nextToken, pageSize: PAGE_SIZE, search: this._search, tags: this._filterTags };

    try {
      await this.fetchTenants(filters);
    } catch (e) {
      console.error('Error fetching tenants', e);
      this.session.toast({ type: 'critical', message: 'An error occured, try again' });
    } finally {
      this._isSearching = false;
    }
  }

  _resetPagination() {
    this._pageNumber = 1;
    this._nextToken = undefined;
    this._lastGoodToken = undefined;
    this._lastPage = undefined;
    this._forwardCursor = undefined;
  }

  async handleFilterSearch({ detail }) {
    const { filterTags, search } = detail;
    this._filterTags = filterTags;
    this._search = search;
    this._resetPagination();
    await this._searchTenants();
  }

  async _pageChanged(e) {
    const buttonId = e.detail.action;
    const isAscending = ['next', 'last'].includes(buttonId);

    const getCursor = tenant => {
      const cursor = { h: 'tenants', r: tenant.id };
      if (!this._search) {
        cursor['g2h'] = 'tenants';
        cursor['g2r'] = tenant.name.toLowerCase();
      }
      return cursor;
    };

    switch (buttonId) {
      case 'first':
        this._pageNumber = 1;
        this._nextToken = undefined;
        break;
      case 'previous':
        this._pageNumber--;
        this._nextToken = getCursor(this._tenants[0]);
        break;
      case 'next':
        this._pageNumber++;
        this._nextToken = this._forwardCursor ?? this._nextToken;
        this._forwardCursor = undefined;
        break;
      case 'last':
        this._pageNumber = this._lastPage ?? this._pagesFound;
        this._nextToken = this._lastGoodToken;
        this._forwardCursor = undefined;
        break;
    }

    await this._searchTenants(isAscending);

    if (!isAscending) {
      this._forwardCursor = getCursor(this._tenants[this._tenants.length - 1]);
    }

    this.requestUpdate();
  }
};
