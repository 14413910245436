import { css, html, LitElement, nothing } from 'lit';
import { repeat } from 'lit/directives/repeat.js';
import { SkeletonMixin } from '@brightspace-ui/core/components/skeleton/skeleton-mixin.js';

import '@brightspace-ui/core/components/filter/filter.js';
import '@brightspace-ui/core/components/filter/filter-dimension-set.js';
import '@brightspace-ui/core/components/filter/filter-dimension-set-value.js';
import '@brightspace-ui/core/components/inputs/input-search.js';
import { LocalizeNova } from '../../mixins/localize-nova/localize-nova.js';

class TenantFilterSearch extends LocalizeNova(SkeletonMixin(LitElement)) {
  static get properties() {
    return {
      disabled: { type: Boolean },
      type: { type: String },
    };
  }

  static get styles() {
    return css`
      .filter-container {
        display: flex;
        justify-content: space-between;
        margin: 1rem 0 1.5rem;
        > :first-child {
          margin-right: 2rem;
        }
      }
    `;
  }

  constructor() {
    super();
    this.disabled = false;
    this._filterTags = new Set();
  }

  async handleChange(e) {
    const { detail, target } = e;
    const search = detail?.value || target?.value;

    if (detail?.dimensions) {
      detail.dimensions.forEach(dimension => {
        dimension.changes.forEach(change => {
          if (change.selected) {
            this._filterTags.add(change.valueKey);
          } else {
            this._filterTags.delete(change.valueKey);
          }
        });
      });
    }

    if (search !== this._search || detail?.dimensions) {
      this._search = detail?.dimensions ? this._search : search;

      this.dispatchEvent(new CustomEvent('changed', {
        detail: {
          search: this._search,
          filterTags: [...this._filterTags],
        },
      }));
    }
  }

  get _renderFilterDimensionSetValue() {
    const values = {
      employer: [
        { key: 'plgTenant', text: 'Self-Serve Client' },
        { key: 'not-plgTenant', text: 'Enterprise Client' },
        { key: 'isCanadianBusiness', text: 'Canadian Client' },
        { key: 'not-isCanadianBusiness', text: 'Non-Canadian Client' },
        { key: 'careerExplorer', text: 'Career Explorer In Use' },
        { key: 'testData', text: 'Test Client' },
      ],
      provider: [
        { key: 'isCanadianBusiness', text: 'Canadian Provider' },
        { key: 'not-isCanadianBusiness', text: 'Non-Canadian Provider' },
        { key: 'testData', text: 'Test Provider' },
      ],
    };

    return repeat(values[this.type], value => value.key, ({ key, text }) => html`
      <d2l-filter-dimension-set-value ?disabled=${this.disabled} key=${key} text=${text}></d2l-filter-dimension-set-value>
    `);
  }

  render() {
    return this.type === 'admin' ? nothing : html`
      <div class="filter-container">
        <d2l-filter ?disabled=${this.disabled} @d2l-filter-change=${this.handleChange}>
          <d2l-filter-dimension-set key="tenant-tags" text="Filter tenants" search-type="none" selection-single>
            ${this._renderFilterDimensionSetValue}
          </d2l-filter-dimension-set>
        </d2l-filter>

        <d2l-input-search ?disabled=${this.disabled} @blur=${this.handleChange} @d2l-input-search-searched=${this.handleChange} label="Search">
        </d2l-input-search>
      </div>
    `;
  }
}

window.customElements.define('tenant-filter-search', TenantFilterSearch);
