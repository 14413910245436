import '@brightspace-ui/core/components/button/button.js';
import '@brightspace-ui/core/components/button/button-icon.js';
import '@brightspace-ui/core/components/list/list.js';
import '@brightspace-ui/core/components/list/list-item.js';
import '@brightspace-ui/core/components/list/list-item-content.js';
import '@brightspace-ui/core/components/dialog/dialog.js';
import '@brightspace-ui/core/components/dialog/dialog-confirm.js';
import '@brightspace-ui/core/components/inputs/input-text.js';

import { css, html, LitElement } from 'lit';
import { inputLabelStyles } from '@brightspace-ui/core/components/inputs/input-label-styles.js';
import { navigator as nav } from 'lit-element-router';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';
import { selectStyles } from '@brightspace-ui/core/components/inputs/input-select-styles.js';

import { LocalizeNova } from '../../../shared/mixins/localize-nova/localize-nova.js';

export default class SettingsFinance extends LocalizeNova(RequesterMixin(nav(LitElement))) {

  static get properties() {
    return {
      _paymentMethods: { type: Array },
      _confirmDeleteOpened: { type: Boolean },
      _editDialogOpened: { type: Boolean },
      _selectedPaymentMethod: { type: Object },
    };
  }

  static get styles() {
    return [selectStyles, inputLabelStyles,
      css`
        :host {
          display: block;
        }
      `,
    ];
  }

  constructor() {
    super();
    this._paymentMethods = [];
    this._confirmDeleteOpened = false;
    this._editDialogOpened = false;
    this._selectedPaymentMethodId = null;
  }

  async connectedCallback() {
    super.connectedCallback();
    this.session = this.requestInstance('d2l-nova-session');
    this.client = this.requestInstance('d2l-nova-client');

    this._paymentMethods = await this.client.getPaymentMethods(this.session.tenant.id);

    const params = new URLSearchParams(window.location.search);
    if (params.get('addPaymentMethod') === 'confirm') {
      await this.client.confirmPaymentMethodAddition(this.session.tenant.id);
    }
  }

  async _addPaymentMethod() {
    const response = await this.client.getAddPaymentMethodSession(this.session.tenant.id);
    location.replace(response.sessionUrl);
  }

  _openEditDialog(e) {
    this._editDialogOpened = true;

    this._selectedPaymentMethod = this._paymentMethods.find(paymentMethod => paymentMethod.id === e.target.getAttribute('key'));
  }

  async _editPaymentMethod(e) {
    this._editDialogOpened = false;

    if (e.detail.action === 'save') {
      this._selectedPaymentMethod = {
        ...this._selectedPaymentMethod,
        expiry_month: parseInt(this.shadowRoot.getElementById('expiry-month').value),
        expiry_year: parseInt(this.shadowRoot.getElementById('expiry-year').value),
        name: this.shadowRoot.getElementById('cardholder-name').value,
        address: {
          line1: this.shadowRoot.getElementById('street').value,
          line2: this.shadowRoot.getElementById('street2').value,
          city: this.shadowRoot.getElementById('city').value,
          postal_code: this.shadowRoot.getElementById('postal').value,
          state: this.shadowRoot.getElementById('province').value,
          country: this.shadowRoot.getElementById('country').value,
        },
      };

      await this.client.editPaymentMethod(this.session.tenant.id, this._selectedPaymentMethod);

      this._paymentMethods = this._paymentMethods.map(paymentMethod => {
        if (paymentMethod.id === this._selectedPaymentMethod.id) {
          return this._selectedPaymentMethod;
        }
        return paymentMethod;
      });
    }

    this._selectedPaymentMethod = null;
  }

  _confirmDelete(e) {
    this._confirmDeleteOpened = true;

    this._selectedPaymentMethod = this._paymentMethods.find(paymentMethod => paymentMethod.id === e.target.getAttribute('key'));
  }

  async _deletePaymentMethod(e) {
    this._confirmDeleteOpened = false;

    if (e.detail.action === 'yes') {
      await this.client.deletePaymentMethod(this.session.tenant.id, this._selectedPaymentMethod.id);
      this._paymentMethods = this._paymentMethods.filter(paymentMethod => paymentMethod.id !== this._selectedPaymentMethod.id);
    }

    this._selectedPaymentMethod = null;
  }

  _renderPaymentMethods() {
    return html`
      <h2 class="d2l-heading-2">${this.localize('settings-finance.payment-methods')}</h2>
      <d2l-button primary @click=${this._addPaymentMethod}>${this.localize('settings-finance.add')}</d2l-button>
      <d2l-list>
        ${this._paymentMethods.map(paymentMethod => html`
          <d2l-list-item key="${paymentMethod.id}" label="${this.localize('settings-finance.card-ending', { lastFour: paymentMethod.last4Digits })}">
            <d2l-list-item-content>
              <div>${paymentMethod.brand}</div>
              <div>${this.localize('settings-finance.card-ending', { lastFour: paymentMethod.last4 })}</div>
              <div slot="supporting-info">${this.localize('settings-finance.card-expiry', { expiry_month: paymentMethod.expiry_month, expiry_year: paymentMethod.expiry_year })}</div>
            </d2l-list-item-content>
            <div slot="actions">
              <d2l-button-icon @click=${this._openEditDialog} key="${paymentMethod.id}" text="${this.localize('settings-finance.edit')}" icon="tier1:edit"></d2l-button-icon>
              <d2l-button-icon @click=${this._confirmDelete} key="${paymentMethod.id}" text="${this.localize('settings-finance.delete')}" icon="tier1:delete"></d2l-button-icon>
            </div>
          </d2l-list-item>
        `)}
      </d2l-list>
    `;
  }

  _renderEditDialog() {
    const year = new Date().getFullYear();

    return html`
      <d2l-dialog 
        id="dialog" 
        title-text="${this.localize('settings-finance.edit-dialog.title')}"
        ?opened=${this._editDialogOpened}
        @d2l-dialog-close=${this._editPaymentMethod}
      >
        <label for="expiry-month" class="d2l-input-label">${this.localize('settings-finance.edit-dialog.expiry-month')}</label>
        <select aria-label="${this.localize('settings-finance.edit-dialog.expiry-month')}" id="expiry-month" class="d2l-input-select">
          ${Array.from(Array(12)).map((_, i) => html`
            <option value="${i + 1}" ?selected=${i + 1 === this._selectedPaymentMethod?.expiry_month}>${i + 1}</option>
          `)}
        </select>
        <label for="expiry-year" class="d2l-input-label">${this.localize('settings-finance.edit-dialog.expiry-year')}</label>
        <select aria-label="${this.localize('settings-finance.edit-dialog.expiry-year')}" id="expiry-year" class="d2l-input-select">
          ${Array.from(Array(20)).map((_, i) => html`
            <option value="${year + i}" ?selected=${year + i === this._selectedPaymentMethod?.expiry_year}>${year + i}</option>
          `)}
        </select>
        <d2l-input-text id="cardholder-name" label="${this.localize('settings-finance.edit-dialog.cardholder-name')}" value="${this._selectedPaymentMethod?.name}"></d2l-input-text>
        <d2l-input-text id="street" label="${this.localize('settings-finance.edit-dialog.street')}" value="${this._selectedPaymentMethod?.address?.line1}"></d2l-input-text>
        <d2l-input-text id="street2" label="${this.localize('settings-finance.edit-dialog.street2')}" value="${this._selectedPaymentMethod?.address?.line2}"></d2l-input-text>
        <d2l-input-text id="city" label="${this.localize('settings-finance.edit-dialog.city')}" value="${this._selectedPaymentMethod?.address?.city}"></d2l-input-text>
        <d2l-input-text id="postal" label="${this.localize('settings-finance.edit-dialog.postal')}" value="${this._selectedPaymentMethod?.address?.postal_code}"></d2l-input-text>
        <d2l-input-text id="province" label="${this.localize('settings-finance.edit-dialog.province')}" value="${this._selectedPaymentMethod?.address?.state}"></d2l-input-text>
        <d2l-input-text id="country" label="${this.localize('settings-finance.edit-dialog.country')}" value="${this._selectedPaymentMethod?.address?.country}"></d2l-input-text>

        <d2l-button slot="footer" primary data-dialog-action="save">${this.localize('general.button-text.save')}</d2l-button>
        <d2l-button slot="footer" data-dialog-action>${this.localize('general.button-text.cancel')}</d2l-button>
      </d2l-dialog>
    `;
  }

  _renderDeleteConfirmation() {
    return html`
      <d2l-dialog-confirm 
        text="${this.localize('settings-finance.delete-dialog.message', { lastFour: this._selectedPaymentMethod?.last4 })}" 
        title-text="${this.localize('settings-finance.delete-dialog.title')}"
        @d2l-dialog-close=${this._deletePaymentMethod}
        ?opened=${this._confirmDeleteOpened}
      >
        <d2l-button slot="footer" primary data-dialog-action="yes">${this.localize('general.button-text.yes')}</d2l-button>
        <d2l-button slot="footer" data-dialog-action>${this.localize('general.button-text.no')}</d2l-button>
      </d2l-dialog-confirm>
    `;
  }

  render() {
    return html`
      ${this._renderPaymentMethods()}
      ${this._renderDeleteConfirmation()}
      ${this._renderEditDialog()}
    `;
  }

}

window.customElements.define('settings-finance', SettingsFinance);
