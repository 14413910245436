import '@brightspace-ui/core/components/empty-state/empty-state-action-button.js';
import '@brightspace-ui/core/components/empty-state/empty-state-illustrated.js';

import { html, LitElement, nothing } from 'lit';
import { navigator as nav } from 'lit-element-router';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';
import { RtlMixin } from '@brightspace-ui/core/mixins/rtl-mixin.js';
import { SkeletonMixin } from '@brightspace-ui/core/components/skeleton/skeleton-mixin.js';

import ActivityFilter from '../../../../../app/shared/models/activity-filter/activity-filter.js';
import LandingCarouselMixin from '../landing-carousel-mixin.js';
import { LocalizeNova } from '../../../../shared/mixins/localize-nova/localize-nova.js';

import '../../../../shared/components/general/nova-carousel/nova-carousel.js';

class SkillSetsCarousel extends LandingCarouselMixin(SkeletonMixin(RtlMixin(RequesterMixin(nav(LocalizeNova(LitElement)))))) {

  static get properties() {
    return {
      selectedSkillCategories: { type: Array }, // the selected skill sets
      skills: { type: Array }, // related skills from the selected skill sets
      heading: { type: String },
      empty: { type: Boolean, reflect: true }, // Reflected when the carousel is empty
      _data: { type: Object },
      _streams: { type: Array },
      _filter: { type: Object },
      _skillProfileSubCategories: { type: Array }, // all subcategories to be rendered in this carousel
      _activityCountsBySkillSet: { type: Object },
      _activityIdsToExclude: { type: Object },
    };
  }

  constructor() {
    super();
    this.selectedSkillCategories = [];
    this.skills = null;
    this.heading = '';
    this.skeleton = true;
    this.empty = false;
    this._data = null;
    this._skillProfileSubCategories = [];
    this._filter = null;
    this._streams = [];
  }

  async connectedCallback() {
    super.connectedCallback();
    this.client = this.requestInstance('d2l-nova-client');
    this.session = this.requestInstance('d2l-nova-session');
    this._setupIntersectionObserver();
  }

  async disconnectedCallback() {
    super.disconnectedCallback();
    if (this._observer) {
      this._observer.disconnect();
    }
  }

  async updated(changedProperties) {
    super.updated();
    if (!this.skeleton && (changedProperties.has('selectedSkillCategories') || changedProperties.has('skills') || changedProperties.has('careerTitle'))) {
      await this._setupComponent();
    }
    this.empty = !this.session?.user.getSetting('selectedTitleId') || (this.selectedSkillCategories.length === 0);
  }

  render() {
    if (this.empty) return nothing;
    return html`
      ${this._carouselTemplate}
    `;
  }

  get _carouselTemplate() {
    if (this.skeleton) {
      return html`
        <nova-carousel
          card-type="activity-card-wide"
          heading=${this.localize('general.loading')}
          max-cards-per-slide="3"
          skeleton>
        </nova-carousel>
      `;
    }

    if (!this._data !== null) {
      if (this._areAllStreamsRejected(this._streams)) {
        return this._emptyStateTemplate;
      }
      if (this._isFetchedDataEmpty(this._data)) {
        return nothing;
      }
    }

    let stylizedSkillCategories = this._skillProfileSubCategories.map(skillCategory => ({
      id: skillCategory.skillCategoryId,
      name: `${skillCategory.skillCategoryName}`,
      count: this._activityCountsBySkillSet.get(skillCategory.skillCategoryId),
    })).sort((a, b) => b.count - a.count);
    stylizedSkillCategories = stylizedSkillCategories.filter(skillCategory => skillCategory.count && skillCategory.count > 0);

    const { bestResults, microlearning, courses, shortCredentials, degrees } = this._data;
    const tabsContent = [
      {
        tabTitle: this.localize(
          'view-landing-page.carousel.tabTitle.bestResults'
        ),
        content: bestResults.activities,
        path: bestResults.path,
      },
      {
        tabTitle: this.localize(
          'view-landing-page.carousel.tabTitle.microlearning'
        ),
        content: microlearning.activities,
        path: microlearning.path,
      },
      {
        tabTitle: this.localize('view-landing-page.carousel.tabTitle.courses'),
        content: courses.activities,
        path: courses.path,
      },
      {
        tabTitle: this.localize(
          'view-landing-page.carousel.tabTitle.shortCredentials'
        ),
        content: shortCredentials.activities,
        path: shortCredentials.path,
      },
      {
        tabTitle: this.localize('view-landing-page.carousel.tabTitle.degrees'),
        content: degrees.activities,
        path: degrees.path,
      },
    ];

    const headingMenu = {
      id: 'skill-sets',
      displayText: stylizedSkillCategories[0].name,
      defaultItem: stylizedSkillCategories[0],
      label: this.localize('view-landing-page.carousel.interestedSkillSet.menuLabel'),
      items: stylizedSkillCategories,
    };

    // TODO:
    // - sort the activities by relevance/hype (https://desire2learn.atlassian.net/browse/NOVA-685)
    return html`
      <nova-carousel
        card-type="activity-card-wide"
        ?skeleton=${this.skeleton}
        heading=${this.heading}
        max-cards-per-slide="3"
        @menu-changed=${this._handleMenuChange}
        @view-all-clicked=${this._viewAllClicked}
        .tabsContent=${tabsContent}
        .headingMenu=${headingMenu}
      >
      </nova-carousel>
    `;
  }

  async _setupComponent() {
    this.skeleton = true;
    await this._setupSkillSets();
    await this._setupActivityCountBySkills();
    const skillsFilter = this._skillProfileSubCategories.flatMap(skillSubcategory => skillSubcategory.skills).map(skill => skill.id);
    await this._setupData(null, { skills: skillsFilter });
    this.skeleton = false;
  }

  async _setupActivityCountBySkills() {
    const activityCountsBySkillSet = new Map();
    await Promise.all(this._skillProfileSubCategories.map(skillSubcategory => {
      const skillsFilter = new ActivityFilter({ skills: skillSubcategory?.skills.map(skill => skill?.id) });
      const filters = {
        from: 0,
        size: 9,
        randomizeOrder: false,
        filters: skillsFilter,
        property: undefined,
        sort: { raw: '_score' },
      };
      return this.client.searchActivities(filters).then(response => {
        activityCountsBySkillSet.set(skillSubcategory?.skillCategoryId, response?.total?.value);
      }).catch(error => {
        console.error(`Fetching activities data failed for category: ${skillSubcategory.id}(${skillSubcategory.name})`, error);
      });
    }));

    this._activityCountsBySkillSet = activityCountsBySkillSet;
  }

  async _setupSkillSets() {
    this.selectedSkillCategories?.forEach(selectedSubcategory => {
      selectedSubcategory.skills = this._getSkillsForSkillCategory(selectedSubcategory.skillCategoryId);
      this._skillProfileSubCategories.push(selectedSubcategory);
    });
  }

  _getSuffixId(selectedSkillSetId) {
    const suffixId = selectedSkillSetId !== null ? `_${selectedSkillSetId}` : '';

    return suffixId;
  }
}

window.customElements.define('skill-sets-carousel', SkillSetsCarousel);
