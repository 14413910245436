import '@brightspace-ui/core/components/button/button.js';
import '@brightspace-ui/core/components/button/button-icon.js';
import '@brightspace-ui/core/components/form/form.js';
import '@brightspace-ui/core/components/inputs/input-date.js';
import '@brightspace-ui/core/components/inputs/input-number.js';
import '@brightspace-ui/core/components/inputs/input-text.js';
import '@brightspace-ui/core/components/inputs/input-textarea.js';
import '@brightspace-ui/core/components/tooltip/tooltip.js';
import '@brightspace-ui/core/components/tooltip/tooltip-help.js';

import '../../../../shared/components/dialog/confirmation-dialog/confirmation-dialog.js';
import '../../../../shared/components/general/nova-card/nova-card.js';

import { bodyCompactStyles, heading3Styles } from '@brightspace-ui/core/components/typography/styles.js';
import { css, html, LitElement, nothing } from 'lit';
import { inputLabelStyles } from '@brightspace-ui/core/components/inputs/input-label-styles.js';
import { navigator as nav } from 'lit-element-router';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';
import { selectStyles } from '@brightspace-ui/core/components/inputs/input-select-styles.js';
import { SkeletonMixin } from '@brightspace-ui/core/components/skeleton/skeleton-mixin.js';

// eslint-disable-next-line import/no-extraneous-dependencies
import Decimal from 'decimal.js';

import Activity from '../../../../../shared/models/activity/activity.js';
import { Application } from '../../../../../shared/models/application/index.js';
import { COMPLETION_STATUSES } from '../../../../../shared/constants.js';
import { convertDateToYMD } from '../../../../../shared/helpers/dateTime.js';
import { Cost } from '../../../../../shared/models/cost.js';
import { LocalizeNova } from '../../../../shared/mixins/localize-nova/localize-nova.js';

import { GENERATED_TEST_ID_CATEGORIES, makePlaywrightTestId, STATIC_TEST_IDS } from '../../../../../shared/helpers/playwright.js';

const DIALOGS = {
  ENROLLMENT_DATE: 'enrollment-date',
  FAILED: 'failed',
  PASSED: 'passed',
  WITHDRAWN: 'withdrawn',
  CANCELLED: 'cancelled',
  NONE: 'none',
};

const REFUND_METHODS = {
  AMOUNT: 'Amount',
  PERCENTAGE: 'Percentage',
};

class ProviderAdminApplicationForm extends LocalizeNova(SkeletonMixin(RequesterMixin(nav(LitElement)))) {

  static get properties() {
    return {
      activity: { type: Object, reflect: false },
      application: { type: Object, reflect: false },
      learnerTerminology: { type: String, reflect: false },
      _enrollDate: { type: String, reflect: false },
      _completedDate: { type: String, reflect: false },
      _selectedCompletionStatus: { type: String, reflect: false },
      _selectedRefundMethod: { type: String, reflect: false },
      _refundAmount: { type: Number, reflect: false }, // used in the confirmation dialog
      _retainAmount: { type: Number, reflect: false }, // used in the confirmation dialog
      _cancelReason: { type: String, reflect: false },
      // local enum state property reflecting which confirmation dialog is open
      _dialogOpen: { type: String, reflect: false },
      _refundAmtByPct: { type: Number, reflect: false }, // refund amount calculated by percentage
      _isUpdatingRefundAmt: { type: Boolean, reflect: false }, // debounce flag for updating refund amount
    };
  }

  static get styles() {
    return [
      bodyCompactStyles,
      heading3Styles,
      inputLabelStyles,
      selectStyles,
      css`
        :host {
          display: block;
        }

        .input-select-wrapper {
          display: inline-block;
          padding: 10px 0;
        }

        d2l-button {
          margin-left: 10px;
        }

        .refund-activity-cost {
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
        }

        .refund-details-info {
          display: flex;
          flex-direction: column;
        }

        .refund-by-info {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          gap: 27px 26px;
          margin-top: 15px;
        }

        #refund-by-amount-or-by-percentage {
          width: 9rem;
        }

        .refund-heading-detail {
          margin-top: 6px;
        }

        .refund-heading-h3 {
          margin-bottom: 6px;
        }

        .refund-required::after {
          background-image: url(data:image/svg+xml,%3Csvg%20width%3D%225%22%20height%3D%226%22%20viewBox%3D%220%200%205%206%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M2.38%205.141V3.86c0-.093.006-.184.018-.273.011-.089.031-.173.059-.252a.927.927%200%200%201-.182.175c-.07.051-.145.103-.224.154l-1.106.644-.413-.7%201.113-.644c.084-.051.167-.093.248-.126.082-.033.167-.056.256-.07a.816.816%200%200%201-.256-.07%202.356%202.356%200%200%201-.248-.133L.532%201.914l.406-.7%201.113.658c.08.051.155.104.228.157a.966.966%200%200%201%20.185.179%201.002%201.002%200%200%201-.066-.252%202.091%202.091%200%200%201-.018-.273V.388h.826v1.281c0%20.098-.006.192-.017.283a1.003%201.003%200%200%201-.067.256c.051-.065.112-.125.182-.179.07-.053.147-.106.231-.157l1.106-.644.413.7-1.113.637a1.954%201.954%200%200%201-.248.13%201.07%201.07%200%200%201-.256.073c.159.028.327.093.504.196l1.113.651-.406.7-1.113-.651a3.307%203.307%200%200%201-.231-.154%201.122%201.122%200%200%201-.189-.175c.06.15.091.322.091.518v1.288H2.38z%22%20fill%3D%22%23494C4E%22%20fill-rule%3D%22evenodd%22%2F%3E%3C%2Fsvg%3E);
          bottom: 0.25rem;
          content: "";
          display: inline-block;
          height: 0.3rem;
          inset-inline-start: 0.15rem;
          position: relative;
          width: 0.25rem;
        }

        .registration-info {
          align-items: center;
          display: flex;
          flex-wrap: wrap;
          gap: 0 20px;
          justify-content: flex-start;
        }

        .registration-item {
          padding: 10px 0;
        }

        d2l-input-number {
          max-width: -moz-fit-content;
          max-width: fit-content;
        }

        .mt-0 {
          margin-top: 0;
        }

        .mb-0 {
          margin-bottom: 0;
        }

        .dialog-above-content {
          padding: 20px 0 0 0;
        }

        #provider-form-deduction,
        #provider-form-refund,
        #provider-form-refund-percentage {
          width: 8rem;
        }

        @media (max-width: 767px) {
          .refund-by-info {
            flex-direction: column;
          }
        }
      `,
    ];
  }

  constructor() {
    super();
    this._selectedCompletionStatus = COMPLETION_STATUSES.PENDING;
    this._selectedRefundMethod = REFUND_METHODS.AMOUNT;
    this.application = new Application();
    this.activity = new Activity();
    this._dialogOpen = DIALOGS.NONE;
    this._isUpdatingRefundAmt = false;
  }

  connectedCallback() {
    super.connectedCallback();
    this.client = this.requestInstance('d2l-nova-client');
    this.session = this.requestInstance('d2l-nova-session');
    this.resizeObserver = new ResizeObserver(() => this._handleWindowResize());
    this.resizeObserver.observe(this);
  }

  render() {
    const saveButtonLabel = this.localize('application-call-to-action.template.provider.buttonSave');
    return html`
      <d2l-form id="provider-form">
        <div class="registration-info">
          <!-- enrollment date date picker -->
          ${this._enrollmentDateInputTemplate()}
          <!-- completion status dropdown -->
          ${this._completionStatusInputTemplate()}
          <!-- completion date date picker -->
          ${this._completionDateInputTemplate()}
        </div>

        <div class="refund-details-info">
          <!-- Only show refund amount input if the selected completion status is Withdrawn or Cancelled -->
          ${this._refundInputTemplate()}
        </div>

        <!-- save button -->
        ${this._saveIsHidden() ? nothing : html`
          <d2l-button
            style="margin-left: 0px; margin-top: 26px;"
            aria-label=${saveButtonLabel}
            primary
            @click=${this._handleSaveClick()}
            .disabled=${this._saveIsDisabled()}>
            ${saveButtonLabel}
          </d2l-button>
        `}
      </d2l-form>
      <!-- confirmation dialog -->
      ${this.getDialogTemplate()}
    `;
  }

  async updated(_changedProperties) {
    let shouldInitializeProperties = false;
    for (const [propName] of _changedProperties) {
      if ((propName === 'application' && this.application)) {
        shouldInitializeProperties = true;
      }
    }
    if (shouldInitializeProperties) {
      this._initializeProperties();
    }
  }

  _initializeProperties() {
    this._learnerName = this.application?.user?.getDisplayName(this.application?.user);

    this._activityCostWithCurrency = this.application?.transaction?.providerAmount?.formatAsDecimal(this.session.user.getSetting('language'), true);
    this._activityCostAsNum = this.application?.transaction?.providerAmount?.formatAsNumber();

    this._activityType = this.activity?.getTranslatedValue('type');

    // initialize local component state
    this._selectedCompletionStatus = this.application.completionStatus || COMPLETION_STATUSES.PENDING;
    // if the app status is pending, pass or fail, then refund percent should always be empty
    // otherwise show the application's stamped refund percent,
    // if this is blank, default to null
    this._refundPct = [COMPLETION_STATUSES.PENDING, COMPLETION_STATUSES.PASS, COMPLETION_STATUSES.FAIL].includes(this._selectedCompletionStatus) ? '' : this.application.refundPct ?? null;

    this._cancelReason = this.application.cancelReason;
    this._enrollDate = this.application.enrollDate;
    this._completedDate = this.application.completedDate;
  }

  async _openDialogOrSaveApplication() {
    if (this._selectedCompletionStatus === COMPLETION_STATUSES.PENDING) {
      this._dialogOpen = DIALOGS.ENROLLMENT_DATE;
      return;
    } else if (this._selectedCompletionStatus === COMPLETION_STATUSES.CANCELLED) {
      this._dialogOpen = DIALOGS.CANCELLED;
      return;
    } else if (this._selectedCompletionStatus === COMPLETION_STATUSES.FAIL) {
      this._dialogOpen = DIALOGS.FAILED;
      return;
    } else if (this._selectedCompletionStatus === COMPLETION_STATUSES.PASS) {
      this._dialogOpen = DIALOGS.PASSED;
      return;
    } else if (this._selectedCompletionStatus === COMPLETION_STATUSES.WITHDRAWN) {
      this._dialogOpen = DIALOGS.WITHDRAWN;
      return;
    }

    this._displayNotificationToast();
    await this._updateCompletion();
  }

  _completionStatusChanged(e) {
    this._selectedCompletionStatus = e.target.value;

    switch (this._selectedCompletionStatus) {
      case COMPLETION_STATUSES.CANCELLED:
        this._handleCancellation();
        if (!this.application.completedDate && !this.application.enrollDate) {
          this._enrollDate = '';
        }
        break;
      case COMPLETION_STATUSES.PENDING:
        this._completedDate = '';
        break;
      case COMPLETION_STATUSES.WITHDRAWN:
        this._handleCancellation();
        break;
      default:
        break;
    }
  }

  _handleCancellation() {
    this._refundPct = null;
    this._refundAmtByPct = null;
    this._refundAmtByPctWithCurrency = null;

    this._deduction = null;
    this._refundAmt = null;
  }

  _dialogClose() {
    return async e => {
      this._dialogOpen = DIALOGS.NONE;
      const { action } = e.detail;

      if (action === 'abort') {
        this._cancelReason = this.application.cancelReason;
      }

      if (action === 'done') {
        await this._updateCompletion();
      }
    };
  }

  _dispatchUpdateEvent() {
    // this event bubbles up to the parent to tell it to re-render and pass down a new "application" prop to its children
    this.dispatchEvent(new CustomEvent('update-application', { detail: { application: this.application } }));
  }

  async _updateCompletion() {
    const isoEnrollDate = this._enrollDate ? new Date(this._enrollDate).toISOString() : undefined;
    const isoCompletedDate = this._completedDate ? new Date(this._completedDate).toISOString() : '';
    this.application = await this.client.completeApplication(
      this.application.uuid,
      isoEnrollDate,
      isoCompletedDate,
      this._selectedCompletionStatus,
      this._cancelReason,
      this._refundPct
    );
    this._dispatchUpdateEvent();
  }

  _displayNotificationToast() {
    const message = this.localize('application-call-to-action.discountCode.save.success');
    const type = 'default';
    this.session.toast({ type, message, noAutoClose: false });
  }

  get _formComponentIds() {
    return {
      completionDate: 'provider-form-completion-date',
      discountCode: 'provider-form-discount-code',
      enrollmentDate: 'provider-form-enrollment-date',
      deduction: 'provider-form-deduction',
      refund: 'provider-form-refund',
      refundPercentage: 'provider-form-refund-percentage',
      refundPercentageAmount: 'provider-form-refund-amount-by-percentage',
    };
  }

  _createErrorMsg(invalidComponents) {
    if (invalidComponents.length === 1) {
      return this.localize(`application-call-to-action.providerForm.error.empty.${invalidComponents[0].label}`);
    }
    else if (invalidComponents.length > 1) {
      const localizedLabels = invalidComponents.map(component => component.localized);
      const last = localizedLabels.pop();
      const invalidComponentsNames = `${localizedLabels.join(', ')} ${this.localize('application-call-to-action.providerForm.error.template.and')} ${last}`;
      return `${this.localize('application-call-to-action.providerForm.error.template')} ${invalidComponentsNames}`;
    }
  }

  _handleWindowResize() {
    this._setTooltipPosition(window.innerWidth);
  }

  // set tooltip-help position based on window width
  _setTooltipPosition(windowWidth) {
    const d2lTooltipHelp = this.shadowRoot.querySelectorAll('d2l-tooltip-help');
    d2lTooltipHelp.forEach(tooltip => {
      const tooltipPosition = windowWidth <= 768 ? 'top' : 'right';
      tooltip.setAttribute('position', tooltipPosition);
    });
  }

  _handleSaveClick() {
    return () => {
      const providerForm = this.shadowRoot.querySelector('[id="provider-form"]');
      const invalidComponents = [];
      // Define validation rules
      const validationRules = {
        'provider-form-enrollment-date': el => !el.value,
        'provider-form-completion-date': el => !el.value,
        'provider-form-deduction': el => isNaN(el.value) || el.value > this._activityCostAsNum || el.value < 0,
        'provider-form-refund': el => isNaN(el.value) || el.value > this._activityCostAsNum || el.value < 0,
        'provider-form-refund-percentage': el => isNaN(el.value) || el.value > 100 || el.value < 0,
        'provider-form-discount-code': el => !el.value,
      };

      // Iterate through form elements and validate them
      Object.keys(this._formComponentIds).forEach(label => {
        const id = this._formComponentIds[label];
        const formElement = providerForm.querySelector(`[id=${id}]`);

        // Skip disabled elements
        if (!formElement || formElement.disabled) {
          return;
        }

        // Apply validation if rule exists
        if (validationRules[id] && validationRules[id](formElement)) {
          invalidComponents.push({
            label,
            localized: this.localize(`application-call-to-action.input.${label}.valid.label`),
          });
        }
      });

      if (new Date(this._enrollDate) > new Date(this._completedDate)) {
        this.session.toast({
          type: 'critical',
          message: this.localize('application-call-to-action.providerForm.error.completionBeforeEnrollment'),
          noAutoClose: false,
        });
        return;
      }
      if (invalidComponents.length === 0) {
        return this._openDialogOrSaveApplication();
      } else {
        this.session.toast({
          type: 'critical',
          message: this._createErrorMsg(invalidComponents),
          noAutoClose: false,
        });
      }
    };
  }

  get _completionStatuses() {
    // a map for each possible completion status option in the dropdown input
    // `alwaysEnabled`: whether the dropdown option is always enabled, or sometimes greyed out/disabled
    // `completionDateEnabled`: whether the completion date input is enabled/required when this status is selected
    // `refundEnabled`: whether the refund percent input is enabled/required when this status is selected
    // `localeKey`: the lang term for the dropdown option value
    return {
      'Pending': { alwaysEnabled: true, completionDateEnabled: false, refundEnabled: false, localeKey: 'application-call-to-action.status.pending' },
      'Pass': { alwaysEnabled: false, completionDateEnabled: true, refundEnabled: false, localeKey: 'application-call-to-action.status.pass' },
      'Fail': { alwaysEnabled: false, completionDateEnabled: true, refundEnabled: false, localeKey: 'application-call-to-action.status.fail' },
      'Withdrawn': { alwaysEnabled: false, completionDateEnabled: true, refundEnabled: true, localeKey: 'application-call-to-action.status.withdrawn' },
      'Cancelled': { alwaysEnabled: true, completionDateEnabled: true, refundEnabled: true, localeKey: 'application-call-to-action.status.cancelled' },
    };
  }

  get _refundMethod() {
    // a map for the two refund methods: refund by amount or refund by percentage
    // `localeKey`: the lang term for the dropdown option value
    return {
      'Amount': { localeKey: 'application-call-to-action.refundMethod.amount' },
      'Percentage': { localeKey: 'application-call-to-action.refundMethod.percentage' },
    };
  }

  _saveTargetValue(propertyName) {
    const handler = e => {
      this[propertyName] = e.target.value;

      switch (propertyName) {
        case '_deduction':
          if (this._isUpdatingRefundAmt) return;

          this._isUpdatingRefundAmt = true;
          this._deduction = parseFloat(e.target.value) || 0;
          this._refundAmt = this._activityCostAsNum - this._deduction;
          // update `this._refundPct` here for backend calculation
          this._refundPct = new Decimal(this._refundAmt).div(this._activityCostAsNum).times(100).toNumber();

          this._isUpdatingRefundAmt = false;
          break;
        case '_refundAmt':
          if (this._isUpdatingRefundAmt) return;
          this._isUpdatingRefundAmt = true;
          this._refundAmt = parseFloat(e.target.value) || 0;
          this._refundAmt = Math.min(this._refundAmt, this._activityCostAsNum);
          this._deduction = this._activityCostAsNum - this._refundAmt;

          // update `this._refundPct` here for backend calculation
          this._refundPct = new Decimal(this._refundAmt).div(this._activityCostAsNum).times(100).toNumber();

          this._isUpdatingRefundAmt = false;
          break;
        case '_refundPct':
          if (e.target.value >= 0 && e.target.value <= 100) {
            this._refundAmtByPct = (this._activityCostAsNum * 100) * (parseFloat(e.target.value) / 100); // as a number without decimal point 100.35 -> 10035

            // format refund amount with currency for display
            if (this._refundAmtByPct) {
              this._refundAmtByPctWithCurrency = new Cost({
                cost: this._refundAmtByPct,
                hundredOfCents: this.application?.activity?.tempCost.hundredOfCents,
                currency: this.application?.activity?.tempCost.currency,
                originalCurrency: this.application?.activity?.tempCost.originalCurrency,
                conversionRate: this.application?.activity?.tempCost.conversionRate,
              }).formatAsDecimal(this.session.user.getSetting('language'), true);
            }
          } else {
            this._refundAmtByPct = null;
          }
          break;
        default:
          break;
      }
    };
    return handler;
  }

  _saveIsDisabled() {
    if (this.session?.isReadOnly) return true;
    // if the application is "complete" then you can't update/save anymore
    return [COMPLETION_STATUSES.PASS, COMPLETION_STATUSES.FAIL, COMPLETION_STATUSES.CANCELLED, COMPLETION_STATUSES.WITHDRAWN].includes(this.application?.completionStatus);
  }

  _saveIsHidden() {
    if (this.session?.isReadOnly) return true;
    // if the application was cancelled or withdrawn, hide the save button
    return [COMPLETION_STATUSES.CANCELLED, COMPLETION_STATUSES.WITHDRAWN].includes(this.application?.completionStatus);
  }

  _completionDateInputTemplate() {
    const completionDateInputLabel = this.localize('application-call-to-action.input.completionDate.label');
    const testId = makePlaywrightTestId(GENERATED_TEST_ID_CATEGORIES.dateInput, completionDateInputLabel);
    return html`
      <d2l-input-date
        id="provider-form-completion-date"
        class="registration-item"
        data-testid="${testId}"
        label=${completionDateInputLabel}
        title=""
        .minValue=${this._enrollDate || convertDateToYMD(new Date())}
        .disabled=${!this._completionStatuses[this._selectedCompletionStatus].completionDateEnabled || this._saveIsDisabled()}
        .required=${this._completionStatuses[this._selectedCompletionStatus].completionDateEnabled}
        .value=${this._completedDate}
        @change=${this._saveTargetValue('_completedDate')}></d2l-input-date>
    `;
  }

  _completionStatusInputTemplate() {
    const completionStatusInputLabel = this.localize('application-call-to-action.input.completionStatus.label');
    return html`
      <div class="input-select-wrapper"
        data-testid="${STATIC_TEST_IDS.completionSelect}"
      >
        <label for="completion-status" class="d2l-input-label">${completionStatusInputLabel}</label>
        <select id="completion-status"
          class="d2l-input-select"
          data-testid="${STATIC_TEST_IDS.completionSelectOptions}"
          @change=${this._completionStatusChanged}
          .value=${this._selectedCompletionStatus}
          .disabled=${this._saveIsDisabled()}
        >
        ${Object.keys(this._completionStatuses).map(key => html`
          <option
            .value=${key}
            .selected="${key === this.application.completionStatus}"
            .disabled=${!this._completionStatuses[key].alwaysEnabled && !this.application.enrollDate}
          >
            ${this.localize(this._completionStatuses[key].localeKey)}
          </option>
        `)}
        </select>
      </div>
    `;
  }

  _enrollmentDateInputTemplate() {
    const enrollmentDateInputLabel = this.localize('application-call-to-action.input.enrollmentDate.label');
    const testId = makePlaywrightTestId(GENERATED_TEST_ID_CATEGORIES.dateInput, enrollmentDateInputLabel);

    // enroll date input is disabled if an enroll date already exists,
    // if application is Pass/Fail/Withdrawn/Cancelled (or is cancelled and doesn't have a completion date)
    // or if condition based on provider payment method is fulfilled
    const isDisabled = this.application?.enrollDate ||
    this._saveIsDisabled() ||
    (this._selectedCompletionStatus === COMPLETION_STATUSES.CANCELLED && !this.application?.completedDate);
    return html`
      <d2l-input-date
        id="provider-form-enrollment-date"
        class="registration-item"
        data-testid="${testId}"
        label=${enrollmentDateInputLabel}
        .value=${this._enrollDate}
        .disabled=${isDisabled}
        style="padding-left: 0px;"
        @change=${this._saveTargetValue('_enrollDate')}>
      </d2l-input-date>
    `;
  }

  _refundInputTemplate() {
    // if there is a refund already issued, show the after refund message
    if ([COMPLETION_STATUSES.WITHDRAWN, COMPLETION_STATUSES.CANCELLED].includes(this.application?.completionStatus)) {
      return html`
        <h3 class="d2l-heading-3 refund-heading-h3">${this.localize('application-call-to-action.providerForm.refund.heading')}</h3>
        ${this._afterRefundMessage()}
      `;
    } else if ([COMPLETION_STATUSES.WITHDRAWN, COMPLETION_STATUSES.CANCELLED].includes(this._selectedCompletionStatus)) {
      return html`
        <h3 class="d2l-heading-3 refund-heading-h3">${this.localize('application-call-to-action.providerForm.refund.heading')}</h3>
        ${this._refundTemplate()}
      `;
    }
  }

  _afterRefundMessage() {
    const actualRefundPct = this.application?.refundPct;
    if (actualRefundPct === 0) {
      return html`
        <p class="d2l-skeletize">
          ${this.localize('application-call-to-action.providerForm.afterRefundMessage.noRefund', { learnerName: this._learnerName })}
        </p>
      `;
    }

    const providerCurrency = this.application?.transaction?.providerAmount?.currency;
    const providerAmount = this.application?.transaction?.providerAmount;
    let actualRefundAmtWithCurrency;

    if (!this.application?.transaction?.providerRefundAmount.cost) {
      actualRefundAmtWithCurrency = new Cost({
        cost: providerAmount.cost * (this._refundPct / 100),
        hundredOfCents: this.application?.activity?.tempCost.hundredOfCents,
        currency: providerCurrency,
        originalCurrency: this.application?.activity?.tempCost.originalCurrency,
        conversionRate: this.application?.activity?.tempCost.conversionRate,
      }).formatAsDecimal(this.session.user.getSetting('language'), true);
    } else {
      actualRefundAmtWithCurrency = this.application?.transaction?.providerRefundAmount.formatAsDecimal(this.session.user.getSetting('language'), true).replace(/-\s?/g, '');
    }

    const lang = this.session.user.getSetting('language');
    const completedDate = new Date(this.application?.completedDate).toISOString().split('T')[0];
    let stylizedCompletedDate;
    if (lang === 'fr') {
      stylizedCompletedDate = new Date(completedDate).toLocaleDateString('fr-CA', { year: 'numeric', month: 'long', day: 'numeric', timeZone: 'UTC' });
    } else if (lang === 'es') {
      stylizedCompletedDate = new Date(completedDate).toLocaleDateString('es-ES', { year: 'numeric', month: 'long', day: 'numeric', timeZone: 'UTC' });
    } else {
      // default to en-US
      stylizedCompletedDate = new Date(completedDate).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric', timeZone: 'UTC' });
    }

    const afterRefundMessage = this.localize('application-call-to-action.providerForm.afterRefundMessage', {
      actualRefundAmtWithCurrency,
      learnerName: this._learnerName,
      refundIssuedDate: stylizedCompletedDate,
      bold: this.l10nTags.bold(),
    });

    return html`<p class="d2l-skeletize">${afterRefundMessage}</p>`;
  }

  _refundTemplate() {
    return html`
      <p class="refund-heading-detail">${this.localize('application-call-to-action.providerForm.refund.details', { name: this._learnerName })}</p>
      ${this._refundTypeTemplate()}
    `;
  }

  _refundTypeTemplate() {
    const refundMethodLabel = this.localize('application-call-to-action.input.refund.method.label');
    return html`
      <div class="input-select-wrapper d2l-skeletize">
        <label for="refund-by-amount-or-by-percentage" class="d2l-input-label">${refundMethodLabel}</label>
        <select id="refund-by-amount-or-by-percentage"
          class="d2l-input-select"
          @change=${this._refundAmountOrPercentageChanged}
          .value=${this._selectedRefundMethod}
          .disabled=${this._saveIsDisabled()}
        >
        ${Object.keys(this._refundMethod).map(key => html`
          <option
            .value=${key}
            .selected="${key === this._selectedRefundMethod}"
            .disabled=${!this._completionStatuses[this._selectedCompletionStatus].refundEnabled}
          >
            ${this.localize(this._refundMethod[key].localeKey)}
          </option>
        `)}
        </select>
      </div>
      <div class="refund-by-info d2l-skeletize">
        <div class="refund-activity-cost">
          <p id="activity-label" class="d2l-input-label">${this.localize('activity.type.cost', { type: this._activityType })}</p>
          <p class="d2l-body-standard mt-0 mb-0" aria-labelledby="activity-label"><strong>${this._activityCostWithCurrency}</strong></p>
        </div>
        ${this._selectedRefundMethod === REFUND_METHODS.AMOUNT ? this._refundByAmountTemplate() : this._refundByPercentageTemplate()}
      </div>
    `;
  }

  _refundAmountOrPercentageChanged(e) {
    this._selectedRefundMethod = e.target.value;
    if (this._selectedRefundMethod === REFUND_METHODS.AMOUNT || this._selectedRefundMethod === REFUND_METHODS.PERCENTAGE) {
      this._resetValues();
    }
  }

  _resetValues() {
    this._refundPct = null;
    this._refundAmtByPct = null;
    this._refundAmtByPctWithCurrency = null;

    this._deduction = null;
    this._refundAmt = null;
  }

  _refundByAmountTemplate() {
    const deductionLabel = this.localize('application-call-to-action.input.deduction.label');
    const refundAmountLabel = this.localize('application-call-to-action.input.refund.label');

    return html`
      <!-- Deduction input -->
      <d2l-input-number
        id="provider-form-deduction"
        class="registration-item"
        label=${deductionLabel}
        unit="$"
        unit-label=${this.localize('unit.label.dollar')}
        min="0"
        max="${this._activityCostAsNum}"
        min-fraction-digits="2"
        max-fraction-digits="2"
        input-width="6rem"
        .disabled=${!this._completionStatuses[this._selectedCompletionStatus].refundEnabled || this._saveIsDisabled()}
        .required=${this._completionStatuses[this._selectedCompletionStatus].refundEnabled}
        @change=${this._saveTargetValue('_deduction')}
        .value=${this._deduction ?? null}
      ></d2l-input-number>
      <d2l-tooltip align="start" for="provider-form-deduction" state="info">
        ${this.localize('application-call-to-action.input.refund.deduction.tooltip')}
      </d2l-tooltip>
      <!-- Refund amount input -->
      <div class="refund-activity-cost registration-item">
        <p class="d2l-input-label refund-required">
          <d2l-tooltip-help
            inherit-font-style="true"
            text="${refundAmountLabel}"
            position="right">
              ${this.localize('application-call-to-action.input.refund.tooltip')}
          </d2l-tooltip-help>
        </p>
        <d2l-input-number
          id="provider-form-refund"
          label=${refundAmountLabel}
          label-hidden
          unit="$"
          unit-label=${this.localize('unit.label.dollar')}
          min="0"
          max="${this._activityCostAsNum}"
          min-fraction-digits="2"
          max-fraction-digits="2"
          input-width="6rem"
          .disabled=${!this._completionStatuses[this._selectedCompletionStatus].refundEnabled || this._saveIsDisabled()}
          .required=${this._completionStatuses[this._selectedCompletionStatus].refundEnabled}
          @change=${this._saveTargetValue('_refundAmt')}
          .value=${this._refundAmt ?? null}
        ></d2l-input-number>
      </div>
    `;
  }

  _refundByPercentageTemplate() {
    const refundPctLabel = this.localize('application-call-to-action.input.refundPercentage.label');
    const refundAmountLabel = this.localize('application-call-to-action.input.refund.label');

    return html`
      <!-- Refund percentage input -->
      <d2l-input-number
        id="provider-form-refund-percentage"
        class="registration-item"
        label=${refundPctLabel}
        unit="%"
        unit-label=${this.localize('unit.label.percentage')}
        min="0"
        max="100"
        min-fraction-digits="2"
        max-fraction-digits="2"
        input-width="6rem"
        .disabled=${!this._completionStatuses[this._selectedCompletionStatus].refundEnabled || this._saveIsDisabled()}
        .required=${this._completionStatuses[this._selectedCompletionStatus].refundEnabled}
        @change=${this._saveTargetValue('_refundPct')}
        .value=${this._refundPct}
      ></d2l-input-number>
      <d2l-tooltip align="start" for="provider-form-refund-percentage" state="info">
        ${this.localize('application-call-to-action.input.refundPercentage.tooltip')}
      </d2l-tooltip>

      <!-- Calculated refund amount -->
      <div class="refund-activity-cost registration-item">
        <p class="d2l-input-label refund-required">
          <d2l-tooltip-help inherit-font-style="true" text="${refundAmountLabel}" position="right" id="calc-refund-amount">${this.localize('application-call-to-action.input.refund.tooltip')}</d2l-tooltip-help>
        </p>
        <p class="d2l-body-standard" style="margin-top: 8px; margin-bottom: 8px;" aria-labelledby="calc-refund-amount"><strong>${this._refundAmtByPctWithCurrency ?? '--'}</strong></p>
      </div>
    `;
  }

  get _commonDialogData() {
    return {
      activityTitle: this.activity?.title,
      activityType: this.activity.getTranslatedValue('type'),
      username: this.application?.user.getDisplayName?.(),
      completionStatus: this._selectedCompletionStatus,
      learnerTerminology: this.learnerTerminology,
    };
  }

  _refundBreakdownTemplate() {
    const lang = this.session.user.getSetting('language');
    const { transaction: { providerAmount } } = this.application;

    this._retainPct = parseFloat((100 - this._refundPct).toFixed(2));
    this._refundAmount = providerAmount.cost * (this._refundPct / 100);
    this._retainAmount = providerAmount.cost - this._refundAmount;

    const refundAmountCost = new Cost({
      cost: this._refundAmount,
      currency: providerAmount.currency.toUpperCase(),
    });
    const retainAmountCost = new Cost({
      cost: this._retainAmount,
      currency: providerAmount.currency.toUpperCase(),
    });

    const refundAmountFormatted = refundAmountCost.formatAsDecimal(lang);
    const retainAmountFormatted = retainAmountCost.formatAsDecimal(lang);

    const refundMessageForProvider = this._selectedRefundMethod === REFUND_METHODS.AMOUNT ?
      this.localize('application-call-to-action.dialog.refundMessageForProvider.amount', { refundAmountFormatted: refundAmountFormatted, learnerName: this._learnerName }) :
      this.localize('application-call-to-action.dialog.refundMessageForProvider.percentage', { refundPercentage: this._refundPct, refundAmountFormatted, learnerName: this._learnerName });

    const retainMessageForProvider = this._selectedRefundMethod === REFUND_METHODS.AMOUNT ?
      this.localize('application-call-to-action.dialog.retainMessageForProvider.amount', { retainAmountFormatted, providerName: this.application.institution }) :
      this.localize('application-call-to-action.dialog.retainMessageForProvider.percentage', { retainPercentage: this._retainPct, retainAmountFormatted, providerName: this.application.institution });

    return html`
      <b>${this.localize('application-call-to-action.dialog.refundBreakdown')}</b>
      <p class="mt-0 mb-0">
        <span data-testid="${STATIC_TEST_IDS.refundText}">
        ${refundMessageForProvider}
        </span>
      </p>
      <p class="mt-0">
        <span data-testid="${STATIC_TEST_IDS.retainedText}">
        ${retainMessageForProvider}
        </span>
      </p>
    `;
  }

  cancelledDialog() {
    const dialogData = {
      ...this._commonDialogData,
      completionDate: this._completedDate,
    };
    const belowContentTitle1 = this.localize('application-call-to-action.dialog.cancelled.reasonInfo.1');
    const belowContentTitle2 = this.localize('application-call-to-action.dialog.cancelled.reasonInfo.2');
    const reasonInputLabel = this.localize('application-call-to-action.dialog.cancelled.reason.label');
    return html`
      <confirmation-dialog
        ?opened=${this._dialogOpen === DIALOGS.CANCELLED}
        ?disableConfirm=${!this._cancelReason}
        type="cancelledStatusConfirmation"
        .data=${dialogData}
        .displayNotificationToast=${this._displayNotificationToast}
        @d2l-dialog-close=${this._dialogClose()}>
        <div slot="above-content" class="dialog-above-content">
          ${this._refundBreakdownTemplate()}
        </div>
        <div slot="below-content">
          <b>${belowContentTitle1}</b> ${belowContentTitle2}
          <d2l-input-textarea
            label=${reasonInputLabel}
            maxlength=300
            label-hidden
            required
            title=""
            @input=${this._saveTargetValue('_cancelReason')}></d2l-input-textarea>
        </div>
      </confirmation-dialog>
    `;
  }

  enrollDateDialog() {
    const dialogData = {
      ...this._commonDialogData,
      enrollDate: this._enrollDate,
    };
    return html`
      <confirmation-dialog
        ?opened=${this._dialogOpen === DIALOGS.ENROLLMENT_DATE}
        type="enrollmentDateConfirmation"
        .data=${dialogData}
        .displayNotificationToast=${this._displayNotificationToast}
        @d2l-dialog-close=${this._dialogClose()}>
      </confirmation-dialog>
    `;
  }

  failedDialog() {
    const dialogData = {
      ...this._commonDialogData,
      completionDate: this._completedDate,
    };
    return html`
      <confirmation-dialog
        ?opened=${this._dialogOpen === DIALOGS.FAILED}
        type="failedStatusConfirmation"
        .data=${dialogData}
        .displayNotificationToast=${this._displayNotificationToast}
        @d2l-dialog-close=${this._dialogClose()}>
      </confirmation-dialog>
    `;
  }

  passedDialog() {
    const dialogData = {
      ...this._commonDialogData,
      completionDate: this._completedDate,
    };
    return html`
      <confirmation-dialog
        ?opened=${this._dialogOpen === DIALOGS.PASSED}
        type="passedStatusConfirmation"
        .data=${dialogData}
        .displayNotificationToast=${this._displayNotificationToast}
        @d2l-dialog-close=${this._dialogClose()}>
      </confirmation-dialog>
    `;
  }

  withdrawnDialog() {
    const dialogData = {
      ...this._commonDialogData,
      completionDate: this._completedDate,
    };
    return html`
      <confirmation-dialog
        ?opened=${this._dialogOpen === DIALOGS.WITHDRAWN}
        type="withdrawnStatusConfirmation"
        .data=${dialogData}
        .displayNotificationToast=${this._displayNotificationToast}
        @d2l-dialog-close=${this._dialogClose()}>
        <div slot="above-content" class="dialog-above-content">
          ${this._refundBreakdownTemplate()}
        </div>
      </confirmation-dialog>
    `;
  }

  getDialogTemplate() {
    switch (this._dialogOpen) {
      case DIALOGS.CANCELLED:
        return this.cancelledDialog();
      case DIALOGS.ENROLLMENT_DATE:
        return this.enrollDateDialog();
      case DIALOGS.FAILED:
        return this.failedDialog();
      case DIALOGS.PASSED:
        return this.passedDialog();
      case DIALOGS.WITHDRAWN:
        return this.withdrawnDialog();
      case DIALOGS.NONE:
        return nothing;
      default:
        return nothing;
    }
  }
}

window.customElements.define('provider-admin-application-form', ProviderAdminApplicationForm);
