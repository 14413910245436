export const NovaFlowStepMixin = superclass => class extends superclass {

  static get properties() {
    return {
      step: { type: Object },
      nextEnabled: { type: Boolean, attribute: 'next-enabled' },
      skippable: { type: Boolean },
      blockBack: { type: Boolean, attribute: 'block-back' },
      data: { type: Object },
      novaFlowData: { type: Object },
      shouldSkip: { type: Function },
    };
  }

  constructor() {
    super();
    this.step = {};
    this.nextEnabled = false;
    this.skippable = false;
    this.blockBack = false;
    this.data = undefined;
    this.stepIndex = -1;
    this.stepCount = 0;
    this.displayName = '';
    this.name = '';
    this._parentFlow = null;

    this.shouldSkip = () => false;
  }

  get _existingData() {
    if (!this._parentFlow) {
      return null;
    }

    const storage = localStorage.getItem(this._parentFlow._storageKey);
    const item = storage ? JSON.parse(storage)[this.name] : null;
    return item;
  }

  connectedCallback() {
    super.connectedCallback();

    if (this.step) {
      this.name = this.step.name;
      this.displayName = this.step.displayName;
      this.slot = this.name;
    }

    const parentFlow = this.parentElement;
    if (parentFlow) {
      this._parentFlow = parentFlow;
    }
  }

  updated(changedProperties) {
    if (changedProperties.has('nextEnabled') && this.nextEnabled !== undefined) {
      this.dispatchEvent(new CustomEvent('nova-flow-step-next-enabled', {
        detail: {
          step: this.step,
          nextEnabled: this.nextEnabled,
        },
        bubbles: true,
      }));
    }

    if (changedProperties.has('step')) {
      this.name = this.step.name;
      this.displayName = this.step.displayName;
      this.slot = this.name;
    }

    if (changedProperties.has('data')) {
      if (!this._parentFlow) {
        return;
      }

      const storage = localStorage.getItem(this._parentFlow._storageKey) || '{}';
      const storageObject = JSON.parse(storage);
      storageObject[this.step.name] = this.data;
      localStorage.setItem(this._parentFlow._storageKey, JSON.stringify(storageObject));
    }
  }

  goNext() {
    if (!this.nextEnabled) {
      return;
    }

    this.dispatchEvent(new CustomEvent('nova-flow-step-next', {
      bubbles: true,
    }));
  }

  goBack() {
    this.dispatchEvent(new CustomEvent('nova-flow-step-back', {
      bubbles: true,
    }));
  }

  finish() {
    this.dispatchEvent(new CustomEvent('nova-flow-step-finish', {
      bubbles: true,
    }));
  }

  updateStep(step) {
    this.dispatchEvent(new CustomEvent('nova-flow-step-updated', {
      detail: {
        step: step,
      },
      bubbles: true,
    }));
  }
};
