import { SkeletonMixin } from '@brightspace-ui/core/components/skeleton/skeleton-mixin.js';

import { css, html, LitElement } from 'lit';

import { LocalizeNova } from '../../../mixins/localize-nova/localize-nova.js';

class NovaTile extends LocalizeNova(SkeletonMixin(LitElement)) {
  static get properties() {
    return {
      text: { type: String },
      icon: { type: String },
    };
  }

  static get styles() {

    return css`
      /* Add styles here */
      :host {
        --nova-tile-background-color: white;
        --nova-tile-border: 1px solid #cdd5dc;
        --nova-tile-font-color: #202122; /* Default font color */
        --nova-tile-font-size: 0.95rem;
        --nova-tile-font-weight: 400;
        --nova-tile-line-height: 4.5rem;


        align-items: center;
        background-color: var(--nova-tile-background-color);
        border: var(--nova-tile-border);
        border-radius: 6px;
        color: var(--nova-tile-font-color);
        display: inline-block;
        font-size: var(--nova-tile-font-size);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
      }

      .tile-container {
        align-items: center;
        display: flex;
        height: 86px;
        padding: 0 10px;
        width: 524px;
      }

      .tile-child {
        align-items: center;
        display: flex;
        height: var(--nova-tile-line-height);
        line-height: var(--nova-tile-line-height);
      }

      .icon-container {
        align-items: center; /* Vertically center the icon */
        background-color: var(--d2l-color-celestine); /* Circle background color */
        border-radius: 50%; /* This makes the div circular */
        display: flex;
        height: 48px;
        justify-content: center; /* Horizontally center the icon */
        margin: 0 20px;
        width: 48px;
      }

      #hype-icon {
        color: white;
        height: 100%;
        margin: 13px;
        width: 100%;
      }

      .nova-tile-box {
        font-size: 20px;
        font-weight: semi-bold;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    `;
  }

  _iconTemplate() {
    const inner = html`
      <div class="icon-container">
        <d2l-icon id="hype-icon" class='primary' icon="${this.icon}"></d2l-icon>
      </div>
    `;

    return html`
      <span class="hype-icon-container">
        ${inner}
      </span>
    `;
  }

  render() {
    return html`
      <div class="tile-container">
          <span class="tile-child" title="tes test">
            ${this._iconTemplate()}
            <h3 class="nova-tile-box">${this.text}</h3>
          </span>
      </div>
      `;
  }
}

window.customElements.define('nova-tile', NovaTile);
