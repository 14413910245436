export const FULL_PERMISSIONS = {
  'activity:view': {
    category: 'activity',
  },
  'activity:create-update': {
    category: 'activity',
  },
  'activity:status:update': {
    category: 'activity',
  },
  'activity:export': {
    category: 'activity',
  },
  'activity:streams:import': {
    category: 'activity',
  },
  'activity:archive': {
    category: 'activity',
  },
  'provider:general:view': {
    category: 'provider:view',
  },
  'provider:finance:view': {
    category: 'provider:view',
  },
  'provider:howitworks:view': {
    category: 'provider:view',
  },
  'provider:customattributes:view': {
    category: 'provider:view',
  },
  'provider:faq:view': {
    category: 'provider:view',
  },
  'provider:activityinstructions:view': {
    category: 'provider:view',
  },
  'provider:entitlements:view': {
    category: 'provider:view',
  },
  'provider:login:view': {
    category: 'provider:view',
  },
  'provider:audit:view': {
    category: 'provider:view',
  },
  'provider:general:update': {
    category: 'provider:update',
  },
  'provider:finance:update': {
    category: 'provider:update',
  },
  'provider:howitworks:update': {
    category: 'provider:update',
  },
  'provider:customattributes:update': {
    category: 'provider:update',
  },
  'provider:faq:update': {
    category: 'provider:update',
  },
  'provider:activityinstructions:update': {
    category: 'provider:update',
  },
  'provider:entitlements:update': {
    category: 'provider:update',
  },
  'provider:login:update': {
    category: 'provider:update',
  },
  'provider:audit:update': {
    category: 'provider:update',
  },
  'provider:create': {
    category: 'provider',
  },
  'employer:general:view': {
    category: 'employer:view',
  },
  'employer:careerexplorer:view': {
    category: 'employer:view',
  },
  'employer:finance:view': {
    category: 'employer:view',
  },
  'employer:howitworks:view': {
    category: 'employer:view',
  },
  'employer:customattributes:view': {
    category: 'employer:view',
  },
  'employer:faq:view': {
    category: 'employer:view',
  },
  'employer:termsofuse:view': {
    category: 'employer:view',
  },
  'employer:entitlements:view': {
    category: 'employer:view',
  },
  'employer:login:view': {
    category: 'employer:view',
  },
  'employer:visibility:view': {
    category: 'employer:view',
  },
  'employer:enabledstreams:view': {
    category: 'employer:view',
  },
  'employer:audit:view': {
    category: 'employer:view',
  },
  'employer:general:update': {
    category: 'employer:update',
  },
  'employer:careerexplorer:update': {
    category: 'employer:update',
  },
  'employer:finance:update': {
    category: 'employer:update',
  },
  'employer:howitworks:update': {
    category: 'employer:update',
  },
  'employer:customattributes:update': {
    category: 'employer:update',
  },
  'employer:faq:update': {
    category: 'employer:update',
  },
  'employer:termsofuse:update': {
    category: 'employer:update',
  },
  'employer:entitlements:update': {
    category: 'employer:update',
  },
  'employer:login:update': {
    category: 'employer:update',
  },
  'employer:visibility:update': {
    category: 'employer:update',
  },
  'employer:enabledstreams:update': {
    category: 'employer:update',
  },
  'employer:audit:update': {
    category: 'employer:update',
  },
  'admin:general:view': {
    category: 'admin:view',
  },
  'admin:general:update': {
    category: 'admin:update',
  },
  'admin:login:view': {
    category: 'admin:view',
  },
  'admin:login:update': {
    category: 'admin:update',
  },
  'admin:userpermissions:view': {
    category: 'admin:view',
  },
  'admin:userpermissions:update': {
    category: 'admin:update',
  },
  'admin:roles:view': {
    category: 'admin:view',
  },
  'admin:roles:update': {
    category: 'admin:update',
  },
  'admin:audit:view': {
    category: 'admin:view',
  },
  'admin:audit:update': {
    category: 'admin:update',
  },
  'admin:create': {
    category: 'admin',
  },
  'employer:create': {
    category: 'employer',
  },
  'features:view': {
    category: 'features',
  },
  'features:update': {
    category: 'features',
  },
  'requests:view': {
    category: 'requests',
  },
  'requests:view-all': {
    category: 'requests',
  },
  'requests:update': {
    category: 'requests',
  },
  'analytics:view': {
    category: 'analytics',
  },
  'settings:update': {
    category: 'settings',
  },
  'settings:payment:view': {
    category: 'settings',
  },
  'settings:payment:update': {
    category: 'settings',
  },
};

// Build the hierarchical permissions structure
export const PERMISSIONS_BY_CATEGORY = Object.keys(FULL_PERMISSIONS).reduce((acc, permissionKey) => {
  const permission = FULL_PERMISSIONS[permissionKey];
  const [category, subcategory] = permission.category.split(':');

  if (!acc[category]) {
    acc[category] = {
      permissions: [],
      categories: {},
    };
  }

  if (subcategory) {
    if (!acc[category].categories[permission.category]) {
      acc[category].categories[permission.category] = {
        permissions: [],
      };
    }
    acc[category].categories[permission.category].permissions.push(permissionKey);
  } else {
    acc[category].permissions.push(permissionKey);
  }

  return acc;
}, {});

export const PERMISSION_NAMES = Object.keys(FULL_PERMISSIONS);
