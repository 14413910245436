import '@brightspace-ui/core/components/button/button.js';
import { bodyCompactStyles, heading1Styles } from '@brightspace-ui/core/components/typography/styles.js';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';
import { SkeletonMixin } from '@brightspace-ui/core/components/skeleton/skeleton-mixin.js';

import { css, html, LitElement } from 'lit';

import '../../../../../../shared/components/general/nova-lang-select-button/nova-lang-select-button.js';
import { skillswaveButtonStyles, skillswaveHeading1Styles, skillswaveParagraphSubtleStyles } from '@/app/shared/styles/skillswave-text-styles.js';
import { LocalizeNova } from '../../../../../../shared/mixins/localize-nova/localize-nova.js';
import { NovaFlowStepMixin } from '../../../../../../shared/mixins/nova-flow-step-mixin/nova-flow-step-mixin.js';

class ClientOnboardingWelcome extends RequesterMixin(NovaFlowStepMixin(LocalizeNova(SkeletonMixin(LitElement)))) {

  static get styles() {
    return [
      bodyCompactStyles,
      heading1Styles,
      skillswaveButtonStyles,
      skillswaveHeading1Styles,
      skillswaveParagraphSubtleStyles,
      css`
        :host {
          display: block;
          height: 100%;
          width: 100%;
        }

        d2l-button {
          width: 100%;
        }
      `,
    ];
  }

  connectedCallback() {
    super.connectedCallback();
    this.client = this.requestInstance('d2l-nova-client');
    this.session = this.requestInstance('d2l-nova-session');
  }

  render() {
    return html`
      <div class="primary-content">
        <h1 class="skillswave-heading-1">
          ${this.localize('view-client-onboarding.client-onboarding-welcome.congratulations.1', { userName: this.session.user.getDisplayName() })}
          <br />
          <br />
          ${this.localize('view-client-onboarding.client-onboarding-welcome.congratulations.2', { strong: this.l10nTags.strong() })}
        </h1>
        <p class="skillswave-paragraph-subtle">
          ${this.localize('view-client-onboarding.client-onboarding-welcome.description', { tenantName: this.session.tenant.name })}
        </p>
        <d2l-button primary @click=${this.goNext}>
          <span class="skillswave-button-text">${this.localize('view-client-onboarding.client-onboarding-welcome.getStarted')}</span>
        </d2l-button>
      </div>
    `;
  }
}

window.customElements.define('client-onboarding-welcome', ClientOnboardingWelcome);

