import '@brightspace-ui/core/components/alert/alert.js';
import '@brightspace-ui/core/components/inputs/input-search.js';
import '@brightspace-ui/core/components/link/link.js';
import '@brightspace-ui/core/components/list/list.js';

import '../../../../shared/components/general/app-link/app-link.js';
import '../../../../shared/components/activities/activity-financial-details/activity-financial-details.js';
import '../../../../shared/components/activities/activity-small/activity-small.js';
import '../../../../main/components/applications/application-review-overview/application-review-overview.js';
import '../../../../shared/components/general/detail-list/detail-list.js';

import { bodyCompactStyles } from '@brightspace-ui/core/components/typography/styles.js';

import { css, html, LitElement, nothing } from 'lit';
import { navigator as nav } from 'lit-element-router';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';
import { SkeletonMixin } from '@brightspace-ui/core/components/skeleton/skeleton-mixin.js';

import Activity from '../../../../../shared/models/activity/activity.js';

import { Application } from '../../../../../shared/models/application/index.js';
import { LocalizeNova } from '../../../../shared/mixins/localize-nova/localize-nova.js';
import Tenant from '../../../../../shared/models/tenant/index.js';

export default class ReviewApplication extends LocalizeNova(SkeletonMixin(RequesterMixin(nav(LitElement)))) {

  static get properties() {
    return {
      params: { type: Object },
      _activity: { type: Object, attribute: false },
      _application: { type: Object, attribute: false },
      _employer: { type: Object, attribute: false },
      _existingApp: { type: Object, attribute: false },
      _provider: { type: Object, attribute: false },
      _tax: { type: Object, attribute: false },
      _dataFetched: { state: true },
    };
  }

  static get styles() {
    return [
      super.styles,
      bodyCompactStyles,
      css`
        :host {
          display: block;
        }

        detail-list {
          --detail-list-spacing: 0.6rem;
        }

        activity-small {
          margin-bottom: 3rem;
        }

        .alert-subtext {
          margin: 0;
        }

        .apply-container {
          column-gap: 100px;
          display: grid;
          grid-template-columns: 1.75fr 1fr;
          margin: 0 auto;
          width: 1170px;
        }

        .left-content-primary {
          grid-column: 1;
          grid-row-start: 1;
        }

        .left-content-secondary {
          grid-column: 1;
          grid-row: 2;
        }

        .right-content {
          grid-column: 2;
          grid-row: 1 / span 2;
        }

        .intro-text {
          margin-bottom: 48px;
        }

        section:not(.apply-container) > :first-of-type {
          margin-bottom: 0;
        }

        section:not(.apply-container) activity-small:first-of-type {
          margin-top: 24px;
        }

        .activity-details {
          position: sticky;
          top: 0.6rem;
        }

        .select-activity {
          margin-top: 48px;
        }

        .d2l-heading-2 {
          margin-bottom: 18px;
          margin-top: 42px;
        }

        .existing-app-alert {
          min-width: 100%;
        }

        @media (max-width: 1280px) {
          .apply-container {
            grid-template-columns: 1fr;
            max-width: 767px;
            width: unset;
          }

          .right-content {
            grid-column: 1;
            grid-row: 2;
            margin-bottom: 1.2rem;
          }

          .left-content-secondary {
            grid-row: 3;
          }

          .d2l-heading-1 {
            margin: 0.6rem 0;
          }

          .intro-text {
            margin-bottom: 1.2rem;
            margin-top: 0;
          }

          .d2l-heading-2 {
            margin-bottom: 0.4rem;
            margin-top: 0.8rem;
          }

          .select-activity {
            margin-bottom: 0.6rem;
            margin-top: 0.6rem;
          }

          section:not(.apply-container) > :first-of-type {
            margin-top: 0;
          }

          section:not(.apply-container) activity-small:first-of-type {
            margin-top: 0.6rem;
          }

          .activity-details {
            position: unset;
            top: unset;
          }
        }
`,
    ];
  }

  constructor() {
    super();
    this._activity = new Activity();
    this._application = new Application();
    this._employer = new Tenant();
    this._provider = new Tenant();
    this._requestedApprover = {};
    this._tax = { percent: 0, rates: [] };
    this._dataFetched = false;
  }

  connectedCallback() {
    super.connectedCallback();
    this.session = this.requestInstance('d2l-nova-session');
    this.client = this.requestInstance('d2l-nova-client');
  }

  async firstUpdated() {
    this.skeleton = true;
    await this._fetchData();

    this._determineRequestedApprover();
    this.skeleton = false;
  }

  render() {
    if (!this.session?.draftApplication?.draft) {
      this.navigate(`/activities/${this.params.original_id}/apply`);
    }

    if (!this._dataFetched) return nothing;

    const introText = html`${this.localize('apply-activity.review.introduction', {
      activity: this._activity.title,
    })}`;

    return html`
      <section class="apply-container">
        <div class="left-content-primary">
          <h1 class="d2l-heading-1 d2l-skeletize">${this.localize('review-application.title')}</h1>
          <p class="d2l-skeletize intro-text">${introText}</p>
          ${this._existingAppAlert}
          <div class="select-activity">
            <activity-small
              disabled
              ?skeleton=${this.skeleton}
              .displayAttributes=${this._activityDisplayAttributes}
              .activity=${this._activity}>
            </activity-small>
          </div>
        </div>
        <div class="left-content-secondary">
          <application-review-overview
            .activity=${this._activity}
            .customCondition=${this._customCondition}
            .employer=${this._employer}
            .provider=${this._provider}
            .params=${this.params}
            .requestedApprover=${this._requestedApprover}
            ?skeleton=${this.skeleton}
            .tax=${this._tax}
          ></application-review-overview>
        </div>
        <div class="right-content">
          <div class="activity-details d2l-skeletize">
            ${this._employer.hasTag('paymentHandledByProvider') ? nothing : html`
            <activity-financial-details
              applying
              .activity=${this._activity}
              .application=${this._application}
              .provider=${this._provider}
              .userGuid=${this.session.userGuid}
              .tenant=${this._employer}
              .taxRateInPercentage=${this._tax.percent}>
            </activity-financial-details>`}
          </div>
        </div>
      </section>
    `;
  }

  get _activityDisplayAttributes() {
    return ['formattedCost', 'formattedStartDate'];
  }

  get _existingAppAlert() {
    return this._existingApp ? html`
      <d2l-alert type="default" class="existing-app-alert">
        ${this.localize('apply-activity.existingApp.header')}
        <p class="d2l-body-compact alert-subtext">${this.localize('apply-activity.existingApp.subtext')} <app-link d2l-link href="/requests/${this._existingApp.uuid}">${this.localize('apply-activity.existingApp.subtext.link')}</app-link></p>
      </d2l-alert>
    ` : nothing;
  }

  _determineRequestedApprover() {
    this._requestedApprover = this._employer.determineRequestedApprover(this.session.user);
    if (!this._requestedApprover.id) {
      if (this.session.user.tenantType === 'employer') {
        this.session.toast({ message: `${this.localize('apply-activity.noManager.toast')}` });
      } else { // It's a provider or admin
        this.session.toast({ message: `${this.localize('apply-activity.error.cantApply')}` });
      }
    }
  }

  async _fetchData() {
    this._activity = new Activity(this.session.draftApplication.activity);
    this._employer = await this.client.fetchTenant(this.session.tenantId);
    this._provider = await this.client.fetchTenant(this._activity.provider);
    this._customCondition = await this.client.getCustomCondition(this._employer.id);

    const existingApps = await this.client.getApplicationsForActivity(this._activity.id);
    this._existingApp = existingApps.length ? new Application(existingApps[0]) : undefined;

    if (this._activity.hasTag('taxable') && this.session?.draftApplication?.taxable)
      this._tax = this.session.draftApplication.tax;
    this._dataFetched = true;
  }
}

window.customElements.define('review-application', ReviewApplication);
