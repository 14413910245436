import { v4 as Uuid4 } from 'uuid';

import { Cost } from './cost.js';
import { LocalizedCostSchema } from './schema/localized-cost.js';

export class LocalizedCost extends Cost {
  constructor(base = {}) {
    base.id = base.id || Uuid4().toLowerCase();

    super(base);
  }

  getSchema() {
    return LocalizedCostSchema;
  }
}
