// Helper methods and constants for Playwright
import { statuses as appStatuses } from '../models/application/index.js';

const idVocabulary = {
  activity: 'activity',
  activities: 'activity',
  admin: 'admin',
  administration: 'admin',
  alert: 'alert',
  application: 'apply',
  applications: 'apply',
  apply: 'apply',
  approve: 'approve',
  approver: 'approve',
  approval: 'approve',
  approvals: 'approve',
  attribute: 'attrib',
  attributes: 'attrib',
  button: 'button',
  callToAction: 'calltoact',
  cancel: 'cancel',
  card: 'card',
  career: 'carrer',
  carousel: 'carousel',
  cell: 'cell',
  checkbox: 'checkbox',
  completion: 'complete',
  confirm: 'confirm',
  confirmation: 'confirm',
  content: 'content',
  contents: 'content',
  container: 'container',
  date: 'date',
  decline: 'decline',
  detail: 'detail',
  details: 'detail',
  dialog: 'dialog',
  dialogue: 'dialog',
  dropdown: 'dropdown',
  employee: 'requester',
  enrol: 'enroll',
  enroll: 'enroll',
  enrolment: 'enroll',
  enrollment: 'enroll',
  filter: 'filter',
  finance: 'finance',
  financial: 'finance',
  goal: 'intent',
  hosted: 'hosted',
  icon: 'icon',
  image: 'image',
  indicator: 'indicate',
  input: 'input',
  intention: 'intent',
  item: 'item',
  learner: 'requester',
  left: 'left',
  lineItem: 'lineitem',
  linkFrom: 'linkfrom',
  linkTo: 'linkto',
  list: 'list',
  logo: 'logo',
  manager: 'manager',
  menu: 'menu',
  name: 'name',
  next: 'next',
  option: 'option',
  options: 'option',
  overview: 'overview',
  payment: 'payment',
  payments: 'payment',
  playwright: 'pw',
  previous: 'prev',
  profile: 'profile',
  program: 'program',
  programs: 'program',
  progress: 'progress',
  progression: 'progress',
  reason: 'reason',
  refund: 'refund',
  register: 'register',
  registration: 'register',
  retained: 'retained',
  requester: 'requester',
  requests: 'application',
  right: 'right',
  role: 'role',
  row: 'row',
  select: 'select',
  selection: 'selection',
  selector: 'selector',
  skill: 'skill',
  skills: 'skill',
  step: 'step',
  sponsor: 'sponsor',
  submit: 'submit',
  submisson: 'submit',
  table: 'table',
  tag: 'tag',
  termsAndConditions: 'tandcs',
  text: 'text',
  title: 'title',
  toast: 'toast',
  user: 'user',
  users: 'user',
};

const v = idVocabulary;
const pw = v.playwright;

const GENERATED_TEST_ID_CATEGORIES = {
  confirmationDialog: `${pw}-${v.confirmation}-${v.dialog}`,
  dateInput: `${pw}-${v.date}-${v.input}`,
  detailItemText: `${pw}-${v.detail}-${v.item}-${v.text}`,
  toastText: `${pw}-${v.toast}-${v.text}`,
};

const STATIC_TEST_IDS = {
  // These IDs are used in Playwright tests
  activitiesLink: `${pw}-${v.linkTo}-${v.activities}`,
  applicationTableRow: `${pw}-${v.application}-${v.table}-${v.row}`,
  applicationOverviewActivityName:
    `${pw}-${v.application}-${v.overview}-${v.activity}-${v.name}`,
  approvalReason: `${pw}-${v.approval}-${v.reason}`,
  careerFilterTag: `${pw}-${v.career}-${v.filter}-${v.tag}`,
  careerSkillsList: `${pw}-${v.career}-${v.skills}-${v.list}`,
  completionSelect: `${pw}-${v.completion}-${v.select}`,
  completionSelectOptions: `${pw}-${v.completion}-${v.options}`,
  confirmationDialogAttributes: `${pw}-${v.confirmation}-${v.dialog}-${v.attributes}`,
  nextProgramsButton: `${pw}-${v.next}-${v.programs}-${v.button}`,
  paymentIndicator: `${pw}-${v.payment}-${v.indicator}`,
  previousProgramsButton: `${pw}-${v.previous}-${v.programs}-${v.button}`,
  profileDropdownMenu: `${pw}-${v.profile}-${v.dropdown}-${v.menu}`,
  refundText: `${pw}-${v.refund}-${v.text}`,
  retainedText: `${pw}-${v.retained}-${v.text}`,
  requestsLink: `${pw}-${v.linkTo}-${v.requests}`,
  termsAndConditionsText: `${pw}-${v.termsAndConditions}-${v.text}`,

  // These IDs are fixed on third-party pages
  hostedPaymentSubmitButton: 'hosted-payment-submit-button', // fixed on Stripe page

  // These IDs are defined but not used in any known Playwright tests
  linkFromLogo: `${pw}-${v.linkFrom}-${v.logo}`,
  profileImage: `${pw}-${v.profile}-${v.image}`,
};

const GENERIC_ICONS = {
  alertTriangle: 'tier1:alert',
};

const ICON_CLASSES = {
  approvedCheck: 'approved-check',
  approvedCheckAligned: 'approved-check-align-text',
  financePaidCheck: 'green-icon',
  stepCompleted: 'd2l-labs-single-step-header-done-icon',
  stepInProgress: 'd2l-labs-single-step-header-inner-progress-circle',
  stepNotStarted: 'd2l-labs-single-step-header-circle',
};

const PROGRESS_WIZARD_STATES = {
  notStarted: 'not started',
  inProgress: 'in progress',
  completed: 'completed',
};

const APPLICATION_STATUS_INDICATOR_STATES = {
  'approved': appStatuses.APPROVED.indicator.state,
  'cancelled': appStatuses.CANCELLED_AFTER_REGISTRATION.indicator.state,
  'completed': appStatuses.COMPLETED.indicator.state,
  'declined': appStatuses.DECLINED.indicator.state,
  'failed': appStatuses.FAILED.indicator.state,
  '1 of 2 approved' : appStatuses.ONE_OF_TWO_APPROVED.indicator.state,
  'paid' : appStatuses.REGISTRATION_SUBMITTED.indicator.state,
  'pending': appStatuses.PENDING.indicator.state,
  'registered': appStatuses.REGISTERED.indicator.state,
  'registration started': appStatuses.REGISTRATION_SUBMITTED.indicator.state,
  'withdrew': appStatuses.WITHDRAWN.indicator.state,
};

const EMPTY_MONEY_TEXT = '---';

/**
 * Generates a Playwright data-testid value when a constant one can't be
 * used.
 *
 * @param {String} category - loose, arbitrary category of element being labelled
 * @param {String} label - distinct identifier for this element category on this page
 * @returns {String} - kebab-case test ID suitable for use with Playwright
 */
const makePlaywrightTestId = (category = '', label = '') => {
  if (!(Object.values(GENERATED_TEST_ID_CATEGORIES).includes(category))) {
    console.warn(`'${category}' is not a defined category value for generating Playwright test IDs. Using it, but consider using or defining a category in helpers/playwright.js.`);
  }
  return [category, label].join(' ')
    .trim()
    .replace(/\s+/g, '-')
    .toLowerCase()
    .replace(/[^-a-z0-9]/g, '');
};

export {
  APPLICATION_STATUS_INDICATOR_STATES,
  EMPTY_MONEY_TEXT,
  GENERATED_TEST_ID_CATEGORIES,
  GENERIC_ICONS,
  ICON_CLASSES,
  makePlaywrightTestId,
  PROGRESS_WIZARD_STATES,
  STATIC_TEST_IDS
};
