import { navigator as nav } from 'lit-element-router';
import { v4 as Uuid4 } from 'uuid';

import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';

import ActivitiesHelper from '../../../../shared/helpers/activities.js';
import Activity from '../../../../shared/models/activity/activity.js';
import { AdmissionRequirements } from '../../../../shared/models/activity/admission-requirements.js';
import { LocalizeNova } from '../../../shared/mixins/localize-nova/localize-nova.js';
import { NovaFormMixin } from '../../../shared/mixins/nova-form-mixin/nova-form-mixin.js';

const isPositiveInteger = value => +value >= 0 && +value % 1 === 0;
export default superclass => class EditActivityMixin extends NovaFormMixin(LocalizeNova(RequesterMixin(nav((superclass))))) {

  static get properties() {
    return {
      params: { type: Object, reflect: true, attribute: 'params' },
      _activity: { type: Object },
      _addedCourses: { type: Array },
      _allActivities: { type: Array },
      _errors: { type: Array },
      _providers: { type: Array },
      _removedCourses: { type: Array },
      _admissionRequirements: { type: Object },
      _isNewActivity: { state: true },
      _admissionBasedCheckboxWasTriggered: { state: true },
      _providerOperatingCurrency: { type: String, attribute: false },
      _extractingSkills: { state: false },
      _updatingChild: { type: Object },
    };
  }

  constructor() {
    super();
    this._activity = new Activity({
      description: '',
      skills: [],
      skillsRemoved: [],
      startDate: [],
      tempCost: { cost: 0, currency: 'CAD' },
    });
    this._errors = [];
    this._addedCourses = [];
    this._removedCourses = [];
    this._admissionRequirements = new AdmissionRequirements();
    this._isNewActivity = false;
    this._admissionBasedCheckboxWasTriggered = false;
    this._extractingSkills = false;
  }

  get addCourseDialog() {
    if (!this._addCourseDialog) this._addCourseDialog = this.shadowRoot.getElementById('add-course-dialog');
    return this._addCourseDialog;
  }

  get addCourseOptions() {
    if (!this._allActivities || !this._activity) {
      return [];
    }

    const addedCourseIds = this._activity.children.map(course => course.id);
    return this._allActivities.filter(activity => !addedCourseIds.includes(activity.id));
  }

  get formattedAdmissionRequirements() {
    const addAdditionalProps = (arr, type) => arr.map(item => ({ ...item, id: this._generateUniqueId(), draftTitle: item.title, isEditing: false, type }));

    const { standard, international } = this._activity.getAdmissionRequirements();
    const contactEmail = this._activity.admissionContactEmail;

    const standardWithAdditionalProps = addAdditionalProps(standard, 'standard');
    const internationalWithAdditionalProps = addAdditionalProps(international, 'international');

    return { contactEmail, standard: standardWithAdditionalProps, international: internationalWithAdditionalProps };
  }

  get requirementsAreEmpty() {
    const { standard, international } = this._admissionRequirements;
    const requirements = [].concat(standard, international);

    return requirements.some(({ description }) => !description) || requirements.length === 0;
  }

  connectedCallback() {
    super.connectedCallback();
    this.client = this.requestInstance('d2l-nova-client');
    this.session = this.requestInstance('d2l-nova-session');
  }

  async firstUpdated() {
    // Initialize NovaFormMixin properties
    this.hideFormErrorSummary = false;
    this.showErrorToast = false;

    if (this.session.loggedIn && this.session.tenant.type !== 'admin') {
      const redirectURL = this.params?.id ? `/activities/${this.params?.id}` : '/';
      this.navigate(redirectURL);
      return;
    }

    this._providers = await this.client.listTenants('provider');
    if (this.params?.id) { // existing activity
      this._activity = await this.client.fetchActivity(this.params.id);
      this._providerOperatingCurrency = this._providers.find(p => p.id === this._activity.provider)?.operatingCurrency;
      this._errors = [...this._activity.customErrors];
    } else { // new activity
      this._isNewActivity = true;
    }

    this._admissionRequirements = this.formattedAdmissionRequirements;
  }

  changeActiveState() {
    this.client.setActivityActive(this._activity.id, !this._activity.hasTag('active'));
    this._activity.setTag('active', !this._activity.hasTag('active'));
  }

  resetAdmissionRequirements() {
    this._activity.admissionRequirements = { standard: [], international: [] };
  }

  newCourseChange(e) {
    e.stopPropagation();
  }

  get _isProgram() {
    return this._activity.type === 'program';
  }

  _generateUniqueId() {
    return Uuid4().toLowerCase();
  }

  createDefaultRequirementObject(type) {
    return {
      id: this._generateUniqueId(),
      title: '',
      draftTitle: '',
      description: '',
      cost: null,
      delay: null,
      isEditing: true,
      type,
    };
  }

  _addRequirement(type) {
    const requirementArr = this._admissionRequirements[type];

    this._admissionRequirements = {
      ...this._admissionRequirements,
      [type]: [...requirementArr, this.createDefaultRequirementObject(type)],
    };
  }

  _toggleEditing(requirement) {
    requirement.isEditing = !requirement.isEditing;
    this.requestUpdate();
  }

  _changeAdmissionContactEmail(e) {
    e.stopPropagation();
    const newEmail = e.target.value;
    this._admissionRequirements = { ...this._admissionRequirements, contactEmail: newEmail };
  }

  _changeAdmissionTitle(e, requirement) {
    const newTitle = e.target.value;
    requirement.draftTitle = newTitle;
    this.requestUpdate();
  }

  _changeAdmissionFormValue(e, requirement) {
    e.stopPropagation();
    switch (e.target.id) {
      case 'admissionTitle':
        requirement.title = e.target.value;
        break;
      case 'cost':
        requirement.cost = e.target.value;
        break;
      case 'delay':
        requirement.delay = e.target.value;
        break;
    }

    if (e.detail?.html) {
      // Ensure that the html tags are not empty before setting the value
      requirement.description = e.detail.text && e.detail.html;
    }
    this.requestUpdate();
  }

  _deleteRequirement(id, type) {
    const requirementArr = this._admissionRequirements[type];
    const index = requirementArr.findIndex(req => req.id === id);

    if (index > -1) {
      requirementArr.splice(index, 1);
      this._admissionRequirements = {
        ...this._admissionRequirements,
        [type]: [...requirementArr],
      };
    }
  }

  _saveRequirementTitle(requirement) {
    requirement.title = requirement.draftTitle;
    requirement.isEditing = !requirement.isEditing;
    this.requestUpdate();
  }

  _admissionRequirementsToExpectedFormat() {
    const toExpectedFormat = requirements => requirements.map(requirement => {
      const expectedFormat = {
        title: requirement.title,
        description: requirement.description,
        cost: requirement.cost,
        delay: requirement.delay,
      };
      // making sure that the client can specify 0 but if nothing is specified - we don't store the attribute
      if (!expectedFormat.cost && expectedFormat.cost !== 0) delete expectedFormat.cost;
      if (!expectedFormat.delay && expectedFormat.delay !== 0) delete expectedFormat.delay;

      return expectedFormat;
    });

    return {
      contactEmail: this._admissionRequirements.contactEmail,
      standard: toExpectedFormat(this._admissionRequirements.standard),
      international: toExpectedFormat(this._admissionRequirements.international),
    };
  }

  async _addCourse() {
    const type = this.shadowRoot.getElementById('core').checked ? 'core' : 'elective';
    const id = this.shadowRoot.getElementById('course').value;
    const order = this.shadowRoot.getElementById('order').value;

    if (order && !isPositiveInteger(order)) return;

    if (id) {
      // remove the course from all lists
      this._removedCourses = this._removedCourses.filter(courseId => courseId !== id);
      this._addedCourses = this._addedCourses.filter(item => item.id !== id);
      this._activity.children = this._activity.children.filter(item => item.id !== id);

      // add the course to the relevant lists
      this._addedCourses.push({ type, id, order });
      const addedChild = await this.client.fetchActivity(id);
      addedChild.meta = { type, id, order };
      this._activity.children.push(addedChild);
      this._activity.children = ActivitiesHelper.sortChildCourses(this._activity.children);
    }
    this._closeAddCourseDialog();
    this.update();
  }

  _allowSkillToBeExtracted(ev) {
    const allowedSkill = ev.detail.skill;
    this._activity.allowSkillToBeExtracted(allowedSkill.id);
    this.requestUpdate();
  }

  _omitExtractedSkill(ev) {
    const omittedSkill = ev.detail.skill;
    this._activity.omitSkillFromExtraction(omittedSkill.id);
    this.requestUpdate();
  }

  _blurred(e) {
    if (!e.target.value?.trim() && (e.target.getAttribute('aria-required') || e.target.required)) {
      e.target.setAttribute('aria-invalid', true);
    } else {
      e.target.setAttribute('aria-invalid', false);
    }
  }

  get _isNotRequestableWithoutChildren() {
    return this._isProgram && !this._activity.hasTag('allowRequest');
  }

  _changeValue(e) {
    if (e.target.id) {
      if (e.target.id === 'provider') {
        this._activity.provider = e.target.value;
        this._updateProviderOperatingCurrency(e.target.value);
        this._updateCostCurrency();
      } else if (e.target.id === 'startDate') {
        this._handleDateSelection();
      } else if (e.target.id === 'cost') {
        this._activity.tempCost.setInDollars(e.target.value);
      }
      else if (e.target.checked !== undefined) {
        this._activity.tags.setTag(e.target.id, e.target.checked);

        if (e.target.id === 'zeroDollarProgram' && e.target.checked) {
          const oldCost = this._activity.tempCost.inDollars();
          this._activity.tags.setTag('allowRequest', true);
          this._activity.tempCost.setInDollars(0);
          this.session.toast({ type: 'info', message: this.localize('edit-activity.zeroDollarProgramActivity.selected', { oldCost }) });
        }
      } else if (e.detail?.html) {
        // Ensure that the html tags are not empty before setting the value
        this._activity[e.target.id] = e.detail.text && e.detail.html;
      } else if (e.target.id === 'delivery') {
        // eslint-disable-next-line no-prototype-builtins
        if (e.target.value !== 'inPersonLocationRestricted' && this._activity.hasOwnProperty('maxLearnerDistance')) {
          delete this._activity.maxLearnerDistance;
        }
        this._activity[e.target.id] = e.target.value;
      } else {
        this._activity[e.target.id] = e.target.value;
      }
    }
    this.requestUpdate();
  }

  _updateTag(e) {
    e.stopPropagation();
    // making sure to check if admissionBased checkbox was clicked or if it was opened before
    if (e.target.id === 'admissionBased') this._admissionBasedCheckboxWasTriggered = true;
    if (e.target.id === 'admissionBased' && e.target.checked) this._admissionRequirements = this.formattedAdmissionRequirements;
    this._activity.tags.setTag(e.target.id, e.target.checked);
    this._errors = this._activity.customErrors;
  }

  _updateProviderOperatingCurrency(providerId) {
    const provider = this._providers.find(p => p.id === providerId);

    // this ensure that even if the provider is not found, the operating currency is set to undefined
    // by doing that, we can ensure that the operating currency is not set to the previous provider's operating currency
    // and it also helps to restrict admin from entering a cost if the operating currency is not set
    this._providerOperatingCurrency = provider?.operatingCurrency;

    if (!provider) {
      this.session.toast({ type: 'critical' });
      return;
    }

    if (!provider.operatingCurrency) {
      this.session.toast({ type: 'critical', message: this.localize('edit-activity.error.operatingCurrencyNotFound') });
      return;
    }
  }

  _updateCostCurrency() {
    if (this._activity.tempCost?.currency) {
      this._activity.tempCost.currency = this._providerOperatingCurrency;
      this.requestUpdate();
    }
  }

  _closeAddCourseDialog() {
    this.addCourseDialog.opened = false;
  }

  __handleCloseAddCourseDialog(e) {
    const order = this.shadowRoot.getElementById('order').value;
    if (e.detail.action === 'done' && order && !isPositiveInteger(order)) {
      e.preventDefault();
    } else {
      this._updatingChild = undefined;
      this.shadowRoot.getElementById('order').value = '';
      this.shadowRoot.getElementById('elective').checked = true;
    }
  }

  _updateTagList(e) {
    e.stopPropagation();
    const { checked, id, name: tagName } = e.target;
    this._activity[tagName].setTag(id, checked);
    this._errors = this._activity.customErrors;
  }

  _warnIfNoCategory() {
    if (this._activity.category?.tagValues?.length === 0 && this._activity.hasTag('active')) {
      this.session.toast({ type: 'warning', message: this.localize('edit-activity.error.selectAtLeastOneSkillStream') });
    }
  }

  _updateCategoryList(e) {
    e.stopPropagation();
    const { checked, id, name: tagName } = e.target;
    this._activity[tagName].setTag(id, checked);
    this._errors = this._activity.customErrors;
    this._warnIfNoCategory();
  }

  _courseRemoved(activity) {
    return e => {
      if (this._activity.children.some(course => course.id === activity.id)) {
        this._addedCourses = this._addedCourses.filter(course => course.id !== activity.id);
        this._activity.children = this._activity.children.filter(course => course.id !== activity.id);
        this._removedCourses.push(activity.id);
        this.update();
        e.preventDefault();
      }
    };
  }

  _updateCourseOrder(activity) {
    return e => {
      if (this._activity.children.some(course => course.id === activity.id)) {
        this._updatingChild = activity;
        this.addCourseDialog.opened = true;
        e.preventDefault();
      }
    };
  }

  _editorBlurred(e) {
    if (!e.target.html && (e.target.getAttribute('aria-required') || e.target.required)) {
      e.target.setAttribute('aria-invalid', true);
    } else {
      e.target.setAttribute('aria-invalid', false);
    }
  }

  async _extractSkills() {
    try {
      let activity = await this.client.extractSkills(this._activity.id);
      let counter = 0;

      this._extractingSkills = true;

      while (activity.hasTag('extractingSkills')) {
        if (counter >= 12) { // 12 * 5 seconds = 60 seconds
          break;
        }
        await new Promise(resolve => setTimeout(resolve, 5000)); // eslint-disable-line no-await-in-loop
        const fetched = await this.client.fetchActivity(this.params.id); // eslint-disable-line no-await-in-loop
        activity = new Activity(fetched);
        counter++;
      }

      this._extractingSkills = false;

      if (activity.hasTag('extractingSkills')) {
        this.session.toast({ type: 'critical', message: this.localize('edit-activity.skillExtractionError') });
        this._activity.setTag('extractingSkills', false);
        return;
      }

      const newExtractedSkills = activity.skills;

      this._activity.updateSkillsByExtraction(newExtractedSkills);
      this.session.toast({ type: 'default', message: this.localize('edit-activity.successfulSkillExtractionMessage', { numSkills: this._activity.skills.length }) });
      this.requestUpdate();
    } catch (err) {
      this.session.toast({ type: 'critical', message: this.localize('edit-activity.skillExtractionError') });
    }
  }

  async _openAddCourseDialog() {
    await this._populateCourses();
    this.addCourseDialog.opened = true;
  }

  async _populateCourses() {
    if (this._allActivities) return;
    this._allActivities = (await this.client.activityList(undefined, { type: ['course'], provider: [this._activity.provider] })).sort((a, b) => {
      const al = a.title.toLowerCase();
      const bl = b.title.toLowerCase();
      if (al < bl) return -1;
      if (bl < al) return 1;
      return 0;
    });
  }

  _handleDateDeletion(date) {
    const index = this._activity.startDate.indexOf(date);
    this._activity.startDate.splice(index, 1);
    this._activity.startDate.sort();
    this.requestUpdate();
  }

  _handleDateSelection() {
    const startDateElement = this.shadowRoot.getElementById('startDate');
    const dateString = new Date(startDateElement.value).toISOString();
    if (!this._activity.startDate.some(ele => ele === dateString)) {
      this._activity.startDate.push(dateString);
      this._activity.startDate.sort();
      this.requestUpdate();
    }
  }

  _handleSkillsChanged(e) {
    const skills = e.detail;
    this._activity.skills = skills;
    this.requestUpdate();
  }

  async isFormValidated() {
    this._errors = this._activity.customErrors;
    return await super.isFormValidated() && !this._errors?.length;
  }

  _validEnteredElectiveCourseCount(actualActivityElectiveCount, inputtedElectiveCourseCount) {
    return inputtedElectiveCourseCount <= actualActivityElectiveCount;
  }

  _validEnteredRequiredCourseCount(actualCoreCourseCount, inputtedRequiredCourseCount, inputtedElectiveCourseCount) {
    return inputtedRequiredCourseCount === inputtedElectiveCourseCount + actualCoreCourseCount;
  }

  async _submit(navigateAway = false) {
    const isFormValidated = await this.isFormValidated();
    if (!isFormValidated) return;

    if (!this._providerOperatingCurrency) {
      this.session.toast({ type: 'critical', message: this.localize('edit-activity.error.operatingCurrencyNotFound') });
      return;
    }

    if (this._activity.isAdmissionBased && this.requirementsAreEmpty) {
      this.session.toast({ type: 'critical', message: this.localize('edit-activity.admissionBased.emptyRequirementsSaveError'), noAutoClose: false });
      return;
    }

    if (this._activity.type === 'program' && this._activity.hasTag('zeroDollarProgram') && this._activity.tempCost.inDollars() > 0) {
      this.session.toast({ type: 'critical', message: this.localize('edit-activity.zeroDollarProgramActivity.priceAndTagMismatch'), noAutoClose: false });
      return;
    }

    // ensure the required courses and electives courses counts are correct
    if (this._activity.type === 'program') {
      const actualElectiveCoursesCount = this._activity.children ? this._activity.children.filter(course => course.meta.type === 'elective').length : 0;
      const actualCoreCoursesCount = this._activity.children ? this._activity.children.filter(course => course.meta.type === 'core').length : 0;

      if (!this._validEnteredElectiveCourseCount(actualElectiveCoursesCount, parseInt(this._activity.numberElectives))) {
        this.session.toast({ type: 'critical', message: this.localize('edit-activity.program.incorrectElectiveCoursesCount'), noAutoClose: false });
        return;
      }
      if (!this._validEnteredRequiredCourseCount(actualCoreCoursesCount, parseInt(this._activity.coursesRequired), parseInt(this._activity.numberElectives))) {
        this.session.toast({ type: 'critical', message: this.localize('edit-activity.program.incorrectCoursesCount'), noAutoClose: false });
        return;
      }
    }

    let type = 'default';
    let message = 'Activity saved';
    try {
      if (this._isNotRequestableWithoutChildren && !this._activity.startDateType) this._activity.startDateType = 'anytime';
      if (this._activity.isAdmissionBased) this._activity.admissionRequirements = this._admissionRequirementsToExpectedFormat();
      else this.resetAdmissionRequirements();

      if (!this._isNewActivity) {
        await this._submitExistingActivity();
      } else {
        await this._submitNewActivity();
      }
      if (navigateAway) this.navigate('/admin');
      this._admissionBasedCheckboxWasTriggered = false;
    } catch (e) {
      type = 'critical';
      message = await e.text();
    }
    this.session.toast({ type, message, noAutoClose: false });
  }

  async _submitAndClose() {
    await this._submit(true);
  }

  async _submitAndStayOpen() {
    await this._submit();
  }

  async _submitExistingActivity() {
    try {
      this._activity.title = this._activity.title.trim();
      await this.client.upsertActivity(this._activity);
      if (this._addedCourses.length > 0) {
        await this.client.addCourses(this._activity.id, this._addedCourses);
        this._addedCourses = [];
      }
      if (this._removedCourses.length > 0) {
        await this.client.removeCourses(this._activity.id, this._removedCourses);
        this._removedCourses = [];
      }
      this.requestUpdate();
    } catch (e) {
      return e;
    }
  }

  async _submitNewActivity() {
    this._updateCostCurrency();
    try {
      let response;
      if (this._activity.type === 'program' && this._addedCourses.length > 0) {
        response = await this.client.upsertActivity(this._activity, this._addedCourses);
      } else {
        response = await this.client.upsertActivity(this._activity);
      }
      const { id } = response;
      await this._activityInOpenSearch(id);
    } catch (e) {
      return e;
    }
  }

  // Try to find the activity in opensearch, try 5 times by default
  async _activityInOpenSearch(activityId, attempts = 5) {
    return new Promise(resolve => {
      const intervalId = setInterval(async() => {
        const filters = { id: activityId };
        const response = await this.client.searchActivities({
          from: 0,
          size: 1,
          filters,
        });
        attempts--;
        if (response.hits?.length > 0 || attempts <= 0) {
          clearInterval(intervalId);
          resolve(response);
        }
      }, 200);
    });
  }
};
