import '@brightspace-ui/core/components/tag-list/tag-list.js';
import '@brightspace-ui/core/components/tag-list/tag-list-item.js';
import '@brightspace-ui/core/components/button/button-icon.js';
import '@brightspace-ui/core/components/button/button.js';
import '@brightspace-ui/core/components/dialog/dialog.js';
import '@brightspace-ui/core/components/inputs/input-search.js';
import '@brightspace-ui-labs/autocomplete/autocomplete.js';
import '@brightspace-ui-labs/autocomplete/autocomplete-input-text.js';
import '@brightspace-ui/core/components/inputs/input-text.js';

import { bodyCompactStyles, heading1Styles } from '@brightspace-ui/core/components/typography/styles.js';
import { css, html, LitElement, nothing } from 'lit';
import { linkStyles } from '@brightspace-ui/core/components/link/link.js';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';

import '@app/main/views/client-onboarding/view-client-onboarding/client-onboarding-progress-bar/client-onboarding-progress-bar.js';
import '@app/shared/components/general/nova-tile/nova-tile.js';
import '@app/shared/components/skills/area-of-interest-chip/area-of-interest-chip.js';

import { skillswaveButtonStyles, skillswaveParagraphSubtleStyles } from '@app/shared/styles/skillswave-text-styles.js';
import { mapify } from '@shared/methods.js';

import { LocalizeNova } from '../../../../../../shared/mixins/localize-nova/localize-nova.js';
import { NovaFlowStepMixin } from '../../../../../../shared/mixins/nova-flow-step-mixin/nova-flow-step-mixin.js';

class ClientOnboardingIndustry extends RequesterMixin(NovaFlowStepMixin(LocalizeNova(LitElement))) {

  static get properties() {
    return {
      lightcastCompanyId: { type: String, attribute: 'lightcast-company-id' },
      _industryList: { type: Array },
      _editMode: { type: Boolean },
      companyName: { type: String },
      suggestions: { type: Array },
    };
  }

  static get styles() {
    return [
      bodyCompactStyles,
      heading1Styles,
      skillswaveButtonStyles,
      skillswaveParagraphSubtleStyles,
      linkStyles,
      css`
        @import url('https://fonts.googleapis.com/css?family=Poppins');

        :host {
          display: block;
        }

        #skillswave-logo {
          margin: 13px 0 0 32px;
          max-width: 120px;
        }

        .primary-content {
          max-width: 554px;
        }

        .industry-container {
          align-content: center;
          align-items: center;
          display: flex;
          padding: 0 24px;
        }

        .primary {
          display: flex;
          flex: 1;
          flex-direction: column;
        }

        .heading-container {
          align-items: flex-start;
          display: flex;
          flex-direction: row;
          height: 80px;
          justify-content: space-between;
          margin-bottom: 40px;
          max-height: 80px;
          padding-top: 14px;
        }

        .skillswave-paragraph-subtle {
          color: tungsten;
          font-family: Poppins, sans-serif;
          font-size: 16px;
          font-weight: regular;
          line-height: 150%;
          text-align: left;
        }

        .industry-header {
          background: linear-gradient(to right, #000000 0%, #006fbf 50%);
          -webkit-background-clip: text; /* This ensures the gradient is applied to the text in WebKit browsers */
          background-clip: text; /* Standard property */
          color: transparent; /* Make the text color transparent */
          display: inline-block; /* Ensure the text doesn't stretch */
          font-family: Poppins, sans-serif;
          font-size: 26px;
          font-weight: bold;
          line-height: 32px;
          text-align: left;
          -webkit-text-fill-color: transparent; /* Ensure the text itself is transparent in WebKit browsers */
        }


        /* Grouping together and setting parent+child specifically as labs component doesn't allow
        proper styling allowance for slotted <d2l-input-search> */
        #auto-complete,
        #soc-search {
          width: clamp(6rem, 85vw, 27rem);
        }

        .content-container {
          display: flex;
          flex-direction: column;
          margin: auto;
          width: 100%;
        }

        .edit-industry {
          color: #494c4e; /* Default link color */
          cursor: pointer;
          font-family: Poppins, sans-serif;
          font-size: 16px;
          font-weight: regular;
          line-height: 150%;
          text-align: right;
          text-decoration: underline;
        }

        .edit-industry:hover {
          color: #007bff; /* Keep the same color when hovered */
        }

        .edit-industry:active {
          color: #494c4e; /* Keep the same color when clicked (active state) */
        }

        .dialog-outer > div {
          height: 450px;
        }

        @media (max-width: 1024px) {
          .side-panel {
            display: none;
          }

          .content-container {
            display: block;
          }

          .primary-content {
            max-width: 100%;
            padding: 0 24px;
          }
        }
      `,
    ];
  }

  constructor() {
    super();
    this._industryList = [];
    this._editMode = false;
    this._foundIndustry = false;
    this.companyName = '';
    this.steps = [];
    this._industryName = '';
    this.current = 2;
    this.suggestions = [];
  }

  async connectedCallback() {
    super.connectedCallback();

    this.client = this.requestInstance('d2l-nova-client');
    this.steps = [{ title: 'Step 1' }, { title: 'Step 2' }, { title: 'Step 3' }, { title: 'Step 4' }];
  }

  async updated(changedProperties) {
    if (changedProperties.has('lightcastCompanyId')) {
      if (this.lightcastCompanyId) {
        const lightcastCategoriesData = await this.client.lightcastSkillCategories(this.lightcastCompanyId);
        if (lightcastCategoriesData.skillCategories.length) {
          this._industryList = lightcastCategoriesData.skillCategories.slice(0, 3);
          this._foundIndustry = true;

          await this._getCompanyIndustry(this.lightcastCompanyId);

          this.requestUpdate();
        }
      }

      this.data = {
        industryList: this._industryList,
      };

      this.nextEnabled = this._foundIndustry;
    }
  }

  async _getCompanyIndustry(companyId) {
    try {
      const { industryName } = await this.client.getIndustryFromCompanyID(companyId);
      this._industryName = industryName;
    } catch (e) {
      console.error('Error getting company industry', e);
      this._openErrorToast(this.localize('view-client-onboarding.client-onboarding-industry.error'));
    }
  }

  _openErrorToast() {
    const toast = this.shadowRoot.getElementById('toast');
    if (toast) {
      toast.open = true;
    }
  }

  _startEditing() {
    this._editMode = true;
  }

  _clearIndustry(e) {
    this._industryList = this._industryList.filter(industry => industry !== e.target.text);

    this.data = {
      industryList: this._industryList,
    };

    this.nextEnabled = this._industryList.length > 0;
  }

  _addIndustry() {
    const inputElement = this.shadowRoot.getElementById('new-industry');
    const newIndustry = inputElement.value;
    if (newIndustry) {
      this._industryList = [...this._industryList, newIndustry];
      inputElement.value = '';

      this.data = {
        industryList: this._industryList,
      };

      this.nextEnabled = true;
    }
  }

  get _industryFinder() {
    return this.shadowRoot.getElementById('_industryFinder-dialog');
  }

  toggleDialog() {
    if (this._industryFinder.opened === true) {
      this._industryFinder.opened = false;
      return;
    }
    this._industryFinder.opened = true;

    this._clearSearch();
    this._industryFinder.resize();
    this.requestUpdate();

  }

  _industryDialogClose() {
    this._industryFinder.opened = false;
  }

  _handleIndustryChanged() {
    this._industryFinder.resize();
  }

  async _saveIndustry() {
    this._industryName = this.shadowRoot.getElementById('soc-search').value;
    this.requestUpdate();
  }

  setTitle(e) {
    const search = this.shadowRoot.getElementById('soc-search');
    search.value = e.detail.value;
    this.requestUpdate();
  }

  _clearSearch() {
    const autoComplete = this.shadowRoot.getElementById('auto-complete');
    autoComplete.setSuggestions([]);
    this.shadowRoot.getElementById('soc-search').value = '';
  }

  async _searchChange(e) {
    const autoComplete = this.shadowRoot.getElementById('auto-complete');
    const unfilteredSuggestions = e.detail.value ? await this.client.searchLightcast(e.detail.value, 'industry') : [];

    this.suggestions = unfilteredSuggestions.filter(element => {
      return Object.entries(element).length > 0;
    }).splice(0, 5);

    const suggestionNames = this.suggestions.map(s => {
      return ({ value: `${s.name}${s.length > 0 ? ` (${s[0]?.name})` : '' }` });
    });
    this.suggestions = mapify(this.suggestions, 'name');
    autoComplete.setSuggestions(suggestionNames);
  }

  _searchTemplate() {
    return html`
     <d2l-labs-autocomplete
        id="auto-complete"
        class="test-autocomplete"
        role="search"
        show-on-focus
        remote-source
        @d2l-labs-autocomplete-filter-change=${this._searchChange}
        @d2l-labs-autocomplete-suggestion-selected=${this.setTitle}>
        <d2l-input-search
          id="soc-search"
          class="test-input"
          label="Title"
          @keydown=${this._stopInputPropagation}
          @d2l-input-search-searched=${this._searchChange}
          slot="input">
        </d2l-input-search>
      </d2l-labs-autocomplete>
    `;
  }

  industryDialog() {
    return html`
        <d2l-dialog id="_industryFinder-dialog"
        class="dialog-outer"
        title-text="${this.localize('view-client-onboarding.client-onboarding-industry.diolog.title')}"
        @d2l-dialog-before-close=${this._industryDialogClose}>
        <div class="dialog-container">
          ${this._searchTemplate()}
        </div>
          <d2l-button id="save-btn" slot="footer" @click=${this._saveIndustry} primary data-dialog-action="done">${this.localize('general.button-text.save')}</d2l-button>
          <d2l-button slot="footer" data-dialog-action="Cancel">${this.localize('general.button-text.cancel')}</d2l-button>
        </d2l-dialog>
      `;
  }

  _editIndustry() {
    this.toggleDialog();
  }

  get editIndustryText() {
    return html`
      <a class="edit-industry" href="javascript:void(0);" rel="stylesheet" @click=${this._editIndustry}>
       ${this.localize('view-client-onboarding.client-onboarding-industry.editIndustry')}
      </a>
    `;
  }

  get titleText() {
    return html`
    <h2 class='industry-header'>
      ${this.localize('view-client-onboarding.client-onboarding-industry.title', { companyName: this.companyName })}
      </h2>
    `;
  }

  get subheadingText() {
    return html`
    <p class='skillswave-paragraph-subtle'>
      ${this.localize('view-client-onboarding.client-onboarding-industry.subheading', { companyName: this.companyName })}
    </p>
    `;
  }

  render() {
    return html`
    <div class="content-container">
    <div class="primary">
      <div>
        <div class="client-onboarding-industry-description">
        <div class="content-container">
          ${this.titleText}
          ${this.subheadingText}
          <nova-tile
            text=${this._industryName}
            icon="tier1:locations"
          >
        </nova-tile>
        ${this.editIndustryText}
        ${this.industryDialog()}
        <d2l-alert-toast id="toast" type="critical">
          A default toast alert.
        </d2l-alert-toast>
      </div>

      </div>
      <div>
        ${!this.blockBack
    ? html`<d2l-button class="nova-flow-previous" @click=${this.goBack}>${this.localize('general.button-text.previous')}</d2l-button>`
    : nothing }
        <d2l-button class="nova-flow-next" primary ?disabled=${!this.nextEnabled} @click=${this.goNext}>${this.localize('general.button-text.next')}</d2l-button>
      </div>
    `;
  }

}

window.customElements.define('client-onboarding-industry', ClientOnboardingIndustry);
